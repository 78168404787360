import { SMSGroup, SMSScheduledMessage } from "@swivl/swivl-lib";
import BotModel from "../Bots/Bot.Model";
import { NotificationsModel } from "../Notifications/Notifications.Model";
import {create} from 'zustand'
import Parse from 'parse';
import { warn } from "../../Helpers/Logging/Logging";


interface StoreInterface {
  campaignGroups?:SMSGroup[],
  intervalGroups?:SMSGroup[],
  campaignMessages?:SMSScheduledMessage[] 
}
const BlankState:StoreInterface  = {
  campaignGroups:undefined,
  intervalGroups:undefined,
  campaignMessages:undefined
}

export class SMSGroupModel {
  static useState = create<StoreInterface>(set => ({  ...BlankState }))
  static setState = SMSGroupModel.useState.setState; 
  static get state() { return SMSGroupModel.useState.getState(); }
  static reset() {  SMSGroupModel.setState({  ...BlankState }); }

    static loadGroups(force) {
      if ((SMSGroupModel.state.campaignGroups || SMSGroupModel.state.intervalGroups) && !force) { return; }
      Parse.Cloud.run("getActiveSMSGroups", {botId:BotModel.state.bot.id}).then((groups) => {
      

        const campaignGroups = groups.filter(item => item.type !== "interval")
        const intervalGroups = groups.filter(item => item.type === "interval")
        SMSGroupModel.setState({
           campaignGroups : campaignGroups, 
           intervalGroups : intervalGroups})
        
      }).catch(e => {
        warn("Error Loading SMS Group state");
      })
    }

    static loadCampaignMessages(force) {
      if (SMSGroupModel.state.campaignMessages && !force) { return; }
      Parse.Cloud.run("getSMSGroupCampaignMessages", {botId:BotModel.state.bot.id}).then((messages) => {
        SMSGroupModel.setState({campaignMessages : messages})
        
      }).catch(e => {
        warn("Error Loading SMS Group state");
      })
    }

    static createCampaignMessage(newMessage) {
      return Parse.Cloud.run("createSMSCampaignMessage", {botId:BotModel.state.bot.id, campaignMessage:newMessage})
      .then((campaignMessage) => {
        let messages = [...SMSGroupModel.state.campaignMessages] || []
        messages.push(campaignMessage)
        SMSGroupModel.setState({campaignMessages : messages})
        NotificationsModel.displayNotification("Message Created",null, null, null, null, true, null , null);

        return campaignMessage;
      })
    }
    static updateCampaignMessage(newMessage) {
      return Parse.Cloud.run("updateSMSCampaignMessage", {botId:BotModel.state.bot.id, campaignMessage:newMessage})
      .then((campaignMessage) => {
        let messages = [...SMSGroupModel.state.campaignMessages] || []
        for (let i = 0; i < messages.length; i++) {
          if (messages[i].id === campaignMessage.id) {
            messages[i] = campaignMessage; 
            break; 
          }          
        }
        NotificationsModel.displayNotification("Message Updated",null, null, null, null, true,null , null);

        SMSGroupModel.setState({campaignMessages : messages})
        return campaignMessage;
      })
    }

    static deleteCampaignMessage(campaignMessage) {
      let messages = [...SMSGroupModel.state.campaignMessages] || []
      for (let i = 0; i < messages.length; i++) {
        if (messages[i].id === campaignMessage.id) {
          messages.splice(i ,1)
          break; 
        }          
      }
      
      SMSGroupModel.setState({campaignMessages : messages})

          return Parse.Cloud.run("deleteSMSCampaignMessage", {botId:BotModel.state.bot.id, campaignMessage:campaignMessage})
      .then((campaignMessage) => {
        NotificationsModel.displayNotification("Campaign Message Deleted",null, null, null, null, true);

        return campaignMessage;
      })
    }

    static createSMSGroup(group) {
      return Parse.Cloud.run("createSMSGroup", {botId:BotModel.state.bot.id, group:group})
      .then((group) => {
        if (group.type === "interval") {
          let groups = [...SMSGroupModel.state.intervalGroups] || []
          groups.push(group)
          SMSGroupModel.setState({intervalGroups : groups})
        } else {
          let groups = [...SMSGroupModel.state.campaignGroups] || []

          groups.push(group)
          SMSGroupModel.setState({campaignGroups : groups})
        }
        NotificationsModel.displayNotification("Group Created",null, null, null, null, true);

        return group;
      })
    }

    static editSMSGroup(group) { 
      if (group.type === "interval") {
        let groups = [...SMSGroupModel.state.intervalGroups] || []
        for (let i = 0; i < groups.length; i++) {
          if (groups[i].id === group.id) {groups[i] = group; break}   
        }
        SMSGroupModel.setState({intervalGroups : groups})
      } else {
        let groups = [...SMSGroupModel.state.campaignGroups] || []
        for (let i = 0; i < groups.length; i++) {
          if (groups[i].id === group.id) {groups[i] = group; break}   
        }
        SMSGroupModel.setState({campaignGroups : groups})
      }

      return Parse.Cloud.run("updateSMSGroup", {botId:BotModel.state.bot.id, group:group})
      .then((updatedGroup) => {
     
        NotificationsModel.displayNotification("Group Saved",null, null, null, null, true);

        return group;
      })

    }
    static deleteSMSGroup(group) {
      let groups = group.type === "interval" ? [...SMSGroupModel.state.campaignGroups] : [...SMSGroupModel.state.intervalGroups]

      for (let i = 0; i < groups.length; i++) {
        if (groups[i].id === group.id) {
          groups.splice(i ,1)
          break; 
        }          
      }
      if (group.type === "interval") { 
        SMSGroupModel.setState({intervalGroups : groups})
      } else {
        SMSGroupModel.setState({campaignGroups : groups})

      }



      return Parse.Cloud.run("deleteSMSGroup", {botId:BotModel.state.bot.id, groupId:group.id})
      .then((successMsg) => {
        NotificationsModel.displayNotification("Group Delete",null, null, null, null, true);
        return true;
      }).catch(e=> {
        alert("Unable to delete group");
        warn("Error deleting sms group", e)
      })


      
    }

    static getGroupMembers(groupSlug:string) {
      return Parse.Cloud.run("getSMSGroupMembers", {botId:BotModel.state.bot.id, groupSlug:groupSlug})
    }
    static getGroupHistory(groupSlug) {
      return Parse.Cloud.run("getSMSGroupHistory", {groupSlug:groupSlug})

      
    }

    static addMember(member) {
      return Parse.Cloud.run("addSMSGroupMember", {member:member}).then(member => { NotificationsModel.displayNotification("Member Added",null, null, null, null, true); return member;  })
    }
    static removeMember(member) {
      return Parse.Cloud.run("removeSMSGroupMember", {member:member}).then(_ => { NotificationsModel.displayNotification("Member Removed", null, null, null, null, true);  })

    }


    static nameOfGroupFromSlug(slug:string) {
      let group = SMSGroupModel.state.campaignGroups?.find(item => item.slug === slug)
      if (group) { return group.name; }
      group = SMSGroupModel.state.intervalGroups?.find(item => item.slug === slug)
      if (group) { return group.name; }
      return "Unknown Group"
    }



}

