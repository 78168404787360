import {create} from 'zustand'
import BotModel from "../Bots/Bot.Model";
import Parse from "parse"
import { warn } from '../../Helpers/Logging/Logging';

// const log = require('debug')('ExperimenntsModel');


interface StoreInterface {
  experiments: Parse.Object[]; 
}
const BlankState:StoreInterface  = {
  experiments:undefined
}


export default class ExperimentsModel {
  static useState = create<StoreInterface>(set => ({  ...BlankState }))
  static setState = ExperimentsModel.useState.setState; 
  static get state() { return ExperimentsModel.useState.getState(); }
  static reset() {  ExperimentsModel.setState({  ...BlankState }); }

  

    
    static load(forceRefresh:boolean) {
        let experiments = ExperimentsModel.state.experiments;
        if (!forceRefresh && experiments && experiments.length > 0) {  return; }
        var query = new Parse.Query(Parse.Object.extend("Experiment"));
        query.equalTo("bot", BotModel.state.bot);
        query.limit(10000);
        query.find().then(function(experiments) {
          ExperimentsModel.setState({ experiments : experiments });
        }).catch(function(error) {
          warn("Error loading experiments", error);
        })
      }
  
      static async createExperiment(name, nodeParam, quest) {
        var ExperimentClass = Parse.Object.extend("Experiment")
        let node =  JSON.parse(JSON.stringify(nodeParam));
        delete node.layout;
        var experiment = new ExperimentClass();
        experiment.set("bot", BotModel.state.bot)
        experiment.set("name", name)
  
        experiment.set("quest", quest);
        experiment.set("nodeId", node.id);
        experiment.set("node", node);
        experiment.set("running", true);
        experiment.set("archieved", false);
        return experiment.save().then(function(exp) {
            let experiments = [...ExperimentsModel.state.experiments];
            experiments.push(exp);
            ExperimentsModel.setState({ experiments : experiments });
            return exp;
        })
  
      }
  

}