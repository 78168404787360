import Parse from "parse";
import {create} from 'zustand'
import { EmbedCodesModel } from "../Embed/EmbedCodes.Model";

import Bot from "../Parse/Bot";
import BotModel from "./Bot.Model";
import OrangeTheme from '../../Views/Settings/Views/Embed/themes/Orange';

interface StoreInterface {
  bots?: Bot[];

    
}
const BlankState:StoreInterface  = {
    bots:undefined
  }

export default class AllBotsModel {
  static useState = create<StoreInterface>(set => ({  ...BlankState }))
  static setState = AllBotsModel.useState.setState; 
  static get state() { return AllBotsModel.useState.getState(); }
  static reset() {  AllBotsModel.setState({  ...BlankState }); }



  static load(forceReload) {
    
    if (!forceReload && AllBotsModel.state.bots) { return; }
    

    var botQuery = new Parse.Query(Bot);
    botQuery.limit(10000);
    botQuery.ascending("name");
    botQuery.find().then(function(bots) {
      

      bots.sort(function(a, b) {
        var textA = a.get("name").toUpperCase();
        var textB = b.get("name").toUpperCase();
        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
      });
      AllBotsModel.setState({ bots : bots }); 
      }).catch(function(error) {
        
      })


  }
  static updateAvatarForBot(parseFile,bot) {
    bot.set("avatar", parseFile)
    return AllBotsModel.saveBot(bot);
  }

  static updateBot(bot) {
    var bots = AllBotsModel.state.bots;
    for (var i = 0; i < bots.length; i++) {
      if (bots[i].id === bot.id) {
        bots[i] = bot;
        break;
      }
    }
    AllBotsModel.setState({ bots : bots }); 
  }

  static async saveBot(bot) {
    return bot.save().then(function(savedBot) {

      if (bot.id === BotModel.state.bot.id) {   BotModel.updateBot(bot); }
      if (AllBotsModel.state.bots) {
        var  bots = [...AllBotsModel.state.bots];
        for (var i = 0; i < bots.length; i++) {
          if (bots[i].id === savedBot.id) {
            bots[i] = savedBot;
            break;
          }
        }
        AllBotsModel.setState({ bots : bots }); 
      }


      return savedBot ;
    })
  }

  static createNewBot() {
    var UserVariablesClass = Parse.Object.extend("UserVariables");
    var SubscriptionClass = Parse.Object.extend("Subscription");

    var bot = new Bot();
    var userVariables = new UserVariablesClass()
    var subscription = new SubscriptionClass()

    bot.set("name", "New Bot");
    bot.set("hasTrainedNLP", false);
    bot.set("NLPTrainingState", "UNPUBLISHED");
    bot.set("luisAppId", "c94ad6b2-d622-43be-86f2-db4cff40a5aa");
    bot.set("luisAppVersion", "1.0");
    return bot.save().then((theBot) => {
      bot = theBot;
      var userVariables = new UserVariablesClass()
      userVariables.set("bot", bot);
      userVariables.set("variables", {});
      userVariables.set("displayNameId", "Entity personName");
      return userVariables.save()
    }).then((theUVs) => {
      userVariables = theUVs;
      bot.set("userVariables", userVariables);
      subscription.set("bot", bot);
      subscription.set("plan", "free");
      subscription.set("maxUsers", 1);
      return subscription.save()
    }).then((theSub) => {
      subscription = theSub;
      bot.set("subscription", subscription);
      return bot.save();
    }).then((theBot) => {
      bot = theBot;
      var bots = AllBotsModel.state.bots || [];
      (bot as any).brandNew = true;
      bots.push(bot)
      AllBotsModel.setState({bots:bots});
      return EmbedCodesModel.createEmbedCode(OrangeTheme,bot)

    }).then((embedCode) => {
      return bot
    })
  }
  
}
