/* eslint-disable react-hooks/rules-of-hooks */
import Parse from "parse";
import React from "react";
import BotModel from "../Bots/Bot.Model";
import _ from "lodash";
import {create} from 'zustand'

export enum AppType {
  Hubspot = "hubspot"
}

interface StoreInterface {
  apps?: Parse.Object[]; 
}
const BlankState: StoreInterface = {
  apps: undefined
}

export default class AppsModel {
  // static Store = new Store<StoreInterface>({
  //   apps: undefined,
  // });
  // static Store = {
  //   useState : create(set => ({  apps: undefined }))
  //   update:AppsModel.useState.set
  // }

  static useState = create<StoreInterface>(set => ({  ...BlankState }))
  static setState = AppsModel.useState.setState; 
  static get state() { return AppsModel.useState.getState(); }
  static reset() {  AppsModel.setState({  ...BlankState }); }

  static useGetApp(type:AppType) { 
    const { apps } = AppsModel.useState(s => s);
    const [app, setApp] = React.useState<Parse.Object>(null);
    React.useEffect(() => { if (apps) { setApp(apps.find(a => a.get("type") === type)); } }, [apps,type]);
    return app;
  }

  
  static async load(forceRefresh) {

      if (AppsModel.state.apps && AppsModel.state.apps.length > 0 && !forceRefresh && AppsModel.state.apps[0].get("bot") === BotModel.state.bot.id) {  return; }
      var query = new Parse.Query(Parse.Object.extend("App"));
      query.equalTo("bot", BotModel.state.bot);
      query.equalTo("active", true);
      query.limit(10000);
      query.find().then((apps) => {
        AppsModel.setState( {apps:apps});
       }).catch(function(error) {
        
      })
    }
    static loadApp(appType) {
      var query = new Parse.Query(Parse.Object.extend("App"));
      query.equalTo("bot", BotModel.state.bot);
      query.equalTo("active", true);
      query.equalTo("type", appType)
      return query.first() 
    }


    // setStorageVariables(type) {
    //   // StoreUnitId?: string
    //   // StoreUnitTypeId: string
    //   // StoreUnitName: string
    //   // StoreUnitDimensions: string
    //   // StoreUnitRate?: string

    //   const tenantIdName = (type === "siteLink") ? "SiteLink Tenant Id" : "Tenant Id"
    //   const locationIdName = (type === "siteLink") ? "SiteLink Location Id" : "Facility Id"

    //   if (!BotModel.state.bot ||  !BotModel.state.bot.get("userVariables") || !BotModel.state.bot.get("userVariables").get("variables") ) {
    //     
    //     return;
    //   }
    //   let userVariables = BotModel.state.bot.get("userVariables").get("variables"); 
    //   userVariables['TenantId'] = {"id": "TenantId",   "name": tenantIdName,   "type": "string", "protected": true} 

    //   userVariables['LocationId'] = {"id": "LocationId",   "name": locationIdName,   "type": "string", "protected": true} 
    //   userVariables['LocationName'] = {"id": "LocationName",   "name": "Location Name",   "type": "string", "protected": true} 
    //   userVariables['LocationAddress'] = {"id": "LocationAddress",   "name": "Location Address",   "type": "string", "protected": true} 
    //   userVariables['LocationPhone'] = {"id": "LocationPhone",   "name": "Location Phone",   "type": "string", "protected": true} 
    //   userVariables['LocationEmail'] = {"id": "LocationEmail",   "name": "Location Email",   "type": "string", "protected": true} 
    //   userVariables['LocationOfficeHours'] = {"id": "LocationOfficeHours",   "name": "Location Office Hours",   "type": "string", "protected": true} 
    //   userVariables['LocationAccessHours'] = {"id": "LocationAccessHours",   "name": "Location Access Hours",   "type": "string", "protected": true} 

    //   userVariables['StoreUnitId'] = {"id": "StoreUnitId",   "name": "Unit Id",   "type": "string", "protected": true} 
    //   userVariables['StoreUnitTypeId'] = {"id": "StoreUnitTypeId",   "name": "Unit Type Id",   "type": "string", "protected": true} 
    //   userVariables['StoreUnitName'] = {"id": "StoreUnitName",   "name": "Unit Name",   "type": "string", "protected": true} 
    //   userVariables['StoreUnitDimensions'] = {"id": "StoreUnitDimensions",   "name": "Unit Dimensions",   "type": "string", "protected": true} 
    //   userVariables['StoreUnitRate'] = {"id": "StoreUnitRate",   "name": "Unit Rate",   "type": "string", "protected": true} 



    //   BotModel.state.bot.get("userVariables").set("variables", userVariables)
    //   BotModel.state.bot.get("userVariables").save()

    // }


    static createApp(type) {
      var AppClass = Parse.Object.extend("App")
      var query = new Parse.Query(AppClass);
      query.equalTo("bot", BotModel.state.bot);
      query.include("bot");
      query.include("bot.userVariables");

      query.limit(10000);
      var app;
      return query.find().then(async (apps) => {
        for (var i = 0; i < apps.length; i++) {
          if (apps[i].get("type") && apps[i].get("type") == type) {
            app = apps[i];
            return Promise.resolve(app);
          }
        }
        if (!app) {
          app = new AppClass()
        }
          app.set("active", true);
          app.set("token", null);
        
        if (type === "slack") {
          app.set("data", {timeZone:"America/Los_Angeles"});
        } else {
          app.set("data", null);

        }
        if (type === "siteLink") {
          // if (!BotModel.state.bot || !BotModel.state.bot.get("userVariables").get("variables") ) {
          //   return Promise.reject("No bot to add variables to")
          // }
          // let userVariables = BotModel.state.bot.get("userVariables").get("variables"); 
          // userVariables['TenantId'] = {"id": "TenantId",   "name": "SiteLink Tenant Id",   "type": "string", "protected": true} 

          // userVariables['LocationId'] = {"id": "LocationId",   "name": "SiteLink Location Id",   "type": "string", "protected": true} 
          // userVariables['LocationName'] = {"id": "LocationName",   "name": "Location Name",   "type": "string", "protected": true} 
          // userVariables['LocationAddress'] = {"id": "LocationAddress",   "name": "Location Address",   "type": "string", "protected": true} 
          // userVariables['LocationPhone'] = {"id": "LocationPhone",   "name": "Location Phone",   "type": "string", "protected": true} 
          // userVariables['LocationEmail'] = {"id": "LocationEmail",   "name": "Location Email",   "type": "string", "protected": true} 
          // userVariables['LocationOfficeHours'] = {"id": "LocationOfficeHours",   "name": "Location Office Hours",   "type": "string", "protected": true} 
          // userVariables['LocationAccessHours'] = {"id": "LocationAccessHours",   "name": "Location Access Hours",   "type": "string", "protected": true} 
          // BotModel.state.bot.get("userVariables").set("variables", userVariables)
          // log("Sitelink add", BotModel.state.bot.get("userVariables").get("variables"))
          // await BotModel.state.bot.get("userVariables").save()

          // this.setStorageVariables("siteLink")       

            


          const vars = {
           "smallMaxSize":50,
           "mediumMaxSize":150,
           "largeMaxSize":300,
           "actionButton":"Reserve Now",
           sizeSmallDescription:      "Similar in size to a walk-in closet. Holds the contents of a studio apartment.", 
           sizeMediumDescription:     "Similar in size to a small bedroom. Holds the contents of a typical garage.", 
           sizeLargeDescription:      "Similar in size to a 1 to 1.5 car garage. Holds the contents of a 3-4 bedroom house", 
           sizeExtraLargeDescription: "You have a lot of stuff! These units have plenty of room for your whole house!", 
           sizeParkingDescription:    "Each spot varies, but sizing ranges from a small utility trailer, vehicle, or camper, to a large Class A / Motorhome.", 
           "showPrice":true,
           "showFloor":true
          }

          app.set("data", vars);


        }

        if (type === "tenant") {
          // this.setStorageVariables("tenant")       


          

        }

        app.set("type", type);
        app.set("bot", BotModel.state.bot);
        return app.save();
      }).then((theApp)  =>{

        app = theApp;
        var found = false;
        var apps = [...AppsModel.state.apps];
        if (!apps) {  }
        for (var i = 0; i < apps.length; i++) {
          if (apps[i].id == app.id) {
            apps[i] = app;
            found = true;
            break;
          }
        }
        if (!found) { apps.push(app); }

        AppsModel.setState( {apps:apps});



        return Promise.resolve(app);

      })
    }


  static  update(app:Parse.Object, shouldSave?:boolean) {
      var apps = [...AppsModel.state.apps];

      
      
      if (!apps) {  return; }
      for (var i = 0; i < apps.length; i++) {
        if (apps[i].id === app.id) {
          apps[i] = app;
          AppsModel.setState( {apps:apps});
     
          
          if (shouldSave) { app.save(); }
          break;
        }
      }
    }
    static  enabledApps() {
      if (BotModel.state.bot && BotModel.state.bot.get("apps")) {
        return BotModel.state.bot.get("apps");
      } return null;

    }
    static hasApp(type) {
      if (BotModel.state.bot && BotModel.state.bot.get("apps")) {
        if  (BotModel.state.bot.get("apps").includes(type)) { return true;  }
        return false;
      }
      return null;
    }
    static getApp(type) {
      var apps = AppsModel.state.apps;
      if (!apps) { return null; }
      for (var i = 0; i < apps.length; i++) {
        if (apps[i].get("type") && apps[i].get("type") == type) {
          return apps[i];
        }
      }
      return null;
    }
    static  deactivateApp(app) {
      var apps = [...AppsModel.state.apps];
      if (!apps) {  return; }
      for (var i = 0; i < apps.length; i++) {
        if (apps[i].id === app.id) {
          apps.splice(i, 1);
          if (BotModel.state.bot && app.get("bot") && BotModel.state.bot.id == app.get("bot").id && BotModel.state.bot.get("apps")) {
            var bot = BotModel.state.bot;
            var botApps  = bot.get("apps");
            var newBotApps = []
            for (var i = 0; i < botApps.length; i++) {
              if (botApps[i] !== app.get("type")) { newBotApps.push(botApps[i]); }

            }
            bot.set("apps", newBotApps);
            bot.save()
            AppsModel.setState( {apps:apps});

          
          }  else {
            AppsModel.setState( {apps:apps});
          }
          app.set("active", false);
          app.save();
          break;
        }
      }
    }

}
