import React from 'react';
import { Outlet } from 'react-router-dom';
import { NavBar } from '../../../Models/NavBar/NavBar.Model';
import { MainMenu } from '../MainMenu/MainMenu';

import "./NavBarView.scss";
export const NavBarLayout:React.FC<any> = () => {
        return <> 
        <NavBarView />
        <MainMenu />
        <div className="pageContent navBarPageContent">
        <Outlet />
          </div>
        </>
      }

export const NavBarView = () => {
  const {left, right, center} = NavBar.useState(s => s)

return <header className="NavBar">     
  <div className="leftNav">{left}</div>
  <div className="centerNav">{center}</div>
  <div className="centerNavSpacer"/>
  <div className="rightNav">{right}</div>
        </header>; 
}
