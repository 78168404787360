import React from "react";
import SSSwitch from "../../../../../Components/SSSwitch/SSSwitch";
import "./NavBar.Switch.scss";
interface Props {
    on:boolean,
    onChange:(on:boolean) => void
    name?:string
    analytics?:any
    children?:any
    isInRightCol?:boolean
}
export class NavBarSwitch extends React.Component<Props> {
    render() {
      const switchComp = <SSSwitch  on={this.props.on} onChange={this.props.onChange} name={this.props.name} analytics={this.props.analytics}/>;
      if (this.props.children) {
        return <div className="NavBarSwitch">
                    <label>{this.props.children}</label>

          {switchComp}
        </div>;
      }
      return switchComp;
    }
  }
  