import { AgentInterface } from "@swivl/swivl-lib";
import {create} from 'zustand'
import UserModel from "../User/UserModel";
import { toast } from 'react-toastify';


import "./Toast.scss"
import { NavigationHelper } from "../../Helpers/Navigation/Navigation.Helper";
import EscalationsModel from "../Escalations/Escalations.Model";
import { ConversationsModel } from "../Conversations/Conversations.Model";
import { AgentModel } from "../Agent/Agent.Model";

const log = require('debug')("AgentModel");

interface StoreInterface {

}
const BlankState:StoreInterface  = {

}
export class NotificationsModel {
  static useState = create<StoreInterface>(set => ({  ...BlankState }))
  static setState = NotificationsModel.useState.setState; 
  static get state() { return NotificationsModel.useState.getState(); }
  static reset() {  NotificationsModel.setState({  ...BlankState }); }

  static   toastsById:any = {}

//  static hasNotificationTime?:number
//  static hasNotification:boolean;
//  static numberOfHootsPlayed:number = 0;


 static soundInterval = setInterval( () => {
  if (ConversationsModel.hasUnresolvedEscalations() && AgentModel.state.isAvailable ) {
    // NotificationsModel.hasNotificationTime = Date.now();
    // NotificationsModel.numberOfHootsPlayed = NotificationsModel.numberOfHootsPlayed + 1;

    NotificationsModel.dingSound.play().catch((e) => {
      
    })   
  }

}, 6000);


  // static testInterval = setTimeout(() => {

  //   NotificationsModel.displayNotification("Test Notification",null, null, true, true)
  // }, 7000)


  static dingSound = new Audio('https://swivl-cdn.s3-us-west-1.amazonaws.com/notification_sound.mp3');


  

  static notificationState() {
    const userSettings =  (UserModel.state.user && UserModel.state.user.get("settings")) ? UserModel.state.user.get("settings") : {}
    if (!userSettings) { return null }
    if (userSettings.isNotificationOn) {  if (userSettings.notificationType === "alert") { return "alert"; } else { return "push"; }
    } else {  if (userSettings.notificationType === "alert") { return "off_alert"; } else { return "off_push"; }  }
  }
  
  
  static displayNotification(message, onClick?, onClose?, updateIcon?, playSound?, autoClose?, className?, bodyClassName?, notificationId?:string) {
    log("displayNotification ");
    // if (updateIcon) {
    //   NotificationsModel.hasNotificationTime = Date.now();
    //   NotificationsModel.hasNotification = true;
    //   NotificationsModel.numberOfHootsPlayed = 0;

    // }
    if (playSound) {
      NotificationsModel.dingSound.play();
    }
    let options:any = { 
      position: toast.POSITION.BOTTOM_RIGHT,
      progressClassName: "notification-progress-bar"
    };
    if (onClick) { options.onClick = () => {
      // NotificationsModel.clearNotification();
      onClick();
      }
    }
    if (onClose) {
      options.onClose = () => {
      // NotificationsModel.clearNotification()
      onClose();
    }; } else {
      options.onClose = () => {
        // NotificationsModel.clearNotification() ;
      }
    }
    if (autoClose && autoClose > 0) {
      options.autoClose = autoClose;
    } else {
      options.autoClose = false;
    }
    if (className) { options.className = className; } else { options.className= "generic-notification"}
    if (bodyClassName) { options.bodyClassName = bodyClassName; } else { options.bodyClassName = 'generic-notification-body'}


    if (notificationId) {
      NotificationsModel.toastsById[notificationId] = toast(message, options);
    } else {
      toast(message, options);
    }


  }

  // static clearNotification() {
  //   
  //   NotificationsModel.hasNotification = false;
  // }

  static successToast(message:string) { 
    toast.success(message, {
      position: "bottom-right",
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      progress: undefined,
      });
    }

  static errorToast(errorMsg:string) {
    toast.error(errorMsg, {
      position: "bottom-right",
      autoClose: false,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      progress: undefined,
      });
  }
  
  

  static receivedNotification(notification) {
    log("receivedNotification",notification)

    if (AgentModel.state.isAvailable) {
      const onClick = () => { NavigationHelper.navigate("/conversations/" + notification.botUserId); };
      //     displayNotification(message,             onClick, onClose, updateIcon, playSound, autoClose, className, bodyClassName) {
      // NotificationsModel.displayNotification("Testing",          onClick, null,     true,      true,     null,       "chat-notification", "chat-notification-body")

      NotificationsModel.displayNotification(notification.message,onClick,  null,  true,      true,      false,    "chat-notification", "chat-notification-body")
    } else {
      log("Not going to show notification")
    }
  }


  static receivedEscalationNotification(escalation) {
    if (!AgentModel.state.isAvailable) {
      log("Not going to show notification receivedEscalationNotification")

      return;
    }
    NotificationsModel.dingSound.play().catch((e) => { })   

    const location =  window.location;
    if (location.pathname.includes("conversations")) {
      log("On conversation page, not going to show notification")
      return;
    }

      const onClick = () => { NavigationHelper.navigate("/conversations/" + escalation.user.id); };
  
      log("Should display notification");


   


      NotificationsModel.displayNotification("Someone needs assistance",onClick,  null,  true,      true,      false,    "chat-notification", "chat-notification-body", escalation.id)

      
  }

  static clearEscalationNotification(escalationId ) {
    
    if (NotificationsModel.toastsById[escalationId]) {
      // const unresolvedEscalations = EscalationsModel.unresolvedEscalations();
      // 
      // if (unresolvedEscalations.length > 1) {
      //   // NotificationsModel.clearNotification();
      // }
      toast.dismiss(NotificationsModel.toastsById[escalationId]);
      delete NotificationsModel.toastsById[escalationId];
    }
  }

  
}


