import _ from 'lodash'
import Parse from 'parse'
import {create} from 'zustand'

import Brain from '../Parse/Brain';

import BotModel from '../Bots/Bot.Model';
import { QuestsModel } from '../Quests/Quests.Model';
import { warn } from '../../Helpers/Logging/Logging';
import { BrainInterface, BrainType, InheritanceStatus, LLMBrainInterface, LLMData, LLMModel, NLPBrainData, NLPBrainInterface, NLPBrainModel, Slug } from '@swivl/swivl-lib';
import { Config } from '../../Config/Config';
import { nanoid } from 'nanoid';
const log = require('debug')('BrainsModel');


interface StoreInterface {
    brains?: BrainInterface[]
    brainsMap?: {[slug:Slug]:BrainInterface}
  }


  
  const BlankState:StoreInterface  = {
    brains:undefined,
  }
  
  export class BrainsModel { 
    static useState = create<StoreInterface>(set => ({  ...BlankState }))
    static setState = BrainsModel.useState.setState; 
    static get state() { return BrainsModel.useState.getState(); }
    static reset() {  BrainsModel.setState({  ...BlankState }); }
  
  

    static load(forceRefresh) {
      if (BrainsModel.state.brains && BrainsModel.state.brains.length > 0 && !forceRefresh ){ return }
      return fetch(`https://${Config.SERVER_HOSTNAME}/brains/${BotModel.state.bot.id}`)
      .then(response => response.json())
      .then(brainsMap => {
        BrainsModel.setState({ brains : Object.values(brainsMap), brainsMap: brainsMap});
      }).catch(err => {
        warn("Error loading quests", err);
        return false;
      })
    }

    static loadBrain(brainSlug:Slug) {
      return fetch(`https://${Config.SERVER_HOSTNAME}/brains/${BotModel.state.bot.id}/brain/${brainSlug}`)
      .then(response => response.json())
      .then(data => {
        
        let brainsMap = BrainsModel.state.brainsMap || {};
        brainsMap[brainSlug] = data;
        BrainsModel.setState({ brains : Object.values(brainsMap), brainsMap: brainsMap});
      }).catch(err => {
        warn("Error loading quests", err);
        return false;
      })
    
    }



    static async createNLPBrain(name:string, data: NLPBrainData, model: NLPBrainModel):Promise<NLPBrainInterface> {
      const brain:NLPBrainInterface = {
            slug: nanoid(10),
            name: name,
            botId: BotModel.state.bot.id,
            type: BrainType.NLP,
            data: data,
            model: model,
            inheritance: InheritanceStatus.NONE,
      }
    
      const newBrain = await Parse.Cloud.run("createBrain",{botId:BotModel.state.bot.id, brain:brain } )
      let brainsMap = BrainsModel.state.brainsMap || {};
      brainsMap[newBrain.slug] = newBrain;
      BrainsModel.setState({ brains : Object.values(brainsMap), brainsMap: brainsMap});
      return newBrain; 
    

    }
    static async createLLMBrain(name:string, data: LLMData, model: LLMModel):Promise<NLPBrainInterface> {
      const brain:LLMBrainInterface = {
            slug: nanoid(10),
            name: name,
            botId: BotModel.state.bot.id,
            type: BrainType.LLM,
            data: data,
            model: model,
            inheritance: InheritanceStatus.NONE,
      }
    
      const newBrain = await Parse.Cloud.run("createBrain",{botId:BotModel.state.bot.id, brain:brain } )
      let brainsMap = BrainsModel.state.brainsMap || {};
      brainsMap[newBrain.slug] = newBrain;
      BrainsModel.setState({ brains : Object.values(brainsMap), brainsMap: brainsMap});
      return newBrain; 
    

    }

    static deleteBrain(brain:BrainInterface) {
      // throw new Error("Not Implemented")

      if (!brain) { warn("No Brain to delete"); return Promise.resolve(true); }
      var brains = BrainsModel.state.brains;
      if (brains) {
        for (var i = 0; i < brains.length; i++) {
          if (brains[i].slug === brain.slug) {
            brains.splice(i, 1);
          }
        }
        BrainsModel.setState({ brains : brains });
      }
      Parse.Cloud.run("deleteBrain", { botId: BotModel.state.bot.id, brain: brain })

    }

    static updateBrain(brain:BrainInterface, shouldSave:boolean) {
      let brainsMap = BrainsModel.state.brainsMap || {};
      brainsMap[brain.slug] = brain;
      
      BrainsModel.setState({ brains : Object.values(brainsMap), brainsMap: brainsMap});
      if (shouldSave) {
        Parse.Cloud.run("updateBrain", { botId: BotModel.state.bot.id, brain: brain })
      }
      
      
      // var brains = BrainsModel.state.brains.slice();
      // if (!brain.updateCount) { brain.updateCount = 0; }
      // brain.updateCount++;
      // for (var i = 0; i < brains.length; i++) {
      //   if (brains[i].id === brain.id) {
      //     brains[i] = brain;
      //     BrainsModel.setState({ brains : brains });
      //     if (shouldSave) {
      //       brain.save().catch(function(error) { warn("Error saving brain"); });
      //     }
      //     return;
      //   }
      // }
      // warn("Didn't find brain!", brain, BrainsModel.state.brains, brains);
    }
    static setUnhandledOnBrain(unhandled, brain) {
      throw new Error("Not Implemented")

      // brain.set("onUnhandled", unhandled);
      // this.updateBrain(brain, true)
    }
    static setIncludeIntentOnBrain(include, intent, brain) {
      throw new Error("Not Implemented")

    //   log("setIncludeIntentOnBrain (include, intent, brain)", include, intent, brain);
    //   var logic = brain.get("logic")
    //   if (!logic.hasOwnProperty(intent.name)) {   
    //     logic[intent.name] = {};

    //     _.forOwn(logic, (value) => { if (value && value.name && value.name === intent.name) {
    //       log("setIncludeIntentOnBrain This already exists!");
    //       logic[intent.name] = value; 

    //     }})

        
    //   }
    //   logic[intent.name].include = include;
    //   logic[intent.name].name = intent.name;

    //   if (!QuestsModel.state.quests) {
    //     warn("Quests Not Loaded");
    //     return;
    //   }

    //   if (!logic[intent.name].allRequirementsMet)  {
    //     logic[intent.name].allRequirementsMet = {
    //        sendMessage:false,
    //        text: "",
    //        goto: QuestsModel.state.defaultUnhandled.slug
    //      }
    //   }



    //   brain.set("logic", logic);
    //   this.updateBrain(brain, true)
    }

    static setRequiredOnEntityInIntentOnBrain(isRequired, entity, intent, brain) {
      throw new Error("Not Implemented")

      // var logic = brain.get("logic")
      // var intentLogic = logic[intent.name]
      // if (!intentLogic.requiredEntities) { intentLogic.requiredEntities = {}; }
      // if (!intentLogic.requiredEntities.hasOwnProperty(entity.id)) {
      //   intentLogic.requiredEntities[entity.id]  = {}
      // }
      // intentLogic.requiredEntities[entity.id].isRequired = isRequired;
      // intentLogic.requiredEntities[entity.id].name = entity.name;

      // if (isRequired && !intentLogic.defaultMissingRequirements) {
      //    intentLogic.defaultMissingRequirements = {
      //        title:"Default",
      //        sendMessage:true,
      //        text: "",
      //        goto: QuestsModel.state.defaultUnhandled.slug
      //    }
      // }

      // if (isRequired && !intentLogic.missingRequirements) {
      //   intentLogic.missingRequirements = {};
      // }

      // //Validation to make sure we remove any conditions that no  longer apply.
      // if (!isRequired) {
      //   for (var key in intentLogic.missingRequirements) {
      //     if (intentLogic.missingRequirements.hasOwnProperty(key) ) {
      //       if  (key.includes(entity.id)) {
      //         delete intentLogic.missingRequirements[key];
      //       }
      //     }
      //   }
      // }

      // logic[intent.name] = intentLogic;
      // brain.set("logic", logic);
      // this.updateBrain(brain, true);
    }


    static setSaveOnAutomaticEntityInIntentOnBrain(shouldSave, entity, intent, brain) {
      throw new Error("Not Implemented")

      //   var logic = brain.get("logic")
      //   var intentLogic = logic[intent.name]
      //   if (!intentLogic.saveAutomaticEntities) { intentLogic.saveAutomaticEntities = []; }
      //   if (shouldSave) {
      //     if(!intentLogic.saveAutomaticEntities.includes(entity.id)) {
      //       intentLogic.saveAutomaticEntities.push(entity.id);
      //     }
      //   } else {
      //     for (var i = 0; i < intentLogic.saveAutomaticEntities.length; i++) {
      //       if (intentLogic.saveAutomaticEntities[i] === entity.id) {
      //         intentLogic.saveAutomaticEntities.splice(i, 1);
      //       }
      //     }
      //   }
      //   logic[intent.name] = intentLogic;
      //   brain.set("logic", logic);
      //   this.updateBrain(brain, true);
      }




      static setLogicOnBrain(logic, brain, shouldSave) {
        throw new Error("Not Implemented")
      // brain.set("logic", logic);
      // this.updateBrain(brain, shouldSave);
    }

    static nameForBrain(brainSlug?:Slug) {
      if (!brainSlug) { return "Not Set"; }
      if (!BrainsModel.state.brainsMap) { return undefined; }
      let brain = BrainsModel.state.brainsMap[brainSlug];
      if (!brain) { return "Unknown" }
      return brain.name;
      
      // if (brain.get("isGlobalBrain")) {
      //   return "Global Brain"
      // } else if (brain.get("quest")) {
      //   return brain.get("quest").get("name")
      // } else if (brain.get("journey")) {
      //   return brain.get("journey").get("name")
      // } else {
      //   return "Unknown Brain"
      // }
    }
    static getGlobalBrainSlug() {
      const bot = BotModel.state.bot;
      if (!bot) { return undefined; }
      return bot.get("brainSlug") || bot.get("parent")?.get("brainSlug")

    }

}
