
export enum Color {
    Clear = "colorClear",
    Orange = "colorOrange",
    Yellow = "colorYellow",
    Red = "colorRed",
    Teal = "colorTeal",
  
    Dark = "colorDark",
    Light = "colorLight"
  
  }
  