import * as React from 'react';
import "./Input.scss";
import { Size } from '../Shared/Size';
import { FaSearch } from 'react-icons/fa'
import { IoClose } from 'react-icons/io5'
import { IconType } from 'react-icons'
import { DebouncedInput } from './DebouncedInput';
import { Color } from '../Shared/Shared';
import { Spinner } from '../Spinner/Spinner';

enum InputType {
  Text = "text",
  Number = "number",
  Integer = "integer",
  Scalar = "scalar",
  Password = "password",
  Telephone = "tel"
}

interface InputProps {
  value?:string;
  isSearch?:boolean;
  isSearching?:boolean;
  isRound?:boolean;
  isLoading?:boolean;
  isDisabled?:boolean;
  // color?:Color;
  size?:Size;
  className?:string 
  name?:string;
  enableAutoComplete?:boolean;
  type?:InputType;
  placeholder?:string;
  min?:number;
  max?:number;
  step?:number;
  icon?:IconType;
  rightIcon?:IconType;
  showClearButton?:boolean 
  onBlur?(event:React.ChangeEvent<HTMLInputElement>)
  onFocus?(event:React.ChangeEvent<HTMLInputElement>)
  onChange?(event:React.ChangeEvent<HTMLInputElement>):void;
  onSet?(value: React.SetStateAction<string>):void; 
  onReturnPressed?():void;
  

  debounce?:number
}






export class Input extends React.Component<InputProps> {
  static Type = InputType;
  static Size = Size; 
  static Color = Color; 

  private inputRef = React.createRef<HTMLInputElement>()

  timeoutDebouce = null;

  constructor(props:InputProps) {
    super(props)
    this.clear     = this.clear.bind(this);
    this.onKeyDown = this.onKeyDown.bind(this)
    this.onChange  = this.onChange.bind(this)
  }
  clear() {
    if  (this.props.onChange) { this.props.onChange({target:{name:this.props.name, value:""}} as React.ChangeEvent<HTMLInputElement>); }
    if  (this.props.onSet) { this.props.onSet(""); }
  
  }
  focus() { if (this.inputRef && this.inputRef.current) { this.inputRef.current.focus(); } }
  onKeyDown(e:React.KeyboardEvent){  if(e.keyCode == 13  && this.props.onReturnPressed){ this.props.onReturnPressed(); } }
  onChange(event:React.ChangeEvent<HTMLInputElement>) {

    


    if (this.props.debounce && this.props.debounce > 0) {
      if(this.timeoutDebouce) {
        clearTimeout(this.timeoutDebouce)
      }
      this.timeoutDebouce = setTimeout(() => {
          if  (this.props.onChange) { this.props.onChange(event); }
          if  (this.props.onSet) { this.props.onSet(event.target.value); }
        }, this.props.debounce)
    } else {
      if  (this.props.onChange) { this.props.onChange(event); }
      if  (this.props.onSet) { this.props.onSet(event.target.value); }
    }
  }

  render() {
    const { value, isSearch, isSearching, isRound, isLoading, isDisabled,  size, name, enableAutoComplete,  placeholder, min, max, step, icon, rightIcon,showClearButton , debounce} = this.props
    let controlClassName = `SCInput ${isRound ? "isRound" : ""} ${isLoading ? "isLoading" : ""} ${size ? size : Size.Medium} ${isDisabled ? "isDisabled" : ""}  ${icon || isSearch ? "hasLeftIcon" : ""} ${rightIcon ? "hasRightIcon" : ""}`;



    let className = "input";
    let leftIconType = this.props.icon;
    let rightIconType = this.props.rightIcon
    let leftIconView, rightIconView;


    

    if (this.props.isSearch) { 
      leftIconType = <FaSearch /> as unknown as  IconType; 
    }





    let type:string;
    if (!this.props.type) {
      type = "text";
    } else if (this.props.type === InputType.Integer || this.props.type === InputType.Scalar) {
      type = "number";
    } else if (this.props.type === InputType.Password) {
      type = "password"
    }

    const autoComplete = (enableAutoComplete) ? "on" : "off";


    if (leftIconType)  {
      leftIconView =  <span className={"icon isLeft"}>
                    {isSearching ? <Spinner /> : leftIconType as any} 
                    </span>
    }

    if (rightIconType || this.props.showClearButton) {
      if (showClearButton) { 
        if (this.props.value && this.props.value.length) {
          let clearIcon = rightIconType ? rightIconType : <IoClose /> as unknown as  IconType;
          rightIconView =  <span className={"icon isRight clickableIcon"} onClick={this.clear}>
          {clearIcon as any} 
          </span>
        }
      } else {
      rightIconView =  <span className={"icon isRight"}>
                   {rightIconType as any} 
                    </span>
      }
    }
    if (this.props.className) {
      controlClassName = this.props.className + " " + controlClassName;
    }


 


    return <div className={controlClassName} >
                  {leftIconView}
            <input
              ref={this.inputRef}
              className={className}
              type={type}
              name={name}
              onChange={this.onChange}
              value={value}
              onKeyDown={this.onKeyDown}
              placeholder={placeholder}
              max={max}
              min={min}
              step={step}
              autoComplete={autoComplete}
              readOnly={isDisabled}
              onFocus={this.props.onFocus}
              onBlur={this.props.onBlur}
               />
            {rightIconView}
          </div>;
   }
};
