import { ConversationItem } from '@swivl/swivl-lib';
import Parse from 'parse';
import {create} from 'zustand'
import { AgentModel } from '../Agent/Agent.Model';
import { BotUserModel } from '../BotUser/BotUser.Model';
import { EscalationStatus } from '../Escalations/Escalations.Model';
import { NotificationsModel } from '../Notifications/Notifications.Model';


const objectsEqual = (o1, o2) => { return typeof o1 === 'object' && Object.keys(o1).length > 0   ? Object.keys(o1).length === Object.keys(o2).length   && Object.keys(o1).every(p => objectsEqual(o1[p], o2[p])) : o1 === o2; }
const arraysEqual = (a1, a2) =>  { return   a1.length === a2.length && a1.every((o, idx) => objectsEqual(o, a2[idx]));}
const lastInteractionSortFunction = (a,b) => {
  return (b.lastInteraction || 0) -  (a.lastInteraction || 0)

  
}

const nameSort = (a:ConversationItem,b:ConversationItem)  => {
  const bVal = b.name || b.id;
  const aVal = a.name || a.id;

  if ( aVal< bVal ){
    return -1;
  }
  if ( aVal > bVal){
    return 1;
  }
  return 0;
}


const sortFunction = (a:ConversationItem,b:ConversationItem) => {
  const bVal = b.lastSeen || b.lastInteraction ||  new Date(-7640000000000000);
  const aVal = a.lastSeen || a.lastInteraction ||  new Date(-7640000000000000);
  return bVal.getTime()- aVal.getTime();
}
// const minDat = new Date(-7640000000000000);
const assignmentHistorySortFunction = (a,b) => { 
  let _b = ConversationsModel.assignmentHistory && ConversationsModel.assignmentHistory[b.escalation.id] && ConversationsModel.assignmentHistory[b.escalation.id].date && ConversationsModel.assignmentHistory[b.escalation.id].date.getTime  ? ConversationsModel.assignmentHistory[b.escalation.id].date.getTime() : 0;
  let _a = ConversationsModel.assignmentHistory && ConversationsModel.assignmentHistory[a.escalation.id] && ConversationsModel.assignmentHistory[a.escalation.id].date && ConversationsModel.assignmentHistory[a.escalation.id].date.getTime  ? ConversationsModel.assignmentHistory[a.escalation.id].date.getTime() : 0;
  return b - a;
}

type AssignmentHistoryType = { [escalationId:string]:{active:boolean, date:Date} }
type FrozenItemsType = { [botUserId:string]:{item:ConversationItem, freezeUntil:Date} } 
export type SortedConversationItems = {
  notifications: ConversationItem[],
  myEscalations: ConversationItem[],
  othersEscalations: ConversationItem[],
  activeConversations: ConversationItem[],
  inactiveConversations: ConversationItem[],
}
interface StoreInterface {
    items?:ConversationItem[]
    hasEscalations?:boolean
    // frozenItems: FrozenItemsType // Tracks Recently updated items
    // assignmentHistory:AssignmentHistoryType // Tracks esclations a user has been assigned to. 
    sortedItems: SortedConversationItems,
    hasStartedShift:boolean
}
  const FREEZE_TIME = 1000 * 2.5; // 5 seconds
  
  const BlankState:StoreInterface  = {
    items:undefined,
    hasEscalations:false,
    hasStartedShift:false,
    sortedItems: {
      notifications: [],
      myEscalations: [],
      othersEscalations: [],
      activeConversations: [],
      inactiveConversations: [],
    }
  }
  
  
  export class ConversationsModel {
    static useState = create<StoreInterface>(set => ({  ...BlankState }))
    static setState = ConversationsModel.useState.setState; 
    static get state() { return ConversationsModel.useState.getState(); }
    static reset() {  ConversationsModel.setState({  ...BlankState }); ConversationsModel.frozenItems = {}; ConversationsModel.assignmentHistory = {};  }
    static frozenItems:FrozenItemsType = {}; 
    static assignmentHistory:AssignmentHistoryType = {};

    

    static updateItems(items:ConversationItem[], forceUpdate?:boolean) {
  
      // if (forceUpdate || !ConversationsModel.state.items || !arraysEqual(ConversationsModel.state.items, items)) {

      const ids = items.map(i => i.id);

            BotUserModel.loadUsersByIds(ids);
            const frozenItems = ConversationsModel.frozenItems;
            const now = new Date();
            const _items = items.map(i => {
              if (typeof i.lastInteraction === 'string') {
                i.lastInteraction = new Date(i.lastInteraction);
              }
              if (typeof i.lastSeen === 'string') {
                i.lastSeen = new Date(i.lastSeen);
              }
           
                if (frozenItems[i.id]) {
                  

                    if (frozenItems[i.id].freezeUntil > new Date()) {
                      
                    return frozenItems[i.id].item;
                  } 
              
              } 
              return i;

              }); 

      
              

            ConversationsModel.setAndSortItems(_items);

          // } else {
          //   // 

          // }
    }



    static removeFromAssignmentHistoryData(item:ConversationItem) {
      if (!item.escalation) { return };
      if (!ConversationsModel.assignmentHistory[item.escalation.id]) { return; }
      ConversationsModel.assignmentHistory[item.escalation.id].active = false; 
    }

    static setAndSortItems(items:ConversationItem[] ) {
      const agent = AgentModel.state.agent;
      let data = {
          notifications: [],
          myEscalations: [],
          othersEscalations: [],
          activeConversations: [],
          inactiveConversations: [],
      }
      if (!items || !agent) { 
          
          return data;
      }
      
      var hasEscalations = false;
      
      const allUsers = BotUserModel.state.allUsers //allUsers[item.id]

      items.forEach((item) => {
        if (!item.name && allUsers && allUsers[item.id]) {
          
          item.name = BotUserModel.displayNameForUser(allUsers[item.id])
        }
        // if (!item.lastInteraction) {
        //   item.lastInteraction = minDat;
        //   
        // }

        if (item.archived) {
          
          return; 
        }
        if ((item as any).isBlocked) {
          return;
        }
          if (item.escalation) {
            
            if (agent && 
              item.escalation.agent && 
              item.escalation.agent.id === agent.id && 
              item.escalation.status === EscalationStatus.ASSIGNED) {
                if (!hasEscalations && !ConversationsModel.state.hasEscalations === false) {
                    NotificationsModel.dingSound.play().catch((e) => { })   
                  }
                hasEscalations = true;
            }
            

              if (agent && item.escalation.agent && item.escalation.agent.id === agent.id) {
                

                if ((item.escalation.status === EscalationStatus.ASSIGNED) && item.escalation.id && !ConversationsModel.assignmentHistory[item.escalation.id]) {
                  ConversationsModel.assignmentHistory[item.escalation.id] = {active:true, date:new Date()};
                }
             
                if (item.escalation.status === EscalationStatus.ACTIVE) {
                      data.myEscalations.push(item);
                      return; 
                }
                if (item.escalation.id &&  ConversationsModel.assignmentHistory[item.escalation.id] && ConversationsModel.assignmentHistory[item.escalation.id].active) {
                     data.notifications.push(item);
                     return; 
                 }

              } else {
                  if (ConversationsModel.assignmentHistory[item.escalation.id ] && ConversationsModel.assignmentHistory[item.escalation.id ].active) { // It HAD BEEN assigned to me but now isn't. I must know!
                    data.notifications.push(item);
                      return; 
                  }
                  if ([EscalationStatus.ACTIVE, EscalationStatus.PENDING,  EscalationStatus.ASSIGNED].includes(item.escalation.status)) {
                    data.othersEscalations.push(item);
                    return; 
                  }
              }
          }
          if (item.active) {
             
              data.activeConversations.push(item);
              return;
          }
          
          data.inactiveConversations.push(item);

          

      })


   
      


      data.notifications.sort(assignmentHistorySortFunction) // (a,b) => { return ConversationsModel.assignmentHistory[b.escalation.id].date.getTime() - ConversationsModel.assignmentHistory[a.escalation.id].date.getTime() });
      data.myEscalations.sort(assignmentHistorySortFunction) // (a,b) => { return ConversationsModel.assignmentHistory[b.escalation.id].date.getTime() - ConversationsModel.assignmentHistory[a.escalation.id].date.getTime() });
      data.othersEscalations.sort((a,b) => { return (b.escalation?.createdAt || 0) -  (a.escalation?.createdAt || 0 )});
      data.activeConversations.sort(nameSort);
      data.inactiveConversations.sort(sortFunction);



      ConversationsModel.setState({
        items:items,
        sortedItems:data,
        hasEscalations:hasEscalations
      });

    }



    static endTakeover(botUserId:string) {
      
      if (!botUserId || !ConversationsModel.state.items || !ConversationsModel.state.items.length) { return; }
      const _item = ConversationsModel.state.items.find(i => i.id === botUserId);
      if (!_item) { return; }
      ConversationsModel.removeFromAssignmentHistoryData(_item)

      
      const item = {..._item};

      
      if (item.escalation) {
        NotificationsModel.clearEscalationNotification(item.escalation.id)
        
        delete item.escalation;
      }
      item.takenOver = false;

      ConversationsModel.frozenItems[item.id] = {item, freezeUntil: new Date(new Date().getTime() + FREEZE_TIME)}

      const items = [...ConversationsModel.state.items].map(i => { return i.id === item.id ? item : i});
      

      
      ConversationsModel.updateItems(items, true);
  

      // Parse.Cloud.run("rejectEscalation", {escalationId:item.escalation.id,agentId:AgentModel.state.agent.id}).then((response) => {
      // }).catch(e => {
        
      // })
    

    }


    static takeoverConversation(botUserId:string) {
      if (!botUserId || !ConversationsModel.state.items || !ConversationsModel.state.items.length) { return; }
      const _item = ConversationsModel.state.items.find(i => i.id === botUserId);
      if (!_item) { return; }
      ConversationsModel.removeFromAssignmentHistoryData(_item)
      const item = {..._item};
      item.takenOver = true;

      if (item.escalation) {
        NotificationsModel.clearEscalationNotification(item.escalation.id)
      }

      ConversationsModel.frozenItems[item.id] = {item, freezeUntil: new Date(new Date().getTime() + FREEZE_TIME)}
      const items = [...ConversationsModel.state.items].map(i => { return i.id === item.id ? item : i});
      
      ConversationsModel.updateItems(items, true);
    }

    static ignoreEscalation(_item:ConversationItem) {
      
      ConversationsModel.removeFromAssignmentHistoryData(_item)

      const item = {..._item};
      if (!item.escalation) { return } 
      
      NotificationsModel.clearEscalationNotification(item.escalation.id)

      delete item.escalation.agent;
      item.escalation.status = EscalationStatus.PENDING; 
    
      ConversationsModel.frozenItems[item.id] = {item, freezeUntil: new Date(new Date().getTime() + FREEZE_TIME)}

      const items = [...ConversationsModel.state.items].map(i => { return i.id === item.id ? item : i});
      
      ConversationsModel.updateItems(items, true);
  

      Parse.Cloud.run("rejectEscalation", {escalationId:item.escalation.id,agentId:AgentModel.state.agent.id}).then((response) => {
      }).catch(e => {
        
      })
    }

    
    static acceptEscalation(_item:ConversationItem) {
      const item = {..._item};
      ConversationsModel.removeFromAssignmentHistoryData(_item)

      if (!item.escalation) { return } 
      item.escalation.status = EscalationStatus.ACTIVE; 
      item.takenOver = true;
      NotificationsModel.clearEscalationNotification(item.escalation.id)

      ConversationsModel.frozenItems[item.id] = {item, freezeUntil: new Date(new Date().getTime() + FREEZE_TIME)}


      const items = ConversationsModel.state.items.map(i => i.id === item.id ? item : i);
      
      
      ConversationsModel.updateItems(items);
      setTimeout(() => {
        window.dispatchEvent(new CustomEvent("showGreeting"));                                                   
    }, 100);

      return Parse.Cloud.run("acceptEscalation", {escalationId:item.escalation.id, agentId:AgentModel.state.agent.id}).then((response) => {
        
        //  if  (response.escalation) {
        //   item.escalation = response.escalation;

        //  }
        
       
        // let newEscalation = (response.escalation) ? response.escalation : item.escalation;

        // 
        // if (response.error) {
        //  console. warn("acceptEscalation - got error", response)
        //   newEscalation.error = response;
        //   EscalationsModel.updateEscalation(newEscalation)
        // } else {
        //   EscalationsModel.updateEscalation(response)
        // }
        // return newEscalation; 
      }).catch(e => {
        
        // log("acceptEscalation - catch", e.message);
        // let newEscalation = (e.escalation) ? e.escalation : escalation;
        // newEscalation.error = e;
        // EscalationsModel.updateEscalation(newEscalation)

      })

    }


    static setArchived(botUserId:string, archived:boolean) {
      

      if (!botUserId || !ConversationsModel.state.items || !ConversationsModel.state.items.length) { return; }
      let _item = ConversationsModel.state.items.find(i => i.id === botUserId);
      let items =  [...ConversationsModel.state.items]
      if (!_item ) {
        

        if (!archived) {
          

        _item = {
          id: botUserId,
          archived: false,
          lastInteraction: new Date(),
        }
        items.push(_item)
        } else {
          

          return 
        }
       } 
       
       _item.archived = archived;
       ConversationsModel.removeFromAssignmentHistoryData(_item)


      const item = {..._item};
      ConversationsModel.frozenItems[item.id] = {item, freezeUntil: new Date(new Date().getTime() + FREEZE_TIME)}
       items = items.map(i => { return i.id === item.id ? item : i});
       

      ConversationsModel.updateItems(items,  true);
    }



    static async dismissNotification(item:ConversationItem) {
      ConversationsModel.removeFromAssignmentHistoryData(item)
      ConversationsModel.updateItems(ConversationsModel.state.items, true);
    }




    static hasUnresolvedEscalations():boolean {
      const agent = AgentModel.state.agent; 
      if (!agent) { return false; }
      return ConversationsModel.state.hasEscalations;

      // if (!items || !items.length) { return false; }
      // const escalations = items.filter((item) => { return item.escalation && item.escalation.status === EscalationStatus.ASSIGNED && !item.archived; }).map((item) => { return item.escalation; });
      // if (agent && escalations) {
      //   const hasUnresolvedEscalations = escalations.filter((escalation) => {
      //     return  escalation.agent?.id === agent.id && escalation.status === EscalationStatus.ASSIGNED;
      //   })
      //   // 
        
      //   return hasUnresolvedEscalations.length > 0;
      // }
      // return false; 
    }

    static setHasStartedShift() { // This is mainly to get an interaction in the begining. 
      ConversationsModel.setState({
        hasStartedShift:true
      });
    }
    
  }