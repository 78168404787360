import { ConversationItem } from "@swivl/swivl-lib";
import { Config } from "../../Config/Config";
import { warn } from "../../Helpers/Logging/Logging";
import { AgentModel } from "../Agent/Agent.Model";
import BotModel from "../Bots/Bot.Model";
import { BotUserModel } from "../BotUser/BotUser.Model";
import BrainsResultsModel from "../Brains/BrainResults.Model";
import { ConversationsModel } from "../Conversations/Conversations.Model";
import EscalationsModel from "../Escalations/Escalations.Model";
import { NotificationsModel } from "./Notifications.Model";

const log = require("debug")("SwivlNotifications")



export default class SwivlNotifications {
  static socket:WebSocket; 
  static isConnecting = true; 

  static reset() {
    if (SwivlNotifications.socket) {
      SwivlNotifications.socket.close()
    }
    SwivlNotifications.socket = null;

  }
  static connect() {
    log("SNM connect");
    SwivlNotifications.reset()
    if (SwivlNotifications.socket) {
      log("SNM connect - already connected");
      // SwivlNotifications.register();
      return;
    }
    try {
      if (!SwivlNotifications.socket) {
        SwivlNotifications.socket = new WebSocket("wss://" + Config.NOTIFICATIONS_HOSTNAME, "websocket");
      }
    } catch(error) {
        warn('Error: Unable to connect.');
    }

    if (SwivlNotifications.socket) {
      SwivlNotifications.socket.onopen    = (e) => { SwivlNotifications.socketOnOpen(e); }
      SwivlNotifications.socket.onmessage = (e) => { SwivlNotifications.socketOnMessage(e); }
      SwivlNotifications.socket.onerror   = (e) => { SwivlNotifications.socketOnError(e); }
      SwivlNotifications.socket.onclose   = (e) => { SwivlNotifications.socketOnClose(e); }
    }
    SwivlNotifications.isConnecting = false;
  }

  static socketOnOpen(event){
    log("SNM socketOnOpen");

    SwivlNotifications.register();
  }

  static socketOnMessage(event){
    // log("SNM socketOnMessage", event);
    if (!event || !event.data) { warn("SNM No data");  return;  }
    const data  = JSON.parse(event.data);
    

    if (!data.type || !data.botId) { warn("SNM Missing Params");  return; }

  

    if (!BotModel.state.bot || data.botId !== BotModel.state.bot.id) { warn("SNM Wrong Bot", data.botId ); SwivlNotifications.register();  return; }

    if (data.type === "new_brain_result" && data.objectId) {
      log("NEW BRAIN RE?SULT GOING TO FETCH");
      BrainsResultsModel.fetchUpdatedItem(data.objectId);
    }
    if (data.type === "bot_user_updated") {
      log("bot_user_updated", data);
      BotUserModel.fetchUpdatedItem(data.id || data.objectId, data.isTakenOver)
    }

    if (data.type === "escalation_updated" && data.escalation) {
      log("Updating Escalation from Alert!");
        
      EscalationsModel.updateEscalation(data.escalation)
    }
    if (data.type === "escalation_notification" && data.escalation && data.agentId) {
      log("Escalation Notification", data);  
      EscalationsModel.updateEscalation(data.escalation)
      if (AgentModel.state.agent && AgentModel.state.agent.id === data.agentId) {
        log("Escalation Notification for me", data.escalation);
        NotificationsModel.receivedEscalationNotification(data.escalation)
      }
    }

    // if (data.type === "active_bot_users") {
    //   BotUserModel.refreshActive(data.botUsers)
    //   // SwivlNotifications.app.botUsersModel.fetchUpdatedItem(data.objectId)
    // }
    if (data.type === "chat_notification") {
      NotificationsModel.receivedNotification(data);
    }

    if (data.type === "conversation_items") {

      
      
      ConversationsModel.updateItems(data.items)
      AgentModel.agentsOnlineUpdated(data.agents)


      BotUserModel.refreshActive((data.items as ConversationItem[]).filter((item) => item.active).map((item) => item.id))

    }
  

  }

  static socketOnError(event) {
    log("SNM socketOnError",event);
    SwivlNotifications.reconnect()
  }

  static socketOnClose(event) {
    log("SNM socketOnClose",event);
    SwivlNotifications.reconnect()

  }
  
  static reconnect() {
    
    if (SwivlNotifications.isConnecting) { return; }
    SwivlNotifications.isConnecting = true;
    setTimeout(() => {
      SwivlNotifications.connect();
    }, 5000)
  }



  static register() {
    const bot = BotModel.state.bot; 
    if  (!SwivlNotifications.socket || SwivlNotifications.socket.readyState !== 1) { warn("SNM Socket not yet ready"); return; }
    log("SNM Registering Swivl Notifications Websocket", SwivlNotifications.socket);
    if (!bot) { warn("SNM No Bot"); return; }
    const frame = {"botId":bot.id, "environment":Config.ENVIRONMENT, "type":"register", "channels": ["Escalations", "Conversations"]};
    
    SwivlNotifications.socket.send(JSON.stringify(frame))
  }

  static sendAgentAvailability(botId, agentId, available) {
    const avail = (available) ? "true" : "false"
    const url = `https://${Config.NOTIFICATIONS_HOSTNAME}/active/${botId}/agent/${agentId}/${avail}`
    fetch(url, {method:'post'}).then((r)=> { 
    }).catch((e) => { warn("Error sending agent notification", e)})
  } 

}
