import * as React from 'react';
import { Spinner } from '../../Spinner/Spinner';
// import * as libphonenumber
// const libphonenumber = require('libphonenumber-js')
import parsePhoneNumber from 'libphonenumber-js'

import "./SaveInput.scss";
interface SaveInputProps {
  name:string
  placeholder:string 
  save?(name:string, value:string, saveComplete:()=>void);
  onBlur?();
  onFocus?();
  value?:string;
  title?:string; 
  rightTitle?:string; 
  inputClassName?:string;
  parsePhoneNumber?:boolean;
  readOnly?:boolean;
}
interface SaveInputState {
  value:string 
  isLoading:boolean
  isFocused:boolean 
  isEditing:boolean 
}

export class SaveInput extends React.Component<SaveInputProps, SaveInputState> {
    isUnmounted:boolean = false; 
  constructor(props) {
    super(props)
    this.state = {value:"", isLoading:false, isFocused:false, isEditing:false}

    this.onFocus = this.onFocus.bind(this)
    this.onBlur = this.onBlur.bind(this)
    this.onKeyDown = this.onKeyDown.bind(this)
    this.onChange = this.onChange.bind(this)
    this.buttonClicked = this.buttonClicked.bind(this)
    this.enterEditMode = this.enterEditMode.bind(this)
    this.exitEditMode  = this.exitEditMode.bind(this)
    this.save          = this.save.bind(this)
    this.saveComplete  = this.saveComplete.bind(this)
  }
  componentWillUnmount() {
    this.isUnmounted = true;
  }
  onFocus() { 
    if (this.props.readOnly) { return; }
    this.enterEditMode(); if (this.props.onFocus) { this.props.onFocus(); }}
  onBlur() { 
    if (this.props.readOnly) { return; }

    if (this.props.onBlur ) { this.props.onBlur();  }}
  onKeyDown(e){  
    if (this.props.readOnly) { return; }

    if(e.keyCode == 13 ){ this.buttonClicked() } }
  onChange(e) {
    if (this.props.readOnly) { return; }
    this.setState({value: e.target.value});
  }
  buttonClicked() {
    if (this.props.readOnly) { return; }
    if (!this.state.isEditing) {
      this.enterEditMode()
    } else {
      this.save()
    }
  }
  enterEditMode() {
    this.setState({isEditing:true, value:(this.props.value)? this.props.value : ""})
  }
  exitEditMode() {
    this.setState({isEditing:false, value:""})
  }
  save() {
    if (this.props.readOnly) { return; }

    let value = this.state.value; 
    if (value && this.props.parsePhoneNumber && value.length) {
      const parsedPhoneNumber = parsePhoneNumber(value, "US");
      if (!parsedPhoneNumber || !parsedPhoneNumber.number || !parsedPhoneNumber.isValid()) {
        alert("Please enter a valid phone number.");
        return;
      } else {
        value = parsedPhoneNumber.number; 
      }
    }

    this.setState({isLoading:true})

    if (this.props.save) {
      this.props.save(this.props.name, value, this.saveComplete)
    }
  }
  saveComplete() {
    if (this.isUnmounted) { return; }
    this.setState({isLoading:false, isEditing:false})
  }
  render() {
    const {readOnly} = this.props;
    let className = (this.state.isEditing) ? "SaveInput editing": "SaveInput";
    if (this.props.inputClassName) { className +=  " " + this.props.inputClassName; }

    if (this.state.isLoading) { className += " loading"   }
    if (this.state.value.length > 0) { className += " hasText";  }


    let saveButton;
    const buttonTitle = (this.state.isEditing) ? "Save" : "Edit"
    // if (this.props.buttonName) {
      saveButton = <div className="saveButton" onClick={this.buttonClicked}>
                <span className="title">{buttonTitle}</span>
                <span className="spinner"><Spinner /></span>
              </div>;
    // }

    var title;
    if (this.props.title) {
      title = <div className="leftTitle">{this.props.title}</div>
    }

    const propValue = (this.props.value) ? String(this.props.value) : "";
    const value = (this.state.isEditing) ? this.state.value : propValue;

    const rightTitle = (this.props.rightTitle) ? <span  className="rightTitle">{this.props.rightTitle}</span> : null;

    return <div className={className} >
            {title}{rightTitle}
            <div className="inputBlock">
              <div className="inputWrapper">
                <input
                  readOnly={readOnly}
                  onFocus={ this.onFocus }
                  onBlur={ this.onBlur }
                  name={this.props.name}
                  onChange={this.onChange}
                  value={value}
                  onKeyDown={this.onKeyDown}
                  placeholder={this.props.placeholder} />
                {!readOnly && <div className="cancelButton" onClick={this.exitEditMode}>Cancel</div>}
                {!readOnly && saveButton}
              </div>
            </div>
          </div>;
   }
};
