import React, { Suspense, lazy, useEffect } from "react";
import {
  Navigate,
  Route,
  Routes,
  useParams,
} from "react-router-dom";

import Permissions from "../../Models/Parse/Permissions";
import { PageLoadingView } from "../Layout/Loading/LoadingView";
import SelectBotView from "../../Views/Login/SelectBotView/SelectBotView";

import { NavBarLayout } from "../Layout/NavBar/NavBarView";
import SettingsView from "../../Views/Settings/SettingsView";
import { useSwivlNavigate } from "../../Models/NavBar/useSwivlNavigate";
import UserModel from "../../Models/User/UserModel";
import { NavigationHelper } from "../../Helpers/Navigation/Navigation.Helper";
import SlackAgentSettingsView from "../../Views/Conversations/Agent/SlackAgentSettingsView";
import { DateTime } from "luxon";
import ModalModel from "../../Models/Modal/Modal.Model";
import { RefreshModal } from "../Layout/RefreshModal/RefreshModal";
import TranscriptView from "../../Views/Transcript/TranscriptView";


const Dashboard = lazy(() => import("../../Views/Dashboard/DashboardView"));
const AnalyticsTables = lazy(() => import("../../Views/Dashboard/Analytics/AnalyticsTables/AnalyticsTables"));

const ConversationsView = lazy(() => import("../../Views/Conversations/ConversationsView"));
const AgentSettingsView = lazy(() => import("../../Views/Conversations/Agent/AgentSettingsView"));



const UserJourneysView = lazy(() => import("../../Views/Workflows/UserJourneysView"));
const EditorView = lazy(() => import("../../Views/Quests/EditorWrapper"));
const BrainView = lazy(() => import("../../Views/Brains/BrainsView"));

const WorksheetsView = lazy(() => import("../../Views/Worksheets/WorksheetsView"));

const TrainingView = lazy(() => import("../../Views/Training/TrainingView"));

const ReviewsView = lazy(() => import("../../Views/Reviews/ReviewsManager.View"));

const AccountView = lazy(() => import("../../Views/Settings/Views/Account/AccountView"));
const BotSettingsView = lazy(() => import("../../Views/Settings/Views/Bot/BotSettingsView"));
const UserVariablesView = lazy(() => import("../../Views/Settings/Views/Variables/UserVariablesView"));
const EmbedSettingsView = lazy(() => import("../../Views/Settings/Views/Embed/EmbedSettingsView"));
const EmbedDetailsView = lazy(() => import("../../Views/Settings/Views/Embed/EmbedDetails/EmbedDetails"));
const BrainsSettingsView = lazy(() => import("../../Views/Settings/Views/Brains/BrainsSettingView"));
const UsersAndPermissionsView = lazy(() => import("../../Views/Settings/Views/Users/UsersAndPermissionsView"));
const EscalationsView = lazy(() => import("../../Views/Settings/Views/Escalations/EscalationsView"));
const FMSView = lazy(() => import("../../Views/Settings/Views/FMS/FMSView"));
const FMSDetailsView = lazy(() => import("../../Views/Settings/Views/FMS/Details/FMSDetailsView"));
const EscalationHistoryView = lazy(() => import("../../Views/Settings/Views/EscalationHistory/EscalationHistoryView"));


const AppsView = lazy(() => import("../../Views/Settings/Views/Apps/AppsView"));
const AIAssistSettings = lazy(() => import("../../Views/Settings/Views/AIAssistSettings/AIAssistSettings"));
const StoreFinderView = lazy(() => import("../../Views/Settings/Views/StoreFinder/StoreFinderView"));
const SMSGroupSettingsViewView = lazy(() => import("../../Views/Settings/Views/SMSGroup/SMSGroupSettingsView"));
const SMSGroupDetailsView = lazy(() => import("../../Views/Settings/Views/SMSGroup/Details/SMSGroupDetailsView"));

const AdminBotView = lazy(() => import("../../Views/Admin/AdminBot/AdminBotView"));
const AdminTransfersView = lazy(() => import("../../Views/Admin/AdminTransfersView/AdminTransfersView"));
const AdminFmsTesterView = lazy(() => import("../../Views/Admin/AdminFmsTesterView/AdminFmsTesterView"));

const InvitesView = lazy(() => import("../../Views/Invites/InvitesView"));

const InteractionsView = lazy(() => import("../../Views/Interactions/Interactions.View"));


const CRMView = lazy(() => import("../../Views/CRM/CRMView"));
export function SpecialNavigate(props: { addressWithKey: string, paramKey: string, fallback: string }) {
  const params = useParams()

  if (params[props.paramKey] && props.addressWithKey.search("_KEY_")) {
    return <Navigate to={"/" + props.addressWithKey.replace("_KEY_", params[props.paramKey])} replace />;
  }
  return <Navigate to={"/" + props.fallback} replace />;
}

interface Props {
  user: Parse.User;
  permissions: Permissions;
}
export default function Router(props: Props) {

  const navigate = useSwivlNavigate()




  useEffect(() => {

    localStorage.setItem('lastDailyReboot', DateTime.now().toFormat('yyyy-MM-dd'))

    setInterval(() => {
      const now = DateTime.now()
      const hour = now.get('hour')
      const today = now.toFormat('yyyy-MM-dd')
      if (hour > 3 && today !== localStorage.getItem('lastDailyReboot')) {
        localStorage.setItem('lastDailyReboot', today)
        ModalModel.showModalWithContent(<RefreshModal />, 544, false)
      }
    }, 1000 * 15);
  }, [])


  useEffect(() => {
    NavigationHelper.navFunction = navigate;
  }, [navigate]);

  if (!props.permissions) { return <SelectBotView /> }
  return <Routes>

    <Route path="invite/:inviteId" element={<Suspense fallback={<PageLoadingView navBarTitle="Invite" pageTitle="Invite" />}><InvitesView /></Suspense>} />


    <Route path="/" element={<NavBarLayout />}>
      <Route path="slack/agent/bot/:botId/slack-user/:slackUserId" element={<Suspense fallback={<PageLoadingView />}><SlackAgentSettingsView /></Suspense>} />

      <Route index element={<Suspense fallback={<PageLoadingView />}><Dashboard /></Suspense>} />



      <Route path="conversations" element={<Suspense fallback={<PageLoadingView />}><ConversationsView /></Suspense>} />
      <Route path="conversations/user/:botUserId" element={<SpecialNavigate addressWithKey="conversations/_KEY_" paramKey="botUserId" fallback="conversations" />} />
      <Route path="conversations/escalations/:escalationId" element={<SpecialNavigate addressWithKey="conversations/_KEY_" paramKey="botUserId" fallback="conversations" />} />
      <Route path="conversations/:botUserId" element={<Suspense fallback={<PageLoadingView />}><ConversationsView /></Suspense>} />


      <Route path="crm" element={<Suspense fallback={<PageLoadingView />}><CRMView /></Suspense>} />



      <Route path="agent/settings" element={<Suspense fallback={<PageLoadingView />}><AgentSettingsView /></Suspense>} />
      <Route path="workflows" element={<Suspense fallback={<PageLoadingView navBarTitle="User Journeys" pageTitle="User Journeys" />}><UserJourneysView /></Suspense>} />
      <Route path="quest/:questSlug" element={<Suspense fallback={<PageLoadingView navBarTitle=" " pageTitle=" " />}><EditorView /></Suspense>} />
      <Route path="matrix/:questSlug" element={<Suspense fallback={<PageLoadingView navBarTitle=" " pageTitle=" " />}><EditorView /></Suspense>} />
      <Route path="brain/:brainSlug" element={<Suspense fallback={<PageLoadingView navBarTitle=" " pageTitle=" " />}><BrainView /></Suspense>} />

      <Route path="analytics/:type/:startDay/:endDay" element={<Suspense fallback={<PageLoadingView />}><AnalyticsTables /></Suspense>} />

      <Route path="settings/embed/:embedCodeId" element={<Suspense fallback={<PageLoadingView navBarTitle="Edit Embed Code" pageTitle="Edit Embed Code" />}><EmbedDetailsView /></Suspense>} />
      <Route path="settings/apps/:appType" element={<Suspense fallback={<PageLoadingView />}><AppsView /></Suspense>} />

      <Route path="interactions-old/results" element={<Suspense fallback={<PageLoadingView />}><TrainingView /></Suspense>} />


      <Route path="interactions" element={<Suspense fallback={<PageLoadingView />}><InteractionsView /></Suspense>} />
      <Route path="interactions/:resultId" element={<Suspense fallback={<PageLoadingView />}><InteractionsView /></Suspense>} />





      <Route path="my-storage" element={<Suspense fallback={<PageLoadingView />}><WorksheetsView /></Suspense>} />
      <Route path="my-storage/:worksheet" element={<Suspense fallback={<PageLoadingView />}><WorksheetsView /></Suspense>} />

      <Route path="reviews" element={<Suspense fallback={<PageLoadingView navBarTitle="Review Manager" pageTitle="Review Manager" />}><ReviewsView /></Suspense>} />


      <Route path="settings/store-finder" element={<Suspense fallback={<PageLoadingView navBarTitle="Store Finder" pageTitle="Store Finder" />}><StoreFinderView /></Suspense>} />

      <Route path="settings/fms/:id" element={<Suspense fallback={<PageLoadingView navBarTitle="FMS" pageTitle="FMS" />}><FMSDetailsView /></Suspense>} />

      <Route path="settings/escalations/history/*" element={<Suspense fallback={<PageLoadingView navBarTitle="Escalation History" pageTitle="Escalation History" />}><EscalationHistoryView /></Suspense>} />


      <Route path="settings/*" element={<SettingsView />}>
        <Route path="apps" element={<Suspense fallback={<PageLoadingView navBarTitle="Apps" pageTitle="Apps" />}><AppsView /></Suspense>} />
        <Route path="account" element={<Suspense fallback={<PageLoadingView navBarTitle="My Account" pageTitle="Account" />}><AccountView /></Suspense>} />
        <Route path="bot" element={<Suspense fallback={<PageLoadingView navBarTitle="Bot Settings" pageTitle="Bot Settings" />}><BotSettingsView /></Suspense>} />
        <Route path="variables" element={<Suspense fallback={<PageLoadingView navBarTitle="Variables" pageTitle="Variables" />}><UserVariablesView /></Suspense>} />
        <Route path="embed" element={<Suspense fallback={<PageLoadingView navBarTitle="Embed Codes" pageTitle="Embed Codes" />}><EmbedSettingsView /></Suspense>} />
        <Route path="users" element={<Suspense fallback={<PageLoadingView navBarTitle="Users & Permissions" pageTitle="Users & Permissions" />}><UsersAndPermissionsView /></Suspense>} />
        <Route path="escalations" element={<Suspense fallback={<PageLoadingView navBarTitle="Escalations" pageTitle="Escalations" />}><EscalationsView /></Suspense>} />

        <Route path="brains" element={<Suspense fallback={<PageLoadingView navBarTitle="Brain Settings" pageTitle="Brain Settings" />}><BrainsSettingsView /></Suspense>} />



        <Route path="fms" element={<Suspense fallback={<PageLoadingView navBarTitle="FMS Integrations" pageTitle="FMS Integrations" />}><FMSView /></Suspense>} />
        <Route path="sms-groups" element={<Suspense fallback={<PageLoadingView navBarTitle="SMS Groups" pageTitle="SMS Groups" />}><SMSGroupSettingsViewView /></Suspense>} />
        <Route path="sms-groups/:type/:slug" element={<Suspense fallback={<PageLoadingView navBarTitle="SMS Group Settings" pageTitle="SMS Group Settings" />}><SMSGroupDetailsView /></Suspense>} />
        <Route path="sms-groups/*" element={<Suspense fallback={<PageLoadingView navBarTitle="SMS Groups" pageTitle="SMS Groups" />}><SMSGroupSettingsViewView /></Suspense>} />
        <Route path="ai-assist" element={<Suspense fallback={<PageLoadingView navBarTitle="AI Assist Settings" pageTitle="AI Assist Settings" />}><AIAssistSettings /></Suspense>} />

        <Route path="*" element={<Navigate to="/settings/account" replace />} />
      </Route>

      <Route path="transcript/:botUserId" element={<Suspense fallback={<PageLoadingView navBarTitle="TranscriptView" pageTitle="Transcript" />}><TranscriptView /></Suspense>} />


      {UserModel.isSuperAdmin && <>
        <Route path="interactions/*" element={<Suspense fallback={<PageLoadingView />}><TrainingView /></Suspense>} />

        <Route path="admin" element={<Suspense fallback={<PageLoadingView navBarTitle="Admin" pageTitle="Admin" />}><AdminBotView /></Suspense>} />
        <Route path="admin/transfers" element={<Suspense fallback={<PageLoadingView navBarTitle="Admin Transfers" pageTitle="Admin Transfers" />}><AdminTransfersView /></Suspense>} />
        <Route path="admin/fms-tester" element={<Suspense fallback={<PageLoadingView navBarTitle="FMS Tester" pageTitle="FMS Tester" />}><AdminFmsTesterView /></Suspense>} />
        <Route path="admin/*" element={<Suspense fallback={<PageLoadingView navBarTitle="Admin" pageTitle="Admin" />}><AdminBotView /></Suspense>} />
      </>}

      <Route path="*" element={<Suspense fallback={<PageLoadingView />}><Dashboard /></Suspense>} />

    </Route>
  </Routes>
}

