import React, { useEffect } from 'react';
import { NavLink, Outlet } from 'react-router-dom';
import { NavBar } from '../../Models/NavBar/NavBar.Model';
import UserModel from '../../Models/User/UserModel';
import { BotSelectView } from './Components/BotSelect/BotSelect';
import {PageLoadingView} from '../../App/Layout/Loading/LoadingView';
import SubscriptionModel from '../../Models/Subscription/Subscription.Model';
import BotModel from '../../Models/Bots/Bot.Model';

export default function SettingsView () {
  const permissions = UserModel.useState(s => s.permissions); 
  
  const bot = BotModel.useState(s => s.bot);
  // useEffect(() => {

  //   NavBar.set(   
  //     <BotSelectView />, 
  //     <NavBar.Component.Title title="Settings"/>, 
  //     <NavBar.Component.Button title="Logout" onClick={() => {
  //       UserModel.logout()
  //     }}/>,
      
  //     "Settings"
  //     );
    
  // }, [])

    if (!permissions) {
      return <PageLoadingView navBarTitle="Settings" pageTitle="Settings" />
    }

    return <div className="SettingsView PageWithSidebarMenu">
            <div className="sideBarMenu">
            <NavLink to="/settings/account" className={"menuItem"}>My Account</NavLink>
            {permissions.bot_settings && <NavLink to="/settings/bot" className={"menuItem"}>Bot Settings</NavLink> }
            
            {permissions.user_journeys && <NavLink to="/workflows" className={"menuItem"}>Custom Workflows</NavLink> }
            {permissions.user_journeys && <NavLink to="/settings/brains" className={"menuItem"}>Brains</NavLink> }
     
           
            {permissions.variables && <NavLink to="/settings/variables" className={"menuItem"}>Variables</NavLink> }
            {permissions.embed_builder && <NavLink to="/settings/embed" className={"menuItem"}>Embed Codes</NavLink> }
            {permissions.users_and_permissions && SubscriptionModel.canSeePermissions() && <NavLink to="/settings/users" className={"menuItem"}>Users & Permissions</NavLink>}
            {permissions.users_and_permissions && bot.get("hasEscalations") && <NavLink to="/settings/escalations" className={"menuItem"}>Escalations</NavLink> } 
            {(UserModel.isSuperAdmin || permissions.escalation_history) && <NavLink to="/settings/escalations/history" className={"menuItem"}>Escalation History</NavLink> } 
            {(UserModel.isSuperAdmin || bot.get("apps")?.includes("twilio")) && <NavLink to="/settings/sms-groups" className={"menuItem"}>SMS Groups</NavLink> } 
            <NavLink to="/settings/apps" className={"menuItem"}>Apps</NavLink>
            <NavLink to="/settings/fms" className={"menuItem"}>FMS Integrations</NavLink>





            {bot.get("apps")?.includes("storeFinder") && <NavLink to="/settings/store-finder" className={"menuItem"}>Store Finder</NavLink> }
            <NavLink to="/crm" className={"menuItem"}>CRM</NavLink>
            {(UserModel.isSuperAdmin) && <NavLink to="/settings/ai-assist" className={"menuItem"}>AI Assist</NavLink> } 

            </div>
            <div className={"pageWithSidebarMenuContent"}>
              <Outlet />
            </div>
  </div>
  
}
