import { JourneyInterface, JourneyType, MatrixInterface, NodeMap, InheritanceStatus, QuestInterface, QuestType, Slug } from "@swivl/swivl-lib";
import { nanoid } from 'nanoid'
import BotModel from "../../Bots/Bot.Model";
import { QuestsModel } from "../Quests.Model";


export class QuestModelHelper {
    static newQuest(name:string, type: QuestType, jouneySlug: Slug, inheritanceStatus?: InheritanceStatus, order?: number, ): Partial<QuestInterface> {
        const data = type == QuestType.MATRIX ? {
            "xVariables": [""],
            "yVariables": [""],
            "defaultUnhandled": QuestsModel.state.defaultUnhandled?.slug,
            "data": [[{ "type": "spacer", "right": "NOT_SET", "bottom": "NOT_SET" }, { "type": "value", "variable": "NOT_SET", "axis": "x", "values": ["SPECIAL_NOT_SET"] }], [{ "type": "value", "variable": "NOT_SET", "axis": "y", "values": ["SPECIAL_NOT_SET"] }, { "type": "quest", "quest": "" }]]
        } as MatrixInterface
            :
            {
                "start": {
                    "id": "start",
                    "type": "start",
                    "delay": 0,
                    "showTyping": false,
                    "name": "",
                    "onCompleteGoto": undefined,
                    "left": 0,
                    "top": 0
                }
            } as NodeMap;

        let quest: Partial<QuestInterface> = {
            slug: nanoid(10),
            name: name, 
            type: type,
            journeySlug: jouneySlug,
            inheritance: inheritanceStatus || InheritanceStatus.NONE,
            order: order,
            botId: BotModel.state.bot.id,
        }
        quest.data = data;
        return quest
    }


    static newJourney(name:string, type?:JourneyType): Partial<JourneyInterface> {
        const journey: Partial<JourneyInterface> = {
            botId: BotModel.state.bot.id,
            name: name,
            inheritance: InheritanceStatus.NONE,
            slug: nanoid(10),
            type: type ? type : JourneyType.NORMAL,
            order: 0
        }
        return journey; 

    }
}