import Modal from 'react-modal';
import ModalModel from '../../../Models/Modal/Modal.Model';

const defaultStyle = {
    content : {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        borderRadius: '8px',
        boxShadow: '0px 2px 18px rgba(0,0,0, 0.2)'
    },
    overlay: {
      backgroundColor:'rgba(94, 84, 80, 0.2)',
      zIndex: 10000,
      backdropFilter: 'blur(2px)'
    },
  };

export const SSModal = () => { // We are starting our transition to hooks and the pullstate approach to everything. 
    const {modalContent, showModal,modalWidth, shouldCloseOnOverlayClick} = ModalModel.useState();

      const closeModal = () => {
        ModalModel.hideModal()
      }
      let style:any = {...defaultStyle}
      if (modalWidth) {
        // style.left = "auto"
        // style.right = "auto"
        style.width = modalWidth + "px"
      } 

    return (
        <Modal
            isOpen={showModal}
            onRequestClose={closeModal}
            style={style}
            contentLabel=""
            shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
          >
          {modalContent}
          </Modal>
      );
   }