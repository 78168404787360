import type { DraggableLocation } from '@hello-pangea/dnd';
import { WorkflowsJourney } from '../../Models/Quests/Quests.Model';
import { QuestInterface } from '@swivl/swivl-lib';

// import type { Quote, QuoteMap } from './types';
// interface ReorderQuoteMapArgs {
//   quoteMap: QuoteMap;
//   source: DraggableLocation;
//   destination: DraggableLocation;
// }

interface List<T> {
  id: string;
  values: T[];
}

interface MoveBetweenArgs<T> {
  list1: List<T>;
  list2: List<T>;
  source: DraggableLocation;
  destination: DraggableLocation;
}

interface MoveBetweenResult<T> {
  list1: List<T>;
  list2: List<T>;
}

export class ReorderHelper {

// a little static to help us with reordering the result
static reorder<TItem>(
  list: TItem[],
  startIndex: number,
  endIndex: number,
): TItem[] {
  const result = [...list];
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
}






static reorderQuestInJourneys (
  workflows: WorkflowsJourney[],
  source:DraggableLocation,
  destination:DraggableLocation,
):{workflows:WorkflowsJourney[], updated:WorkflowsJourney[]} { // returns all the workflows with the quest moved
  

  const current: WorkflowsJourney = workflows[source.droppableId];
  const next: WorkflowsJourney = workflows[destination.droppableId];
  

  const target: QuestInterface = current.quests[source.index];

  // moving to same list
  if (source.droppableId === destination.droppableId) {
    current.quests = ReorderHelper.reorder(
      current.quests,
      source.index,
      destination.index,
    );
    
    workflows[source.droppableId] = current;
    return  {workflows:workflows, updated:[current]};

    // const result: QuoteMap = {
    //   ...quoteMap,
    //   [source.droppableId]: reordered,
    // };
    // return {
    //   quoteMap: result,
    // };
  }
  

  // moving to different list

  // remove from original
  

  current.quests[source.index].journeySlug = next.journey.slug;

  current.quests.splice(source.index, 1);
  // insert into next
  next.quests.splice(destination.index, 0, target);
  workflows[source.droppableId] = current;
  workflows[destination.droppableId] = next;

  return  {workflows:workflows, updated:[current, next]};
  // const result: QuoteMap = {
  //   ...quoteMap,
  //   [source.droppableId]: current,
  //   [destination.droppableId]: next,
  // };

  // return {
  //   quoteMap: result,
  // };
};


static moveBetween<T>({
  list1,
  list2,
  source,
  destination,
}: MoveBetweenArgs<T>): MoveBetweenResult<T> {
  const newFirst = [...list1.values];
  const newSecond = [...list2.values];

  const moveFrom = source.droppableId === list1.id ? newFirst : newSecond;
  const moveTo = moveFrom === newFirst ? newSecond : newFirst;

  const [moved] = moveFrom.splice(source.index, 1);
  moveTo.splice(destination.index, 0, moved);

  return {
    list1: {
      ...list1,
      values: newFirst,
    },
    list2: {
      ...list2,
      values: newSecond,
    },
  };
}


}