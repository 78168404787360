import { useEffect, useState } from "react";
import BotUser from "../../../Models/Parse/BotUser";
import { DateTime } from "luxon";
import { Spinner } from "../../../Components/Spinner/Spinner";

interface Props {
    botUser?: BotUser
}

export default function RenewButton(props: Props) {
    const {botUser} = props;
    const [_botUser, setBotUser] = useState<BotUser | undefined>(botUser);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const renewLink = async () => {
        setIsLoading(true);
        const bu = await _botUser?.renewTranscriptAccess();
        setBotUser(bu);
        setIsLoading(false);
    }
    useEffect(() => {
        setBotUser(botUser);
    }, [botUser])
    if (!_botUser || isLoading) {
        return <div className="RenewButton"><Spinner /></div>
    }

    const expires = (!_botUser.get("transcriptAccessExpires") || _botUser.get("transcriptAccessExpires") < new Date()) ? undefined : _botUser.get("transcriptAccessExpires"); 


    return <div className="TranscriptRenewButton">
        <div className="expiresText">
        {expires ? <> Link Expires {DateTime.fromJSDate(expires).toRelative()}</> : "Link Expired"}
        </div>
        <div className="renewButton">
            <div onClick={() => renewLink()}>Renew Link?</div>
        </div>
    </div>

    
}