import * as React from 'react';
import "./ImageUploader.scss"
import { FaUpload } from 'react-icons/fa';
import Parse from 'parse';
import { warn } from '../../Helpers/Logging/Logging';
export default class ImageUploader extends React.Component{
    fileUploader; 

  constructor(props) {
    super(props)
    this.state = {isUploading:false};
    this.fileUploader = React.createRef();
    this.onClick = this.onClick.bind(this)
    this.onChangeFile = this.onChangeFile.bind(this)
  }

  onClick(event) {
    this.fileUploader.current.click();
    event.stopPropagation();
  }
  getBase64 = (file, cb) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
        cb(reader.result)
    };
    reader.onerror = function (error) {
        
    };
}
  onChangeFile(event) {
    
    event.stopPropagation();
    event.preventDefault();
    if (!event.target.files) { return; }
    var file = event.target.files[0];
    if (!file) { return; }

    if (this.props.setBase64Data) {
      this.getBase64(file, (base64) => {
          this.props.setBase64Data(base64)

      } )
    }
    if (!file.type ||  (file.type !== "image/png" && file.type !== "image/jpeg")) {
      alert("You  must upload a PNG or JPEG file.");
      return;
    }
    if (this.props.imageChanged) {
      var parseFile = new Parse.File("avatar.png", file);
      this.setState({isUploading:true}); /// if you want to upload latter
      parseFile.save().then((savedFile) => {
        this.props.imageChanged(savedFile, this.props.name);
      }).catch((error) => {
        warn(error)
        alert("Error Uploading Image");
        this.setState({isUploading:false});
      })
    }


}
  render() {
    const className = (this.props.base64) ? "SSImageUpload SSImageUploadHasFile": "SSImageUpload";
    const onClick = (this.props.base64) ? undefined : this.onClick;
    var content;
    let image = (this.props.base64)? this.props.base64 : this.props.imageURL; 
    if (!image && this.props.parseFile) {
      image = this.props.parseFile.url();
    }

    

    if (image) {
      content = <div className="SSImageUploadContent">
      <img src={image} />

      <div className="SSImageUploadIcons">
        <div className="SSImageUploadIcon SSImageUploadIconChange" onClick={this.onClick}>
            <FaUpload />
      </div>

      </div>
    </div>
    } else {
        content = <div className="uploadAnAvatar">{this.props.placeholder || "Upload your Avatar"}</div>
    }
    


    return (<div className={className} onClick={onClick}>
      {content}
      <input type="file" id="file" ref={this.fileUploader} style={{display: "none"}}    onChange={this.onChangeFile} />

    </div>);
   }
};



