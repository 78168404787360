
import { Button } from "../../../Components/Button/Button";
import "./RefreshModal.scss"

export const RefreshModal = (props) => {
    const refreshClicked = () => {
        window.location.reload()
    }

    return <div className="RefreshModal">
        <h1>⏰</h1>
        <h2>Session Expired</h2>
        <h3>Please refresh to continue.</h3>

        <div className="buttons">
            <Button style={Button.Style.Dark} onClick={refreshClicked}>Let's Do This!</Button>
        </div>
    </div>;
}

