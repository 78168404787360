import React, { Component } from 'react';
import {CopyToClipboard} from 'react-copy-to-clipboard';


export default class SSCopyToClipboard extends Component {

  constructor(props, context) {
    super(props, context);
    this.state = {justCopied:false}
    this.willUnmount = false;
    this.onCopy = this.onCopy.bind(this);
  }
  componentWillUnmount() { this.willUnmount = true; }
  onCopy() {
    const timeoutTime = this.props.timeoutTime || 3000;
    setTimeout(() => {
      if  (!this.willUnmount) {
        this.setState({justCopied:false});
      }
    }, timeoutTime);
    if (this.props.onCopy){ this.props.onCopy();}
    this.setState({justCopied:true});
  }

  render(){
    const copiedComponent = this.props.copiedComponent || <span className="SSCopyToClipboard copied">COPIED</span>;
    const children = this.props.children || <span className="SSCopyToClipboard copied">COPY</span>;
    const content  = (this.state.justCopied) ? copiedComponent  : children;
    return<CopyToClipboard text={this.props.copyValue} onCopy={this.onCopy}>{content}</CopyToClipboard>
  }
}
