import {useEffect, useState} from 'react';
import {
  BrowserRouter,
} from "react-router-dom";
import { ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import './App.scss';

import "./Layout/Layout.scss";
import UserModel from '../Models/User/UserModel';
import {LoadingView} from './Layout/Loading/LoadingView';
import RouterLoggedOut from './Routes/RouterLoggedOut';
import Router from './Routes/Router';
import BotModel from '../Models/Bots/Bot.Model';
import { SSModal } from './Layout/Modal/SSModal';
import { DateTime } from 'luxon';
import ModalModel from '../Models/Modal/Modal.Model';



function App() {
  
  const { user, isLoadingUser , permissions} = UserModel.useState(s => s);
  const isLoadingBot = BotModel.useState(s => s.isLoadingBot);


  


  useEffect(()=> { 

    UserModel.loadUser() 
    
  //   setInterval(() => {
  //     NotificationsModel.displayNotification("WOW!")
  //   }, 1000 * 30);
  //   NotificationsModel.displayNotification("WOW!")

   }, [])
  
   if (isLoadingUser || isLoadingBot) {
    return <div className="App">
      <LoadingView />
    </div>;
   }


   


  return <><div className="App">
              <SSModal />

          <BrowserRouter>
          {(isLoadingUser || !user ) ? <RouterLoggedOut /> : <Router user={user} permissions={permissions} /> } 
          </BrowserRouter>
    </div>
    <ToastContainer/>
    </>;

}

export default App;
