import {
  UserVars,
  VariableDefinitionInterface,
  MergedInheritedValues,
  InheritedValuesType,
  InheritedValuesInterface,
  VariablesMap,
  VariableType,
} from "@swivl/swivl-lib";
import { create } from "zustand";

import { AlwaysCustom, BotVariables, RecommendedVariables } from "@swivl/swivl-worksheets-lib";
import NLPModel from "../NLP/NLP.Model";
import Bot from "../Parse/Bot";
import _ from "lodash";
import { warn } from "../../Helpers/Logging/Logging";
import { TagInputTag } from "../../Components/TagInput/TagInput";
import BotModel from "../Bots/Bot.Model";
import Parse from "parse";

const log = require("debug")("VariablesModel");

interface StoreInterface {
  // userVariables?: Variables;
  // displayNameId?: string;
  // userVariablesObject?:Parse.Object

  allVariables?: TagInputTag[];
  variableDefinitions: MergedInheritedValues<VariableDefinitionInterface>;
  allVariablesMap?: { [id: string]: string };
}
const BlankState: StoreInterface = {
  // userVariables:undefined,
  // displayNameId:undefined,
  // userVariablesObject:undefined,
  variableDefinitions: {},

  allVariables: [],
  allVariablesMap: undefined,
};
export default class VariablesModel {
  static botVariables: VariablesMap = {};

  static useState = create<StoreInterface>((set) => ({ ...BlankState }));
  static setState = VariablesModel.useState.setState;
  static get state() {
    return VariablesModel.useState.getState();
  }
  static reset() {
    VariablesModel.setState({ ...BlankState });
  }

  static variables: TagInputTag[] = [];

  static async loadedFromBotOld(bot: Bot) {
    const allVariables = VariablesModel.generateAllVariables(bot, bot.get("userVariables").get("variables"));

    // let allVariablesMap = {}
    // allVariables.forEach((v) => { allVariablesMap[v.id] = v.display })

    // VariablesModel.setState( {
    //   userVariables : bot.get("userVariables").get("variables"),
    //   displayNameId : bot.get("userVariables").get("displayNameId"),
    //   userVariablesObject : bot.get("userVariables"),
    //   allVariables  :allVariables,
    //   allVariablesMap:allVariablesMap
    // });
    // VariablesModel.load(bot)
  }

  static async loadedFromBot(bot: Bot) {
    if (!bot) {
      return;
    }
    let ownerChain = ["STORAGE_CORE"];
    if (bot.get("parent")?.id) {
      ownerChain.push(bot.get("parent").id);
    }
    ownerChain.push(bot.id);

    Parse.Cloud.run("getInheritedValues", { type: InheritedValuesType.VARIABLE_IDS_AND_NAMES, ownerChain: ownerChain })
      .then((values: MergedInheritedValues<VariableDefinitionInterface>) => {
        const allVariables = VariablesModel.generateAllVariables(bot, values);
        let allVariablesMap = {};
        allVariables.forEach((v) => {
          allVariablesMap[v.id] = v.display;
        });

        VariablesModel.setState({ variableDefinitions: values, allVariables, allVariablesMap });
        // if (!userVariablesObj) { userVariablesObj = new Parse.Object("InheritedValues"); }
        // VariablesModel.loadedFromBot(bot);
      })
      .catch((error) => {
        warn("Error loading variables", error);
      });
  }

  static generateAllVariables(bot: Bot, variables?: MergedInheritedValues<VariableDefinitionInterface>): TagInputTag[] {
    const specialVars = [
      { id: "lastUserInput", display: "User - Last Input" },
      { id: "URLPath", display: "User - Current URL" },
      { id: "URLHostName", display: "User - URL Hostname" },
      { id: UserVars.User_LastAgentName, display: "User - Agent Name" },
    ];

    let entityVars = [];

    if (NLPModel.state.entities) {
      entityVars = NLPModel.state.entities.map((entity) => {
        let name; // = entity.name;
        if (entity.name === "email" || entity.name === "Entity email") {
          name = "User - Email";
        } else if (entity.name === "personName") {
          name = "User - Name";
        } else if (entity.name === "phonenumber") {
          name = "User - Phone Number";
        }

        if (!name) {
          name = "Entity - " + entity.name;
        }
        return { id: "Entity " + entity.name, display: name };
      });
    } else {
      log.warn("Entities not loaded for vars.");
      entityVars = [
        { id: "Entity personName", display: "User - Name" },
        { id: "Entity phonenumber", display: "User - Phone Number" },
        { id: "Entity email", display: "User - Email" },
      ];
    }

    let vars = [];
    for (const [key, value] of Object.entries(variables)) {
      vars.push({ id: key, display: value.value.name });
    }
    //sorts vars by name
    vars.sort((a, b) => {
      if (a.display < b.display) {
        return -1;
      }
      if (a.display > b.display) {
        return 1;
      }
      return 0;
    });

    const botTags: TagInputTag[] =
      bot && bot.get("worksheetSettings")
        ? Object.values(BotVariables).map((v: any) => {
            const name = VariablesModel.nameForBotVariable(v);
            VariablesModel.botVariables[v] = { id: v, name: name, type: VariableType.STRING };
            return { id: v, display: name };
          })
        : [];

    // let userVarTags:TagInputTag[] = variables ? Object.values(userVariables).map((v:VariableDefinition) => {
    //   return {id:v.id, display:v.name}
    // }) : [];

    const allVariables = _.uniqBy([...specialVars, ...entityVars, ...vars, ...botTags], "id");

    return allVariables;
  }

  static saveVariable(variable: VariableDefinitionInterface) {
    return Parse.Cloud.run("saveInheritedValues", {
      type: InheritedValuesType.VARIABLE_IDS_AND_NAMES,
      owner: BotModel.state.bot.id,
      data: { [variable.id]: variable },
    }).then((success: boolean) => {
      VariablesModel.loadedFromBot(BotModel.state.bot);
    });

    // var userVariablesObj = VariablesModel.state.userVariablesObject;
    // var userVariables = userVariablesObj.get("variables");
    // if (!userVariables) { userVariables = {}; }
    // var newVar =  {
    //                 id: shortId(),
    //                 name: newVariableName,
    //                 type:"string",
    //                 hidden:false
    //               };
    // userVariables[newVar.id] = newVar;

    // userVariablesObj.set("variables",userVariables);
    // VariablesModel.setState({ userVariables : userVariables,  userVariablesObject : userVariablesObj });
    // return userVariablesObj.save();
  }

  static deleteVariables(keys: string[]) {
    return Parse.Cloud.run("deleteInheritedValues", {
      type: InheritedValuesType.VARIABLE_IDS_AND_NAMES,
      owner: BotModel.state.bot.id,
      keys: keys,
    }).then((success: boolean) => {
      VariablesModel.loadedFromBot(BotModel.state.bot);
    });

    // var userVariablesObj = VariablesModel.state.userVariablesObject;
    // var userVariables = userVariablesObj.get("variables");
    // if (userVariables[variable.id]) {
    //   delete userVariables[variable.id];
    // }  else  { warn("No variable to delete"); }
    // userVariablesObj.set("variables",userVariables);
    // userVariablesObj.save().catch(function(error) { alert("There was an error deleting your variables"); warn(error); });
    // VariablesModel.setState({ userVariables : userVariables,  userVariablesObject : userVariablesObj });
  }

  // setUserVariables(userVariablesObj) {
  //   var userVariables, displayNameId;

  //   if (userVariablesObj && userVariablesObj.get("variables")) {
  //     userVariables = userVariablesObj.get("variables");
  //     displayNameId = userVariablesObj.get("displayNameId");
  //   } else {
  //     userVariables = {}
  //     displayNameId = "personName"

  //   }

  //   var filteredObj = (userVariables) ? userVariables :{};
  //   for (var varName in userVariables) {
  //     if (userVariables[varName]['name'].includes("Hidden*") || userVariables[varName]['id'].includes("Entity")) {
  //       delete filteredObj[varName]
  //     }
  //   }
  //   this.app.setState({userVariablesObj:userVariablesObj, userVariables:filteredObj, displayNameId:displayNameId});
  // }

  static nameForBotVariable(botVariable: string) {
    return _.startCase(botVariable.replace(/Bot_/g, "").replace(/_/g, ": "));
  }

  static nameForVariable(_variable: string) {
    let variable = String(_variable).replace("#*", "").replace("*#", "");

    if (variable.indexOf("[") > -1) {
      if (variable.indexOf("]") > -1) {
        return variable.substring(variable.indexOf("[") + 1, variable.indexOf("]"));
      }
    }
    if (variable.indexOf("Bot_") > -1) {
    }

    if (variable === "userId") {
      return "User Id";
    }
    if (variable === "thirdPartyId") {
      return "Third Party Id";
    }
    if (variable === "lastUserInput") {
      return "Last User Input";
    }
    if (variable === "URLPath") {
      return "URL Path";
    }
    if (variable === UserVars.User_LastAgentName) {
      return "Agent Name";
    }

    const variableDefinitions = VariablesModel.state.variableDefinitions;
    // const varId = variable.substring(firstIndex + 2, secondIndex )
    if (variableDefinitions && variableDefinitions[variable]) {
      return variableDefinitions[variable].value.name;
    } else {
      log("&&& it's a variable!");
      if (variable.indexOf("#*Entity") > -1) {
        return variable.replace("Entity ", "");
      }
    }

    return variable;
  }

  static replaceVariableNameInString(str: string, prepend?: string, append?: string) {
    if (!str) {
      return "";
    }
    if (str.indexOf("#*") === -1) {
      return str;
    }
    let mark = 0;
    while (str.indexOf("#*") !== -1 && mark < 3000) {
      var indexStart = str.indexOf("#*");
      var indexEnd = str.indexOf("*#");
      if (indexEnd < indexStart) {
        warn("improper setup for variables");
        str = str.replace("#*", "");
        str = str.replace("*#", "");
      } else {
        var variableId = str.substring(indexStart, indexEnd + 2);
        var replacement = VariablesModel.nameForVariable(variableId);
        if (prepend && append) {
          replacement = prepend + replacement + append;
        }
        str = str.replace(variableId, replacement);
      }
      mark++;
    }
    if (mark >= 2999) {
      warn("Improper parse in replaceVariableNameInString", str);
    }
    return str;
  }

  static variablesAndEntityVariables(
    skipUserId?: boolean,
    skipLastUserInput?: boolean,
    addMarkup?: boolean,
    includeURLPath?: boolean,
    skipBotVariable: boolean = false
  ) {
    if (!VariablesModel.state.variableDefinitions) {
      return null;
    }
    const variableDefinitions = VariablesModel.state.variableDefinitions;
    var specialVars = [];
    var variables = {};
    var entities = {};
    if (!skipUserId) {
      specialVars.push({ id: "userId", name: "User Id", isEntity: false });
    }
    if (!skipLastUserInput) {
      specialVars.push({ id: "lastUserInput", name: "Last User Input", isEntity: false });
    }
    if (includeURLPath) {
      specialVars.push({ id: "URLPath", name: "URL Path", isEntity: false });
    }
    for (var key in variableDefinitions) {
      if (variableDefinitions.hasOwnProperty(key) && !variableDefinitions[key].value.hidden) {
        variables[key] = { id: variableDefinitions[key].value.id, name: variableDefinitions[key].value.name, isEntity: false };
      }
    }
    
    const _entities = NLPModel.state.entities || [];
    //
    for (var i = _entities.length - 1; i >= 0; i--) {
      var name = _entities[i].name;
      if (name === "email" || name === "Entity email") {
        name = "Email";
        // continue;
      } else if (name === "personName") {
        name = "Person Name";
        // continue;
      } else if (name === "phonenumber") {
        name = "Phone Number";
        // continue;
      }
      let id = "Entity " + _entities[i].name;

      entities[id] = { id: id, name: name, isEntity: true };
    }
    var allVars, combined;
    if (skipBotVariable) {
      combined = Object.values({ ...variables, ...entities }).reverse();
    } else {
      combined = Object.values({ ...VariablesModel.botVariables, ...variables, ...entities }).reverse();
    }

    allVars = specialVars.concat(combined);

    for (var i = 0; i < allVars.length; i++) {
      if (addMarkup) {
        allVars[i].value = "#*" + allVars[i].id + "*#";
      } else {
        allVars[i].value = allVars[i].id;
      }
    }

    return allVars;
  }

  static inputProvider(id: string, display: string) {
    const allVariablesMap = VariablesModel.state.allVariablesMap;
    if (!allVariablesMap) {
      return id;
    }
    if (id.indexOf("#*[")) {
      // remove everything withing brackets
      id = id.replace(/\[.*?\]/g, "");
    }

    if (allVariablesMap[id]) {
      return allVariablesMap[id];
    }
    return id;
  }

  static getBotVariable(variableId: string): string {
    const bot = BotModel.state.bot;
    const botVariables = bot.get("botVariables");
    if (botVariables && botVariables[variableId]) {
      return botVariables[variableId];
    } else {
      if (RecommendedVariables[variableId] && !AlwaysCustom.includes(variableId as BotVariables)) {
        return RecommendedVariables[variableId];
      }
    }
  }
}
