import React, {useEffect, useState} from 'react';
import { NavBar } from '../../Models/NavBar/NavBar.Model';
import "./LoginView.scss"
import UserModel from '../../Models/User/UserModel';
import Parse from 'parse'
import { warn } from '../../Helpers/Logging/Logging';
import { Button } from '../../Components/Button/Button';
import { Input } from '../../Components/Input/Input';
import { Size } from '../../Components/Shared/Size';

function validateEmail(email:string) {
  var re = /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/;
  if (re.exec(email) == null) {
    return false;
  }
}

export default function LoginView()  {
    const [forgotPassword, setForgotPassword] = useState(false);
    const [username,  setUsername] = useState("");
    const [password,  setPassword] = useState("");
    const [errorMsg,  setErrorMsg] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    useEffect(()=> { 
      localStorage.removeItem("botId");
      NavBar.set(null, <NavBar.Component.Logo />,   null); }, [])

    const loginClicked = () => { 
      var validEmail = validateEmail(username.toLowerCase());
      if (validEmail === false) {
        setErrorMsg("Please enter a valid email");
        return;
      }
      setIsLoading(true); 
      setErrorMsg("");

      UserModel.login(username.toLowerCase(), password).then(user => { }).catch(error => { 
        if (error.message) {
          setErrorMsg(error.message);
        } else {
          setErrorMsg(error);
        }
        setIsLoading(false); 
      })
    
    }
    const recoverAccount = () => { 


      var validEmail = validateEmail(username.toLowerCase());
      if (validEmail === false) {
        setIsLoading(true); 
        setErrorMsg("Please enter a valid email");
        return;
      }
      setIsLoading(true); 
      setErrorMsg("");
      Parse.User.requestPasswordReset(username).then(() => {
          setForgotPassword(false)
          setErrorMsg("");
          setIsLoading(false); 

          alert('Please check your email for instructions on resetting your password.')
        })
        .catch((error) =>{
          // Show the error message somewhere
          warn("Error: " + error.code + " " + error.message);
          setErrorMsg(error.message);
          setIsLoading(false); 

        })
    
    }

    let content; 
    if (forgotPassword) {
        content = <div className="loginContent">
          <div className="forgotParagraph">Enter the email address associated with your swivl Studio account and we’ll send you instructions on how to reset your password.</div>
          <h4>Email Address</h4>
          <Input 
            size={Size.Large}
            value={username} 
            name="username"  
            onSet={setUsername}
            enableAutoComplete={true}
            
             />


        <Button 
            onClick={recoverAccount} 
            isLoading={isLoading} 
            style={Button.Style.Orange} 
            size={Button.Size.Large}
            >SUBMIT</Button>
             



          <div className="backToLogin"  onClick={()=>{setForgotPassword(false)}}>Back</div>
        </div>
      } else {
        content = <div className="loginContent">
          <h4>Email</h4>
          <Input 
            size={Size.Large}
            value={username} 
            name="username"  
            onSet={setUsername}
            enableAutoComplete={true}
             />
          <h4>Password</h4>
          <Input 
            size={Size.Large}
            value={password} 
            name="password"  
            onSet={setPassword}
            enableAutoComplete={true}
            type={Input.Type.Password}
            onReturnPressed={loginClicked}
             />


          <Button 
            onClick={loginClicked} 
            isLoading={isLoading} 
            style={Button.Style.Orange} 
            size={Button.Size.Large}
            >LOG IN</Button>

          
          <div className="loginText forgotText" onClick={()=>{setForgotPassword(true)}}>Forgot?</div>
        </div>
      }
  
  
  
      return (<div className="LoginView">
         <div className="LoginContent">
        <div ><img src="/img/logo/SwivlStudioWritten.png" alt="Swivl Studio" className="swivlLoginPic"></img></div>
        {content}
        <div className="errorMsg">{errorMsg}</div>
        </div>
      </div>
      );
}