import Parse from 'parse';
import {EmbedConfigForBot} from '@swivl/swivl-lib'
import {create} from 'zustand'
import BotModel from '../Bots/Bot.Model';
import { warn } from '../../Helpers/Logging/Logging';

interface StoreInterface {
  embedCodes?:Parse.Object[];
}


const BlankState:StoreInterface  = {
  embedCodes:undefined
}


export class EmbedCodesModel {
  static useState = create<StoreInterface>(set => ({  ...BlankState }))
  static setState = EmbedCodesModel.useState.setState; 
  static get state() { return EmbedCodesModel.useState.getState(); }
  static reset() {  EmbedCodesModel.setState({  ...BlankState }); }


   

    static load(forceRefresh) {
      if (EmbedCodesModel.state.embedCodes && !forceRefresh) { return; }

      var EmbedCodeClass = Parse.Object.extend("EmbedCode");
      var query = new Parse.Query(EmbedCodeClass);
      query.equalTo("bot", BotModel.state.bot)
      query.find().then(function(embedCodes) {
        EmbedCodesModel.setState({ embedCodes : embedCodes });
      }).catch(function(error) {
        warn("Error Loading Embed Codes", error);
      })
    }

    static getAll() {
      var EmbedCodeClass = Parse.Object.extend("EmbedCode");
      var query = new Parse.Query(EmbedCodeClass);
      query.include("bot")
      query.limit(1000)
      return query.find()
    }

    static createEmbedCode(config?, optionalBotParam?) {
      var EmbedCodeClass = Parse.Object.extend("EmbedCode");
      var embedCode = new EmbedCodeClass()
      var bot = (optionalBotParam) ? optionalBotParam : BotModel.state.bot;
      embedCode.set("name", "New Embed Code")
      embedCode.set("bot", bot)

      

      var theConfig = (config) ? config : EmbedConfigForBot(bot);
      theConfig.botId = bot.id;
      embedCode.set("config", theConfig);
      return embedCode.save().then(function(embedCode) {
        if (bot.id === BotModel.state.bot.id)  {
          let embedCodes = [...EmbedCodesModel.state.embedCodes] || [];
          embedCodes.push(embedCode)
          EmbedCodesModel.setState({ embedCodes : embedCodes });
        } 
        return Promise.resolve(embedCode)
      }).catch(function(error) {
        warn("Error Creating Embed Code", error);
        return Promise.reject(error)
      })

    }

    static updateEmbedCode(embed, shouldSave) {
      if (shouldSave) {
        embed.save().catch(function(error) { warn("Error saving embed"); alert("There was an error saving your embed code"); })
      }
      let embedCodes = [...EmbedCodesModel.state.embedCodes] || [];
      for (var i = 0; i < embedCodes.length; i++) {
        if (embed.id === embedCodes[i].id) {
          embedCodes[i] = embed;
          EmbedCodesModel.setState({ embedCodes : embedCodes });
          break;
        }
      }
    }
    static deleteEmbed(embed, shouldSave) {
      // if (shouldSave) {
      //   embed.save().catch(function(error) { warn("Error saving embed"); alert("There was an error saving your embed code"); })
      // }
      let embedCodes = [...EmbedCodesModel.state.embedCodes] || [];
      for (var i = 0; i < embedCodes.length; i++) {
        if (embed.id === embedCodes[i].id) {
          embedCodes.splice(i, 1);
          EmbedCodesModel.setState({ embedCodes : embedCodes });
          break;
        }
      }
      embed.destroy().catch(function(error) {
        warn("Error deleting embed", error);
        alert("There was an error deleting your embed code.");
       })
    }


}
