import {create} from 'zustand'

import { NavBarLogo } from "../../App/Layout/NavBar/Components/Logo/NavBar.Logo";
import { NabBarTitle } from "../../App/Layout/NavBar/Components/NavBar.Title";
import { NavBarBack } from "../../App/Layout/NavBar/Components/NavBar.Back";
import { NavBarTitleSwitch } from "../../App/Layout/NavBar/Components/TitleSwitch/NavBar.TitleSwitch";
import { NavBarSwitch } from "../../App/Layout/NavBar/Components/Switch/NavBar.Switch";
import { NavBarSearch } from "../../App/Layout/NavBar/Components/Search/NavBar.Search";
import { NavBarButton } from "../../App/Layout/NavBar/Components/NavBar.Button";
import { NotificationsModel } from '../Notifications/Notifications.Model';
import EscalationsModel from '../Escalations/Escalations.Model';
import { AgentModel } from '../Agent/Agent.Model';
import { ConversationsModel } from '../Conversations/Conversations.Model';



// enum NavBarComponent {
//   Logo = "Logo",

// }

interface StoreInterface {
    left?:React.ReactNode;
    center?:React.ReactNode;
    right?:React.ReactNode;
    title?:string;
}
const BlankState:StoreInterface = {
  left:undefined,
  center:undefined,
  right:undefined,
  title:undefined


};



export class NavBar { 
  static useState = create<StoreInterface>(set => ({  ...BlankState }))
  static setState = NavBar.useState.setState; 
  static get state() { return NavBar.useState.getState(); }
  static reset() {  NavBar.setState({  ...BlankState }); }
  static title = "swivlStudio";
  static navBarIconToggle = false; 
  static timer = setInterval(() => { 
    NavBar.flashNavBar();
    
    
  }, 1500);

  static TitleForPath:{[url:string]:string } = {
    "/":"swivlStudio",
  }



    static Component = {
      Logo: NavBarLogo, 
      Title: NabBarTitle, 
      Back:  NavBarBack,
      Button:NavBarButton,
      TitleSwitch:NavBarTitleSwitch,
      Switch:NavBarSwitch,
      Search:NavBarSearch,
    }

    // static nodeFor(component:NavBarComponent) { 
    //   switch (component) {
    //     case NavBarComponent.Logo:
    //       return <img src="/img/logo/swivl.png" alt="swivl" />
    //     default:
    //       return null;
    //   }
    // }
    
  static async set(left?:React.ReactNode, center?:React.ReactNode, right?:React.ReactNode, title?:string):Promise<any> {    
    NavBar.setState({
      left:left,
      center:center,
      right:right, 
    })
    if (title) {  NavBar.setTitle(title);  }
  }



  static async setLeft(left?:React.ReactNode):Promise<any> { NavBar.setState({ left:left });}
  static async setCenter(center?:React.ReactNode):Promise<any> { NavBar.setState({ center:center });}
  static async setRight(right?:React.ReactNode):Promise<any> { NavBar.setState({ right:right });}
  static async setTitle(title?:string):Promise<any> { 
    

    NavBar.title = (title) ? title + " | swivlStudio" : "swivlStudio"; 
    if (title !== "swivlStudio") {
      NavBar.TitleForPath[window.location.pathname] = title;
    }
    NavBar.flashNavBar();
  }

  static flashNavBar() {
    
    // if (NotificationsModel.hasNotification) {
    if (ConversationsModel.hasUnresolvedEscalations() && AgentModel.state.isAvailable) {
      const icon = NavBar.navBarIconToggle ? '💬 ' : '👋 '
      document.title =  icon + NavBar.title;
      NavBar.navBarIconToggle = !NavBar.navBarIconToggle;
    } else {
      document.title = NavBar.title;
    }
  }




}

