import { ServerEnvironment } from "@swivl/swivl-lib"

const Config = {
     APP_ID     : process.env.REACT_APP_APP_ID ||  "Swivl-Server-Production",
     ENVIRONMENT : process.env.REACT_APP_ENVIRONMENT ||  ServerEnvironment.PRODUCTION,
     IS_PROD    : process.env.REACT_APP_APP_ID  === "Swivl-Server-Production" ? true : false,
     SERVER_HOSTNAME : process.env.REACT_APP_SERVER_HOSTNAME || "api.swivl.studio",
     SOCKET_HOSTNAME: process.env.REACT_APP_SOCKET_HOSTNAME || process.env.REACT_APP_SERVER_HOSTNAME || "api.swivl.studio",
     NOTIFICATIONS_HOSTNAME: process.env.REACT_APP_NOTIFICATIONS_HOSTNAME ||  "notifications.swivl.studio",
     STUDIO_HOSTNAME: process.env.REACT_APP_APP_ID  === "Swivl-Server-Production" ? "swivl.studio" :  process.env.REACT_APP_APP_ID  === "Swivl-Server-Staging" ? "staging.swivl.studio" : "localhost:4201",
     FMS_SERVER_BASE_URL: `https://${process.env.REACT_APP_FMS_SERVER_HOSTNAME}`,     
}


export {Config};
