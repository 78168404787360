import React from 'react';
import "./NavBar.TitleSwitch.scss"
interface Props {
    name?: string,
    items:string[],
    itemWidth?: number,
    selectedIndex:number 
    onChange: (index:number, name?:string) => void
}
interface ItemProps {
    item:string, 
    index:  number,
    width:number, 
    selectedIndex:number 
    onChange: (index:number) => void
}
class SSTitleSwitchItem extends React.Component<ItemProps> {
  constructor(props:ItemProps) {
    super(props)
    this.onClick = this.onClick.bind(this)
  }
  onClick() {
    if (this.props.onChange  && this.props.selectedIndex != this.props.index ) {
      this.props.onChange(this.props.index)
    }
  }
  render() {
    const style = {width:this.props.width + "px" }
    const className = (this.props.selectedIndex === this.props.index) ? "ssTitleSwitchItem selected" :  "ssTitleSwitchItem";
    return <div onClick={this.onClick} style={style} className={className}>{this.props.item}</div>
  }
}
export class NavBarTitleSwitch extends React.Component<Props> {

  render() {
    const selectedIndex = (this.props.selectedIndex) ? this.props.selectedIndex : 0;
    const itemWidth = (this.props.itemWidth) ? this.props.itemWidth : 112;

    const items = this.props.items.map((item,index) => {
      return <SSTitleSwitchItem item={item} selectedIndex={selectedIndex} index={index} key={index} width={itemWidth} onChange={this.props.onChange}/>
    })

    const indicatorStyle = {width: (itemWidth - 6) + "px", left:((selectedIndex * itemWidth ) + 3)  + "px"}

    return <div className="ssTitleSwitch">
            <div className="ssTitleSwitchIndicatorWrapper">
              <div  style={indicatorStyle} className="ssTitleSwitchIndicator"></div>
            </div>
            <div className="ssTitleSwitchItemsWrapper">
              {items}
            </div>
          </div>;
   }
};

