import Parse from "parse";
import {create} from 'zustand'

import {AgentModel} from "../Agent/Agent.Model";
import BotModel from "../Bots/Bot.Model";

import Permissions from "../Parse/Permissions";

import { ModelController } from "../Model.Controller";
import { warn } from "../../Helpers/Logging/Logging";
import { NavigationHelper } from "../../Helpers/Navigation/Navigation.Helper";
// const log = require('debug')("UserModel");
function escapeRegex(string) {
  return string.replace(/[/\-\\^$*+?.()|[\]{}]/g, '\\$&');
}
interface StoreInterface {
  user?: Parse.User;
  permissions?:Permissions; 
  allPermissions?: Permissions[];
  isLoadingUser: boolean;
  invites?:Permissions[];
}

const BlankState:StoreInterface  = {
  user:undefined,
  permissions:undefined,
  allPermissions: undefined,
  isLoadingUser: true
}


export default class UserModel {
  static useState = create<StoreInterface>(set => ({  ...BlankState }))
  static setState = UserModel.useState.setState; 
  static get state() { return UserModel.useState.getState(); }
  static reset() {  UserModel.setState({  ...BlankState }); }



  
  static logout(supressNavigate?:boolean) {
    Parse.User.logOut();
    ModelController.resetModels(false);
    if (!supressNavigate) {
      NavigationHelper.navigate("/")
    }
      window.location.reload();
    

  }
  


  static async loadUser() {
    const _user = Parse.User.current();
    
    if (!_user) { 
      UserModel.setState({isLoadingUser:false});
      return;
     } 
     const user = await _user.fetch();

     user.set("lastLogin", new Date())
     user.save()

    var permissionsQuery = new Parse.Query(Permissions);
    permissionsQuery.equalTo("user", user);
    permissionsQuery.equalTo("active", true);
    permissionsQuery.include("bot.name");
    permissionsQuery.include("bot.avatar");
    permissionsQuery.include("bot.subscription");
    permissionsQuery.include("bot.userVariables");
    permissionsQuery.limit(10000);
    permissionsQuery.find().then(async (allPermissions) => {
      let bot, permissions; 
      const botId = localStorage.getItem("botId");

      if (botId) {
        if (user.get("isSuperAdmin")) {
          bot = new Parse.Object("Bot", { id: botId });
          permissions = Permissions.superUserPermissions();
        } else {
          allPermissions.forEach((_permission) => {
            if (_permission.get("bot") && _permission.get("bot").id === botId)
              bot = _permission.get("bot");
              permissions = _permission;
          });
        }
      }
      if (!permissions && allPermissions.length === 1) {
        permissions = allPermissions[0];
        bot = permissions.get("bot");
      }

      if (bot) {        
        bot = await BotModel.loadBot(bot.id);
      }
      UserModel.setState({
        isLoadingUser:false,
        user:user,
        allPermissions:allPermissions,
        permissions:permissions
      });

      AgentModel.getAgent()

    });
  }
  static hasPermissionsForBot(botId: string) {
    return false; 
    if (UserModel.isSuperAdmin) return true;
    if (!UserModel.state.permissions) return false;
    if (UserModel.state.permissions.get("bot").id === botId) return true;
    return false;
  }


  static login(username: string, password: string) {
    return Parse.User.logIn(username, password).then((user) => {
      UserModel.loadUser();
      return user;
    });
  }

  static setPermissions(permissions:Permissions) {
    UserModel.setState({
      permissions : permissions
    })
  }


  static userFullName() {
    const user = UserModel.state.user;
    if (!user) return "Unknown";
    let name = ""
    if (user.get("firstName")) { name += user.get("firstName") + " " }
    if (user.get("lastName")) { name += user.get("lastName") }
    if (name.length === 0) { name = user.get("username") }
    return name.trim();
  }


  static updateAvatarForUser(parseFile:Parse.File,user:Parse.User) {
    user.set("avatar", parseFile)
    UserModel.userUpdated(user);
  }

  static userUpdated(user) {
    return user.save().then(function() {
      if (user.id === UserModel.state.user.id) {  UserModel.setState({user:user})  }
    }).catch(function(error) { warn(error);  })
  }

 static updateEmailForUser(email, user) {
      var query = new Parse.Query(Parse.User);
      query.equalTo("username", email.toLowerCase());
      return query.find().then(function(users) {
        for (var i = 0; i < users.length; i++) {
          if (users[i].id !== user.id) {
            return Promise.reject({type:"in_use", message:"That username is already in use"});
          }
        }
        user.set("email",  email)
        user.set("username",  email.toLowerCase());
        UserModel.userUpdated(user);

        
      })

  }

  static updatePermissions(permissions:Permissions, shouldSave?:boolean) {
    let allPermissions = [...UserModel.state.allPermissions];
    for (let index = 0; index < allPermissions.length; index++) {
      if( allPermissions[index].id === permissions.id) {
        allPermissions[index] = permissions;
        break;
      }
    }
    UserModel.setState({
      allPermissions : allPermissions,
      permissions : permissions
    })
    if(shouldSave) {
      permissions.save();

    }
  }
  static get isSuperAdmin():boolean {
    return UserModel.state.user && UserModel.state.user.get("isSuperAdmin");
  }

  static loadInvites() {

    let email = UserModel.state.user.get("username").toLowerCase();
    email = escapeRegex(email);
    var permissionsQuery = new Parse.Query(Parse.Object.extend("Permissions"));
      permissionsQuery.include("bot");
      permissionsQuery.doesNotExist("user");
      permissionsQuery.equalTo("isInvite",true);
      permissionsQuery.equalTo("active",true);
      permissionsQuery.matches("inviteEmail", email, "i");
      permissionsQuery.find().then((permissions) => {
        
        if (permissions.length) {
         UserModel.setState({invites:permissions as Permissions[]})
        }
      }).catch(function() {
        warn("There was an error loading your invite.")
      })
    
  }
  static async acceptInvite(invite:Permissions) {
    invite.set("user", UserModel.state.user);
    invite.set("active",true);
    invite.save() 
    let invites = [...UserModel.state.invites];
    for (let index = 0; index < invites.length; index++) {
      if( invites[index].id === invite.id) {
        invites.splice(index,1);
        break;
      }
    }
    let allPermissions = [...UserModel.state.allPermissions];
    allPermissions.push(invite);
    UserModel.setState({invites:invites,allPermissions:allPermissions})
  }
  static async declineInvite(invite:Permissions) {
    invite.set("active",false);
    invite.save(); 
    let invites = [...UserModel.state.invites];
    for (let index = 0; index < invites.length; index++) {
      if( invites[index].id === invite.id) {
        invites.splice(index,1);
        break;
      }
    }
    UserModel.setState({invites:invites})
  }

}
