import {create} from 'zustand'

interface StoreInterface {
    showMainMenu:boolean 
    
}
const BlankState:StoreInterface  = {
    showMainMenu:false
  }
  

export class AppModel { 
    static useState = create<StoreInterface>(set => ({  ...BlankState }))
    static setState = AppModel.useState.setState; 
    static get state() { return AppModel.useState.getState(); }
    static reset() {  AppModel.setState({  ...BlankState }); }
  
  
  

    static toggleMainMenu() { AppModel.setState({ showMainMenu : !AppModel.state.showMainMenu }) }
}