import { AgentModel } from "./Agent/Agent.Model";
import { AIModel } from "./AI/AI.Model";
import { AppModel } from "./App/App.Model";
import AppsModel from "./Apps/Apps.Model";
import AllBotsModel from "./Bots/AllBots.Model";
import BotModel from "./Bots/Bot.Model";
import { BrainsModel } from "./Brains/Brains.Model";
import { ConversationsModel } from "./Conversations/Conversations.Model";
import { EmbedCodesModel } from "./Embed/EmbedCodes.Model";
import EscalationsModel from "./Escalations/Escalations.Model";
import ExperimentsModel from "./Experiments/Experiments.Model";
import FMSModel from "./FMS/FMS.Model";
import ModalModel from "./Modal/Modal.Model";
import SwivlNotifications from "./Notifications/SwivlNotifications";
import { QuestsModel } from "./Quests/Quests.Model";
import { QuickReplyModel } from "./QuickReply/QuickReply.Model";
import { SMSGroupModel } from "./SMSGroup/SMSGroup.Model";
import SubscriptionModel from "./Subscription/Subscription.Model";
import { TablesModel } from "./Tables/Tables.Model";
import UserModel from "./User/UserModel";
import VariablesModel from "./Variables/Variables.Model";

export class ModelController {
    static resetModels(maintainUser:boolean) {
        AgentModel.reset();
        AppModel.reset();
        AppsModel.reset();
        AllBotsModel.reset();
        BrainsModel.reset();
        EmbedCodesModel.reset();
        EscalationsModel.reset();
        ExperimentsModel.reset();
        ModalModel.reset();
        SMSGroupModel.reset();
        SubscriptionModel.reset();
        SwivlNotifications.reset();
        VariablesModel.reset();
        QuestsModel.reset();
        EmbedCodesModel.reset();
        FMSModel.reset();
        ConversationsModel.reset();
        AIModel.reset();
        QuickReplyModel.reset();
        TablesModel.reset();
        if (!maintainUser) {
          BotModel.reset();
          UserModel.reset();
        }
      }
    
}