import * as React from 'react';
import "./Button.scss";

import { IconType } from 'react-icons'
import { Spinner } from '../Spinner/Spinner';

// export enum ButtonType {
//   Text = "text",
//   Number = "number",
//   Integer = "integer",
//   Scalar = "scalar",
//   Password = "password",
//   Telephone = "tel"
// }

export enum ButtonStyle {
  Default = "styleDefault",
  Orange = "styleOrange",
  Dashed = "styleDashed",
  Yellow = "styleYellow",
  Teal = "styleTeal",
  Dark = "styleDark",
  Light = "styleLight",
  White = "styleWhite",
}
export enum ButtonSize {
  Small = "sizeSmall",
  Default = "sizeMedium",
  Medium = "sizeMedium",
  Large = "sizeLarge"
}


export type ButtonClickHandler = (event:React.MouseEvent<HTMLButtonElement>)=>void; 
export interface ButtonProps {
  onClick?:ButtonClickHandler | ButtonClickHandler[] // (event:React.MouseEvent<HTMLElement>):void | (event:React.MouseEvent<HTMLElement>):void[];
  name?:string;
  title?:string;
  size?:ButtonSize;
  style?:ButtonStyle;
  isActive?:boolean

  isRound?:boolean;
  isLoading?:boolean;
  isSelected?:boolean;

  isDisabled?:boolean;
  isFullWidth?:boolean;
  isDashed?:boolean;
  type?:"submit" | "reset" | "button";
  children?: React.ReactNode;
  icon?:IconType;
  rightIcon?:IconType;
  className?:string;

  //
  // value?:string;
  // isSearch?:boolean;
  // isReadOnly?:boolean;
  //
  // enableAutoComplete?:boolean;
  // type?:ButtonType;
  // placeholder?:string;
  // min?:number;
  // max?:number;
  // step?:number;
  //
  // onReturnPressed?():void;
}


export class Button extends React.Component<ButtonProps> {
  static Size = ButtonSize; 
  static Style = ButtonStyle; 

  constructor(props:ButtonProps) {
    super(props)
    this.onClick = this.onClick.bind(this)
  }

  onClick(eventPre:React.MouseEvent<HTMLButtonElement>) {
    
    const event = {target:{name:this.props.name}}
    if (this.props.onClick && !this.props.isLoading) { 
      if (typeof this.props.onClick !== 'function') {
        (this.props.onClick as ButtonClickHandler[]).forEach(element => {
          element(event as any); 
        });

      } else {
       ( this.props.onClick as ButtonClickHandler)(event as any); 
      }
    }
  }

  render() {
    // let controlClassName = "control";
    // let className = "input";

    //
    // let isReadOnly = (this.props.isReadOnly) ? true : false;
    //
    // if (this.props.isLoading) { controlClassName  += " " + "is-loading"; }
    // if (this.props.size) {
    //   controlClassName  += " " + this.props.size;
    //   className += " " + this.props.size;
    //  }
    //
    // if (this.props.isSearch) { leftIconType = faSearch; }
    //
    //
    //
    // if (this.props.color) { className += " " + this.props.color; }
    // if (this.props.isRound) { className += " is-rounded"; }
    //
    //
    // let type:string;
    // if (!this.props.type) {
    //   type = "text";
    // } else if (this.props.type === ButtonType.Integer || this.props.type === ButtonType.Scalar) {
    //   type = "number";
    // }
    //
    // const autoComplete = (this.props.enableAutoComplete) ? "on" : "off";
    //
    //



    let className = `SCButton ${((this.props.size) ? this.props.size : Button.Size.Medium)} ${((this.props.style) ? this.props.style : Button.Style.Default)}`;
    if (this.props.className) { className += " " + this.props.className; }
    
    // if (this.props.color) { className += " " +this.props.color  } else { className += " " + Color.Clear;}
    if (this.props.isRound) { className += " isRound"; }

    // if (this.props.isOutlined) { className += " isOutlined"}
    // if (this.props.isDashed) { className += " isDashed"}

    if (this.props.isSelected) { className += " isSelected"}
    if (this.props.isDisabled) { className += " isDisabled"}
    if (this.props.isActive) { className += " isActive"}


    if (this.props.isLoading) { className += " isLoading"}
    if (this.props.isFullWidth) { className += " isFullwidth"}




    let leftIcon:any, rightIcon:any;

    if (this.props.icon)  {
      leftIcon =  <span className={"icon is-left"}>
                    {this.props.icon as any}
                    </span>
    }

    if (this.props.rightIcon)  {
      rightIcon =  <span className={"icon is-right"}>
                    {this.props.rightIcon as any}
                    </span>
    }

    const type = (this.props.type) ? this.props.type : "button"; 

    return <button className={className} name={this.props.name} type={type} onClick={this.onClick} disabled={(this.props.isDisabled) ? true : false} >
            {leftIcon}
            {this.props.isLoading && <div className="spinner"><Spinner /></div> }
            <span className="btnText">{this.props.children}{this.props.title || ""}</span>
            {rightIcon}
          </button>;
   }
};
