import { EscalationInterface, InheritanceStatus } from "@swivl/swivl-lib";

import { AgentModel } from "../Agent/Agent.Model";
import BotModel from "../Bots/Bot.Model";
import Parse from "parse"
import { create } from 'zustand'
import { BotUserModel } from "../BotUser/BotUser.Model";
import { useSwivlNavigate } from "../NavBar/useSwivlNavigate";
import { warn } from "../../Helpers/Logging/Logging";
import { NotificationsModel } from "../Notifications/Notifications.Model";
import { nanoid } from "nanoid";

const log = require('debug')('EscalationsModel');


export const EscalationStatus = {
  PENDING: "pending", // Pending Opt In 
  ASSIGNED: "assigned",
  ACTIVE: "active",
  HANDLED: "handled",
  // MISSED   = "missed", 
  // IGNORED  = "ignored",
  // UNAVAILABLE = "unavailable",
  // OUTSIDE_HOURS = "outside of hours",
  // OPT_OUT = "opt out",
  TIMED_OUT: "timed out",
  OUTSIDE_HOURS: "outside of hours",
  NO_AGENT_AVAILABLE: "no agent available",

}

export const EscalationErrorType = {
  TIMED_OUT: "timed out",
  REASSIGNED: "reassigned"
}
interface StoreInterface {
  escalations: EscalationInterface[];
  escalationGroups: Parse.Object[];
  isSearching: boolean; // Is a search query being performed
  isLoading: boolean; // Is the initial load of all esclations being performed
  escalationSearchResults?: EscalationInterface[];

  escalationsPage: number;
  escalationsSearchPage: number;
  generatedSummaries:{[esclationId:string]:string|undefined};
}
const BlankState: StoreInterface = {
  escalations: undefined,
  escalationGroups: undefined,
  isSearching: false,
  isLoading: false,
  escalationSearchResults: undefined,

  escalationsPage: 1,
  escalationsSearchPage: 1,
  generatedSummaries:{}
}
export default class EscalationsModel {
  static ResultsPerPage = 50;
  static useState = create<StoreInterface>(set => ({ ...BlankState }))
  static setState = EscalationsModel.useState.setState;
  static get state() { return EscalationsModel.useState.getState(); }
  static reset() { EscalationsModel.setState({ ...BlankState }); }
  // static checkEscalationsTimer:any = setInterval(() => {
  //   EscalationsModel.checkEscalations();
  // }, 4000);
  static shouldPollEscalations = false;
  static searchTimer?: NodeJS.Timeout;


  static loadEscalations() { // I think this is deprecated
    if (!AgentModel.state.agent) {
      warn("no agent")
      return;
    }
    Parse.Cloud.run("getEscalationsForAgent", { botId: BotModel.state.bot.id, agentId: AgentModel.state.agent.id }).then((escalations) => {
      EscalationsModel.setState({ escalations: escalations });
      BotUserModel.loadUsersByIds(escalations.map(el => el.user.id));
    })
  }

  static loadAllEscalations() {
    
    EscalationsModel.setState({ isLoading: true });


    Parse.Cloud.run("getAllEscalationsForBot", { botId: BotModel.state.bot.id, limit: EscalationsModel.ResultsPerPage }).then((escalations) => {
      
      EscalationsModel.setState({ escalations: escalations, isLoading: false });

    })
  }
  static loadNextEscalationsPage() {
    if (EscalationsModel.state.isLoading) {
      return;
    }
    const page = EscalationsModel.state.escalationsPage + 1;
    
    EscalationsModel.setState({ isLoading: true, escalationsPage: page });
    Parse.Cloud.run("getAllEscalationsForBot",
      {
        botId: BotModel.state.bot.id,
        limit: EscalationsModel.ResultsPerPage,
        skip: EscalationsModel.ResultsPerPage * (page - 1)
      }
    ).then((escalations) => {
      
      const currentEscalations = EscalationsModel.state.escalations || [];
      EscalationsModel.setState({
        escalations: [...currentEscalations, ...escalations],
        isLoading: false,
      });
    })
  }

  static searchForEscalations(searchQuery: string, statuses: typeof EscalationStatus[], agentIds: [], page: number) {
    
    EscalationsModel.setState({ isSearching: true, escalationsSearchPage: page });

    if (EscalationsModel.searchTimer) {
      clearTimeout(EscalationsModel.searchTimer)
    }
    EscalationsModel.searchTimer = setTimeout(() => {
      const params = {
        botId: BotModel.state.bot.id, searchQuery: searchQuery,
        statuses: statuses,
        agentIds: agentIds,
        limit: EscalationsModel.ResultsPerPage,
        skip: EscalationsModel.ResultsPerPage * (page - 1)
      }
      

      Parse.Cloud.run("searchForEscalations", params).then((escalations) => {
        
        if (page == 1) {
          EscalationsModel.setState({
            escalationSearchResults: escalations,
            isSearching: false,
          });
        } else {
          const currentEscalations = EscalationsModel.state.escalationSearchResults || [];
          EscalationsModel.setState({
            escalationSearchResults: [...currentEscalations, ...escalations],
            isSearching: false,
          })
        }
      })
    }, 1000)

  }


  static checkEscalations() {
    if (EscalationsModel.shouldPollEscalations) {
      if (!AgentModel.state.agent) {

        return;
      }
      Parse.Cloud.run("getEscalationsForAgent", { botId: BotModel.state.bot.id, agentId: AgentModel.state.agent.id }).then((escalations) => {
        let existingIds = EscalationsModel.state.escalations.map(el => el.id);
        let newIds = escalations.map(el => el.id);
        let newEscalations = newIds.filter(el => !existingIds.includes(el));
        let oldEscalations = existingIds.filter(el => !newIds.includes(el));
        log("Loaded Escalations", existingIds, newIds, newEscalations, oldEscalations);
        oldEscalations.forEach(el => {

          NotificationsModel.clearEscalationNotification(el)

        })
        EscalationsModel.setState({ escalations: escalations });

      })

    }
  }

  static updateEscalation(escalation) {


    if (escalation.resolved) {
      NotificationsModel.clearEscalationNotification(escalation.id)
    }
    BotUserModel.loadUsersByIds([escalation.user.id]);
    const agent = AgentModel.state.agent;
    if (!agent) {
      log("Can't update...no agent");
      return;
    }
    if ((!escalation.assignedAgentId || escalation.assignedAgentId !== agent.id)) {
      log("Agent not assigned to me.");
      return;
    }
    let escalations = EscalationsModel.state.escalations;
    if (escalations) {
      for (let i = 0; i < escalations.length; i++) {
        if (escalations[i].id === escalation.id) {
          log("Found escalation");
          escalations[i] = escalation;
          EscalationsModel.setState({ escalations: escalations });

          return;
        }

      }
      escalations.unshift(escalation)
      EscalationsModel.setState({ escalations: escalations });

    } else {
      EscalationsModel.setState({ escalations: [escalation] });
    }
  }

  static resolveEscalationsForUser(user) {

    if (!EscalationsModel.state.escalations) { return; }
    const escalations = EscalationsModel.state.escalations.filter(escalation => {
      if (escalation.user && escalation.user.id === user.id) {
        NotificationsModel.clearEscalationNotification(escalation.id)

        return false;
      }
      return true;
    })
    EscalationsModel.setState({ escalations: escalations });

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const navigate = useSwivlNavigate()
    navigate("/conversations")

  }


  static loadGroups() {
    var query = new Parse.Query(Parse.Object.extend("EscalationsGroup"));
    query.equalTo("bot", BotModel.state.bot);
    query.limit(10000);
    query.find().then(async (escalationGroups) => {

      
      if (BotModel.state.bot.isChild) {
        
        let parentQuery = new Parse.Query(Parse.Object.extend("EscalationsGroup"));
        parentQuery.equalTo("bot", BotModel.state.bot.get("parent"));
        parentQuery.limit(10000);
        await parentQuery.find().then((parentEscalationGroups) => {
          

          // escalationGroups = [...parentEscalationGroups, ...escalationGroups];
          
          let groupsObject = {};

          parentEscalationGroups.forEach((group) => {
            groupsObject[group.get("slug")] = group;
          })
          escalationGroups.forEach((group) => {
            if (groupsObject[group.get("slug")]) {
              
              group.set("inheritance", InheritanceStatus.IS_CHILD)
            }
            groupsObject[group.get("slug")] = group;
          })
          

          EscalationsModel.setState({ escalationGroups: Object.values(groupsObject) });
        })
      } else {
        EscalationsModel.setState({ escalationGroups: escalationGroups });
      }

    }).catch((error) => {
      warn("Error loading escalations Config", error);
    })
  }

  static createGroup() {
    const EscalationGroup = Parse.Object.extend("EscalationsGroup");


    const hours = {
      timezone: "America/Denver",
      sun: { open: false, start: "9:00", end: "17:00" },
      mon: { open: true, start: "9:00", end: "17:00" },
      tue: { open: true, start: "9:00", end: "17:00" },
      wed: { open: true, start: "9:00", end: "17:00" },
      thu: { open: true, start: "9:00", end: "17:00" },
      fri: { open: true, start: "9:00", end: "17:00" },
      sat: { open: false, start: "9:00", end: "17:00" }
    }



    let group = new EscalationGroup()
    group.set("slug", nanoid(8))
    group.set("bot", BotModel.state.bot);
    group.set("name", "New Group");
    group.set("queue", true);
    const data = {
      queue: true,
      initialMessage: "Please wait while we find you an agent.",
      hours: hours,
      agents: []
    }
    group.set("data", data);
    return group.save().then((saved) => {
      let escalationGroups = EscalationsModel.state.escalationGroups;
      escalationGroups.push(saved)
      EscalationsModel.setState({ escalationGroups: escalationGroups });


      return saved;
    })



  }

  static async makeGroupAChild(group) {
    var objectJSON = group.toJSON();
    delete objectJSON.objectId; // force it to be a new DB object if you save it
    var child = new Parse.Object( group.className );
    child.set( objectJSON );
    child.set("bot", BotModel.state.bot);
    child.set("inheritance", InheritanceStatus.IS_CHILD); 
     
    await child.save();
    let escalationGroups = EscalationsModel.state.escalationGroups;
    for (let i = 0; i < escalationGroups.length; i++) {
      if (escalationGroups[i].get("slug") === group.get("slug")) {
        escalationGroups[i] = child;
        EscalationsModel.setState({ escalationGroups: escalationGroups });
        return;
      }
    }
    // return EscalationsModel.loadGroups();

  }

  static updateGroup(escalationGroup, save) {
    log("updateGroup", escalationGroup, save);


    if (save) { escalationGroup.save(); log("saving", escalationGroup.id); }
    let escalationGroups = EscalationsModel.state.escalationGroups;
    escalationGroups[escalationGroups.findIndex(el => el.id === escalationGroup.id)] = escalationGroup;
    EscalationsModel.setState({ escalationGroups: escalationGroups });

  }

  static deleteGroup(escalationGroup) {
    let escalationGroups = EscalationsModel.state.escalationGroups;

    let index = escalationGroups.findIndex(el => el.id === escalationGroup.id)
    if (index) { escalationGroups.splice(index, 1); }
    EscalationsModel.setState({ escalationGroups: escalationGroups });

    escalationGroup.destroy()


  }


  static acceptEscalation(escalation) {
    NotificationsModel.clearEscalationNotification(escalation.id)

    log("acceptEscalation");
    if (!AgentModel.state.agent) {
      warn("acceptEscalation - no agent")
      return;
    }
    return Parse.Cloud.run("acceptEscalation", { escalationId: escalation.id, agentId: AgentModel.state.agent.id }).then((response) => {
      let newEscalation = (response.escalation) ? response.escalation : escalation;

      warn("acceptEscalation - got escalation", response)
      if (response.error) {
        warn("acceptEscalation - got error", response)
        newEscalation.error = response;
        EscalationsModel.updateEscalation(newEscalation)
      } else {
        EscalationsModel.updateEscalation(response)
      }
   
      return newEscalation;
    }).catch(e => {
      log("acceptEscalation - catch", e.message);
      let newEscalation = (e.escalation) ? e.escalation : escalation;
      newEscalation.error = e;
      EscalationsModel.updateEscalation(newEscalation)

    })
  }

  static ignoreEscalation(escalation) {
    NotificationsModel.clearEscalationNotification(escalation.id)

    if (!AgentModel.state.agent) {
      warn("reject - no agent")
      return;
    }
    let escalations = EscalationsModel.state.escalations;
    if (escalations) {
      for (let i = 0; i < escalations.length; i++) {
        if (escalations[i].id === escalation.id) {
          log("Found escalation");
          escalations.splice(i, 1);
          EscalationsModel.setState({ escalations: escalations });


        }
      }
    }
    return Parse.Cloud.run("rejectEscalation", { escalationId: escalation.id, agentId: AgentModel.state.agent.id }).then((response) => {
    }).catch(e => {
      warn("Error ignoring escalation", e)
    })

  }


  static removeEscalation(escalation) {
    log("Dismiss");
    NotificationsModel.clearEscalationNotification(escalation.id)

    let escalations = EscalationsModel.state.escalations;
    if (escalations) {
      for (let i = 0; i < escalations.length; i++) {
        if (escalations[i].id === escalation.id) {
          log("Found escalation");

          escalations.splice(i, 1);
          EscalationsModel.setState({ escalations: escalations });


          return;
        }
      }
    }
  }


  static generateSummaryForEscalation(escalation) {
    let generatedSummaries = EscalationsModel.state.generatedSummaries;
    if (generatedSummaries && generatedSummaries[escalation.id] && generatedSummaries[escalation.id] !== "loading") {
      return generatedSummaries[escalation.id];
    }

    generatedSummaries[escalation.id] = "loading";
    EscalationsModel.setState({ generatedSummaries: generatedSummaries });
    
    return Parse.Cloud.run("generateSummaryForEscalation", { escalationId: escalation.id}).then((response) => {
      log("generateSummaryForEscalation", response)
      let generatedSummaries = EscalationsModel.state.generatedSummaries;
      generatedSummaries[escalation.id] = "LOADED";
      EscalationsModel.setState({ generatedSummaries: generatedSummaries });
  
      let escalations = EscalationsModel.state.escalations;
      if (escalations) {
        for (let i = 0; i < escalations.length; i++) {
          if (escalations[i].id === escalation.id) {
            log("Found escalation");
            escalations[i].summary = response;
            EscalationsModel.setState({ escalations: escalations });
            return;
          }
        }
      }
      let escalationSearchResults = EscalationsModel.state.escalationSearchResults;
      if (escalationSearchResults) {
        for (let i = 0; i < escalationSearchResults.length; i++) {
          if (escalationSearchResults[i].id === escalation.id) {
            log("Found escalation");
            escalationSearchResults[i].summary = response;
            EscalationsModel.setState({ escalationSearchResults: escalationSearchResults });
            return;
          }
        }
      }
   

      return response;
    }).catch(e => {
      warn("Error ignoring escalation", e)
    })
  }

  // static hasUnresolvedEscalations():boolean {
  //   const agent = AgentModel.state.agent; 
  //   let escalations = EscalationsModel.state.escalations;
  //   if (agent && escalations) {

  //     const hasUnresolvedEscalations = escalations.filter((escalation) => {
  //       return escalation.assignedAgent.id === agent.id && escalation.status === EscalationStatus.ASSIGNED;
  //     })
  //     // 


  //     return hasUnresolvedEscalations.length > 0;


  //   }

  //   return false; 
  // }

}