import React from "react";
import { FiEdit } from "react-icons/fi";
import { Input } from "../../../../Components/Input/Input";
import { Button } from "../../../../Components/Button/Button";

interface Props {
    title?:string;
    children?:React.ReactNode;
    canEdit?:boolean;
    onSave?(newTitle:string):void;
}


export const NabBarTitle: React.FC<any> = (props:Props) => {
    if (props.canEdit) { return <EditableNavBar {...props} /> }
    return <div className="title">{props.title}{props.children}</div> 

}

const EditableNavBar: React.FC<any> = (props:Props) => {
    const [isEditing, setIsEditing] = React.useState(false)
    const [title, setTitle] = React.useState(props.title ?? "")
    if (!isEditing) {
        return <div className="title canEdit" 
            onClick={()=>setIsEditing(true)}
        >{props.title}{props.children}
            <div className="titleEditButton"><FiEdit />
</div>
        </div> 
    }
    return <div className="title">
        <Input value={title} onChange={(event)=>{
            setTitle(event.target.value)
        }} 
        onReturnPressed={() => {
                props.onSave?.(title)
                setIsEditing(false)
            
            
        }}        />
        <Button 
            style={Button.Style.Orange}
        onClick={()=> {
            props.onSave?.(title)
            setIsEditing(false)
        }}>Save</Button>
        <Button
                    style={Button.Style.Light}

        onClick={()=>setIsEditing(false)}>Cancel</Button>
        </div>




}