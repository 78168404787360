import Parse from 'parse';
import Bot from './Bot';

export interface PermissionsInterface {
    conversations:boolean,
    training:boolean,
    user_journeys:boolean,
    bot_settings:boolean,
    cancel_account:boolean,
    payment_settings:boolean,
    variables:boolean,
    server_settings:boolean,
    embed_builder:boolean,
    users_and_permissions:boolean
    escalation_history?:boolean
    reviews_manager?:boolean
    sms_campaign?:boolean
  }  
  
  const adminPermissions:PermissionsInterface = {
    conversations:true,
    training:true,
    user_journeys:true,
    bot_settings:true,
    cancel_account:true,
    payment_settings:true,
    variables:true,
    server_settings:true,
    embed_builder:true,
    users_and_permissions:true,
    reviews_manager:true,
    sms_campaign:true
  }
  
  const editorPermissions:PermissionsInterface = {
    conversations:false,
    training:false,
    user_journeys:true,
    bot_settings:true,
    cancel_account:false,
    payment_settings:false,
    variables:true,
    server_settings:false,
    embed_builder:true,
    users_and_permissions:false,
    reviews_manager:true,
    sms_campaign:false

  }
  const agentPermissions:PermissionsInterface = {
    conversations:true,
    training:false,
    user_journeys:false,
    bot_settings:false,
    cancel_account:false,
    payment_settings:false,
    variables:false,
    server_settings:false,
    embed_builder:false,
    users_and_permissions:false ,
    reviews_manager:false,
    sms_campaign:false

  }
  const trainerPermissions:PermissionsInterface = {
    conversations:false,
    training:true,
    user_journeys:false,
    bot_settings:false,
    cancel_account:false,
    payment_settings:false,
    variables:false,
    server_settings:false,
    embed_builder:false,
    users_and_permissions:false,
    reviews_manager:false,
    sms_campaign:false

  }
  
  const teamLeaderPermissions:PermissionsInterface = {
    conversations:true,
    training:true,
    user_journeys:true,
    bot_settings:true,
    cancel_account:false,
    payment_settings:false,
    variables:true,
    server_settings:false,
    embed_builder:true,
    users_and_permissions:false,
    reviews_manager:false,
    sms_campaign:false
  }
  
  
export default class Permissions extends Parse.Object {
    constructor() { super('Permissions'); }
    privilege(name:string): boolean { 


      
      return this.get("active") && this.get("permissions") && this.get("permissions")[name] ? true : false; }
    get bot(): Bot { return this.get("bot")}

    get conversations(): boolean { return this.privilege("conversations"); }
    get training(): boolean { return this.privilege("training"); }
    get user_journeys(): boolean { return this.privilege("user_journeys"); }
    get bot_settings(): boolean { return this.privilege("bot_settings"); }
    get cancel_account(): boolean { return this.privilege("cancel_account"); }
    get payment_settings(): boolean { return this.privilege("payment_settings"); }
    get variables(): boolean { return this.privilege("variables"); }
    get server_settings(): boolean { return this.privilege("server_settings"); }
    get embed_builder(): boolean { return this.privilege("embed_builder"); }
    get users_and_permissions(): boolean { return this.privilege("users_and_permissions"); }
    get escalation_history(): boolean { return this.privilege("escalation_history"); }
    get reviews_manager(): boolean { return this.privilege("reviews_manager"); }
    get sms_campaign(): boolean { return this.privilege("sms_campaign"); }
    
    static roleForPermissions(permissions, rolePermissions) {
        for (var key in rolePermissions) { if (rolePermissions.hasOwnProperty(key) && rolePermissions[key] !== permissions[key]) { return false; } }
        return true;
    }

    static superUserPermissions():Permissions {
        let perm = new Permissions()
        perm.set("permissions", adminPermissions)
        perm.set("active", true)
        return perm; 
    }

    static roleValueForPermissions(permissions) {
      if (this.roleForPermissions(permissions, adminPermissions)) { return "admin"; }
      if (this.roleForPermissions(permissions, editorPermissions)) { return "editor"; }
      if (this.roleForPermissions(permissions, agentPermissions)) { return "agent"; }
      if (this.roleForPermissions(permissions, trainerPermissions)) { return "trainer"; }
      if (this.roleForPermissions(permissions, teamLeaderPermissions)) { return "team leader"; }
      return "custom";
    }
    static permissionsForRole(role) {
      if (role === "admin")       { return adminPermissions; }
      if (role === "editor")      { return editorPermissions; }
      if (role === "agent")       { return agentPermissions; }
      if (role === "trainer")     { return trainerPermissions; }
      if (role === "team leader") { return teamLeaderPermissions; }
    }

}
Parse.Object.registerSubclass('Permissions', Permissions);
