import {create} from 'zustand'

import UserModel from "../User/UserModel";
import Parse from 'parse'
import BotUser from "../Parse/BotUser";
import { AgentModel } from "../Agent/Agent.Model";
import BotModel from "../Bots/Bot.Model";
import VariablesModel from "../Variables/Variables.Model";
import { UserInterface, UserVars } from '@swivl/swivl-lib';
import { warn } from '../../Helpers/Logging/Logging';
import { ConversationsModel } from '../Conversations/Conversations.Model';

const log = require('debug')("BotUserModel");

const NUMBER_OF_USERS_PER_PAGE = 100;

function sortByActivityAndLastInteraction(users) {
    return users.sort( (user1, user2) => {
        if (user1.isActive > user2.isActive) return -1;
        if (user1.isActive < user2.isActive) return 1;
        if (user2.get("lastInteraction") > user1.get("lastInteraction")) return 1;
        if (user2.get("lastInteraction") < user1.get("lastInteraction")) return -1;
        return 0; 
    })
}
type BotUserIdentifier = BotUser|UserInterface|string; 
interface StoreInterface {
    allUsers?:{[id:string]:BotUser} ,
    users?:BotUser[]
    loadedUsers:Boolean
    loadedArchivedUsers:Boolean
    archivedUsers?:BotUser[]
    activeUserIds:string[]; 


    loadingArchivedUsers:boolean,
    archivedPage:number,
    archivedHasMore:boolean,
    loadedArchivedPage:number,
}
const BlankState:StoreInterface  = {
  allUsers:undefined,
  users:undefined,
  archivedUsers: undefined,
  activeUserIds: [],
  loadedUsers:false,
  loadedArchivedUsers:false,

  loadingArchivedUsers:false,
  archivedPage:0,
  archivedHasMore:true,
  loadedArchivedPage:0,

}


export class BotUserModel {
  static useState = create<StoreInterface>(set => ({  ...BlankState }))
  static setState = BotUserModel.useState.setState; 
  static get state() { return BotUserModel.useState.getState(); }
  static reset() {  
    BotUserModel.setState({  ...BlankState });
    BotUserModel.shouldRefreshActive = false; 
  }
    

  static checkBotUsersTimer = setInterval(() => {
    if (BotUserModel.shouldRefreshActive) {
      BotUserModel.load(true, false );
    }
  }, 10000);



  static shouldRefreshActive:boolean = false;

  static botsWhosTakeoverJustEnded = {} 
  static botsWhosTakeoverJustStarted = {} 


  static getQuery() {
    var query = new Parse.Query(BotUser);
    query.equalTo("bot", BotModel.state.bot);
    query.notEqualTo("isTestBotUser", true);
    query.descending('lastInteraction');
    query.include("takeoverAgent")
    query.limit(10000)
    return query;
  }


  static load(forceRefresh:boolean, archived:boolean) {

    if (!forceRefresh) {
      if (archived) {
        if ( BotUserModel.state.archivedUsers && BotUserModel.state.archivedUsers.length) { 
          log("Not loading Archived")
          return; }
      } else if (BotUserModel.state.users && BotUserModel.state.users.length) { 
        log("not loading users")
        return; }
    }

    
    // if (!forceRefresh && 
    //   ((archived && BotUserModel.state.archivedUsers) || BotUserModel.state.allUsers)) { 
    //    log("Not refreshing users", archived, BotUserModel.state.archivedUsers);
       
    //     return; }
    let query = BotUserModel.getQuery()
    query.notEqualTo("isArchived", archived? false: true)
    query.find().then((users)=> {
      if (archived) {
        BotUserModel.setState({
          loadedArchivedUsers:true
        })
      } else {
        BotUserModel.setState({
          loadedUsers:true
        })
      }
      
      BotUserModel.updateUsers(users);
    }).catch((error) => {
      warn("Error loading unhandled messages", error);
    })
  }


  static loadArchivedUsers(page?:number) {
    if (!page) { page = BotUserModel.state.loadedArchivedPage + 1;  }
    if (BotUserModel.state.loadedArchivedPage >= page) {
      
      return; 
    } 
    BotUserModel.setState({loadingArchivedUsers:true})
    let query = BotUserModel.getQuery()
    query.equalTo("isArchived", true)
    query.limit(NUMBER_OF_USERS_PER_PAGE) 
    query.skip(NUMBER_OF_USERS_PER_PAGE * (page - 1))
    query.find().then((users)=> {
      
      BotUserModel.setState({
        loadedArchivedUsers:true,
        archivedHasMore:users.length >= NUMBER_OF_USERS_PER_PAGE,
        archivedPage:page,
        loadingArchivedUsers:false,
      })
      BotUserModel.updateUsers(users);
  
    }).catch((error) => {
      warn("Error loading Archived Users", error);
    })
  }
  static loadNextPageOfArchivedUsers() {
    BotUserModel.loadArchivedUsers(BotUserModel.state.archivedPage + 1);
  }



  static searchForUser(name:string, isArchived) {
  

    let query = BotUserModel.getQuery()
    // query.contains("name", name)
    query.fullText("name", name)
    query.equalTo("isArchived", isArchived)
    query.limit(1000)

    
    return query.find()
  }

  static loadUsersByIds(ids:string[]) {
    let allUsers = {...BotUserModel.state.allUsers}
    if (!ids.length) { return; }
    const idsNotLoaded = ids.filter(id => !allUsers[id]);
    let query = new Parse.Query(BotUser);
    query.containedIn("objectId", idsNotLoaded);
    query.find().then((users)=> {
      if (!allUsers) { allUsers = {} }
      for (let i = 0; i < users.length; i++) {
        allUsers[users[i].id] = users[i];
      }
      BotUserModel.processAllUsers(allUsers);
    }).catch((error) => {
      warn("Error loading loadUsersByIds", error);
      
    }) 


  }

  static loadAndUnarchiveUser(userId) {
    
    var query = new Parse.Query(BotUser);
    return query.get(userId).then((user)=> {
      user.set("isArchived", false);
      user.save()
      let allUsers = {...BotUserModel.state.allUsers} || {};
      allUsers[user.id] = user;
      BotUserModel.processAllUsers(allUsers);
    }).catch((error) => {
      warn("Error loading and unarchiving user",userId, error);
    })
  }


  static async blockOrUnbockUser(user:BotUser, block:boolean) {
    user.set("isBlocked", block);
    if (block) {
      user.set("isArchived", true);
    }
    await user.save();
    let allUsers = {...BotUserModel.state.allUsers} || {};
    allUsers[user.id] = user;
    BotUserModel.processAllUsers(allUsers);      
  }



  static updateUsers(usersToUpdate) {
    
    let allUsers = {...BotUserModel.state.allUsers}
    for (let i = 0; i < usersToUpdate.length; i++) {
        allUsers[usersToUpdate[i].id] = usersToUpdate[i];
        if (BotUserModel.state.activeUserIds.includes(usersToUpdate[i].id)) {
          usersToUpdate[i].isActive = true;
        }
      }
    BotUserModel.processAllUsers(allUsers);
  }

    static processAllUsers(allUsers:{[id:string]:BotUser}) {
      

    

    let users = [], archivedUsers = []; 
    for (const id in allUsers) { if (Object.hasOwnProperty.call(allUsers, id)) {
        const user = allUsers[id];

        // if (user.forceHideTakeover) {
        //   

        //     user.isTakenOver = false;
        //     user.forceHideTakeover = false;
            
        //   } else if (user.forceShowTakeover) {
        //     user.isTakenOver = true;
        //     user.forceShowTakeover = false;
        // } 


        if (user.get("isArchived")) {
          archivedUsers.push(user)
        } else {
          users.push(user)
        }


    }}
    if (users.length) { users = sortByActivityAndLastInteraction(users) }
    if (archivedUsers.length) { archivedUsers = sortByActivityAndLastInteraction(archivedUsers) }
    log("Updating users!", users, archivedUsers);

    BotUserModel.setState({
        allUsers:allUsers,
        users:users,
        archivedUsers:archivedUsers
      }); 
  }

  static updateUser(user:BotUser, save?:boolean) {
    if (!BotUserModel.state.allUsers || !BotUserModel.state.allUsers[user.id]) { warn("No USer", user); return; }
    if (save) { 
      
      user.save() } 
    
      

    BotUserModel.updateUsers([user]);
  }

  static refreshActive(activeBotUsers) {
    

    if (!BotUserModel.shouldRefreshActive || !BotUserModel.state.allUsers) {    return;  }
    let allUsers = {...BotUserModel.state.allUsers}
    var hasChanged = false;
    for (const id in allUsers) { if (Object.hasOwnProperty.call(allUsers, id)) {
      const user = allUsers[id];
      const isActive = activeBotUsers.includes(user.id);
      if (user.isActive && !isActive) { hasChanged =  true;  

      } else if (isActive) {  hasChanged =  true;  } 
      allUsers[id].isActive = isActive;
  }}

    if (hasChanged) { BotUserModel.processAllUsers(allUsers); }
  }
  

static async getUser(userObjectInterfaceOrId:BotUserIdentifier):Promise<BotUser> {
  if (userObjectInterfaceOrId instanceof BotUser) {
    return userObjectInterfaceOrId;
  } else {
    const botUserId = (typeof userObjectInterfaceOrId === "string")  ? userObjectInterfaceOrId : userObjectInterfaceOrId.id;
    var query = new Parse.Query(BotUser);
    log("fetching updated bot user")
    return query.get(botUserId)
  }
}


  static async takeoverConversation(_botUser:BotUserIdentifier, takeoverChannel?:string) {
    const botUser = await BotUserModel.getUser(_botUser);
    setTimeout(() => {
      window.dispatchEvent(new CustomEvent("showGreeting"));                                                   
  }, 100);
    ConversationsModel.takeoverConversation(botUser.id);
    if (!botUser) { warn("Unable to get bot user", _botUser); return; }

    if (takeoverChannel) {
      botUser.set("takeoverChannel", takeoverChannel);
      if (takeoverChannel === "Web") {   botUser.set("currentChannel", "WebSocket"); } 
      if (takeoverChannel === "SMS" || takeoverChannel === "Twilio") {   botUser.set("currentChannel", "Twilio");  } 
      if (takeoverChannel === "GMB") {   botUser.set("currentChannel", "GMB");  } 
      
    }
    BotUserModel.botsWhosTakeoverJustStarted[botUser.id] = true;

    const agent = await AgentModel.getAgent()
    if (agent) {
      log("takeover with agent")
      Parse.Cloud.run('takeoverConversationWithAgent', {userId:botUser.id, agentId:agent.id, source:"swivlStudio", takeoverChannel:takeoverChannel}).then((response) => {
        botUser.set("isTakenOver", true);
        let variables = botUser.get("userVariables") || {};
        variables[UserVars.User_LastAgentName] = agent.name; 
        botUser.set("userVariables", variables);
        BotUserModel.updateUser(botUser, false);


        window.dispatchEvent(new CustomEvent("conversationTakenOver", { detail: {userId:botUser.id, agentId:agent.id}}));
        
      }).catch((error) => {
        
      });
      return; 
    }



    // botUser.forceShowTakeover = true;
    this.updateUser(botUser, false);
    const avatarURL = (
        UserModel.state.user &&
        UserModel.state.user.get("avatar") && 
        UserModel.state.user.get("avatar").url()) ? UserModel.state.user.get("avatar").url() : null;

    Parse.Cloud.run('takeoverConversation', {userId:UserModel.state.user.id, userFullName:UserModel.state.user.get("firstName") + " " + UserModel.state.user.get("lastName"), botUserId:botUser.id, source:"swivlStudio", avatarURL:avatarURL, takeoverChannel:takeoverChannel}).then(function(response) {
      BotUserModel.botsWhosTakeoverJustStarted[botUser.id] = false;
      botUser.set("isTakenOver", true);
      BotUserModel.updateUser(botUser, false);
    }).catch((error) => {
      
    });
  }

  static async endTakeover(_botUser:BotUserIdentifier) { // This can be a JSON object or a Parse object as long as it has an ID
    const botUser = await BotUserModel.getUser(_botUser);
    if (!botUser) { warn("Unable to get bot user", _botUser); return; }
    ConversationsModel.endTakeover(botUser.id);

    const agent = await AgentModel.getAgent()
    if (agent) {
      log("ending with agent")
      botUser.isTakenOver = false;
      // botUser.forceHideTakeover = true;
      BotUserModel.updateUser(botUser, false);
      
      BotUserModel.botsWhosTakeoverJustEnded[botUser.id] = true;

      Parse.Cloud.run('endTakeoverWithAgent', {userId:botUser.id, agentId:agent.id, source:"swivlStudio"}).then((response) => {
        BotUserModel.botsWhosTakeoverJustEnded[botUser.id] = false;

        // if (!isFromEscalation) {
        //   botUser.set("isTakenOver", true);
        //   this.updateUser(botUser, false);
        // } else {
        //   if (this.allUsers[botUser.id]) {
        //     this.allUsers[botUser.id].set("isTakenOver", false)
        //     this.updateUser(this.allUsers[botUser.id], false);

        //   }

        // }

      }).catch((error) => {
        
      });
      return; 
    } else {
        warn("No Agent!");
    }
    
  }

  static archiveConversation(botUser:BotUser|string, shouldArchive:boolean) {
    let _botUser = typeof botUser === "string" ? BotUserModel.state.allUsers[botUser] : botUser;
    if (!_botUser) { warn("Unable to get bot user", botUser); return; }
    _botUser.set("isArchived", shouldArchive)
    ConversationsModel.setArchived(_botUser.id, shouldArchive);
    BotUserModel.updateUser(_botUser,true)

  }


  static displayNameForUser(user) {

    const displayNameId = UserVars.User_Name;
    // if (this.app.state.displayNameId) {
    //   displayNameId = this.app.state.displayNameId;
    // } else if (this.app.state.userVariablesObj && this.app.state.userVariablesObj.get("displayName")) {
    //   displayNameId = this.app.state.userVariablesObj.get("displayName");
    // } else if (this.app.state.bot &&  this.app.state.bot.get("userVariables") && this.app.state.bot.get("userVariables").get("displayName")) {
    //   displayNameId = this.app.state.bot.get("userVariables").get("displayName");
    // }

    if (displayNameId)  {
      if (user.get("userVariables") && user.get("userVariables")[displayNameId] && user.get("userVariables")[displayNameId].length > 0 ) {
        return user.get("userVariables")[displayNameId];
      }
    }
    if (user.get("name") && user.get("name").length > 0) {
      return user.get("name");
    } 

    if (user.get("userNumber")) {
      return "User " + user.get("userNumber");
    } else {
      return  "User " + user.id;
    }

  }


  static fetchUpdatedItem(botUserId:string, isTakenOver:boolean) {
    log("fetchUpdatedItem",botUserId, isTakenOver);
    
    if (!BotUserModel.state.users) {
      return;
    }
    var BotUserClass = Parse.Object.extend("BotUser");
    var query = new Parse.Query(BotUserClass);
    log("fetching updated bot user")

    query.get(botUserId).then((user:any) => {
      log("got user",user)
      if (BotUserModel.state.users) {
        var found = false;
        var users = BotUserModel.state.users;
        for (var i = 0; i < users.length; i++) {
          if (users[i].id === user.id) {
            if (BotUserModel.botsWhosTakeoverJustEnded[users[i].id] 
              || BotUserModel.botsWhosTakeoverJustStarted[users[i].id]) {
              

            // if (users[i].forceHideTakeover || users[i].forceShowTakeover) {
              
              return 
            }
            log("SETTING UPDATED USER!!!!")
            
            user.isActive = users[i].isActive
            user.set("isTakenOver",isTakenOver);
            users[i] = user;
            found = true;
            break;
          }
        }
        if (!found) {
          users.unshift(user);
        }
        
        
        BotUserModel.updateUsers(users)
      }
    }).catch((e) => { warn("Error updating user items", botUserId, e.message || e);

    })

  }



  static async setChannelForUser(botUser:BotUser, channel:string) {
    botUser.set("currentChannel", channel);
    await botUser.save();
    BotUserModel.updateUsers([botUser]);
  }

}
