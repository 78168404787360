import { create } from "zustand";
import Parse from "parse";

import BotModel from "../Bots/Bot.Model";
import { warn } from "../../Helpers/Logging/Logging";
import { Config } from "../../Config/Config";
import { JourneyInterface, InheritanceStatus, QuestInterface, QuestType, Slug } from "@swivl/swivl-lib";
import { ReorderHelper } from "../../Helpers/Reorder/ReorderHelper";
import { DraggableLocation } from "@hello-pangea/dnd";
import { NotificationsModel } from "../Notifications/Notifications.Model";
import { nanoid } from "nanoid";
import { QuestModelHelper } from "./Helpers/QuestModel.Helper";

export interface WorkflowsJourney {
  journey?: JourneyInterface;
  quests: QuestInterface[];
  isUnassignedGroup?: boolean;
}

interface StoreInterface {
  // journeys?: Journey[],
  quests?: { [slug: string]: QuestInterface };
  // templates?:Quest[],
  // isSaving:boolean,
  // defaultUnhandledQuestId?:string,
  // router?:Quest
  router?: QuestInterface;
  defaultUnhandled?: QuestInterface;

  workflows: WorkflowsJourney[];
  journeys?: { [slug: string]: JourneyInterface };

  loadingAllData: boolean; // Used for node search
}

const BlankState: StoreInterface = {
  journeys: undefined,
  quests: undefined,
  defaultUnhandled: undefined,
  router: undefined,
  workflows: [],
  loadingAllData: false,
};
export class QuestsModel {
  static useState = create<StoreInterface>((set) => ({ ...BlankState }));
  static setState = QuestsModel.useState.setState;
  static get state() {
    return QuestsModel.useState.getState();
  }
  static reset() {
    QuestsModel.setState({ ...BlankState });
  }
  static isLoading = false;
  static async loadQuestsList(force?: boolean, includeData?: boolean) {
    if (QuestsModel.isLoading) {
      return;
    }
    if (!force && QuestsModel.state.quests) {
      return;
    }
    if (includeData) {
      QuestsModel.setState({ loadingAllData: true });
    }
    QuestsModel.isLoading = true;
    return fetch(`https://${Config.SERVER_HOSTNAME}/quests/${BotModel.state.bot.id}?includeData=${includeData ? "true" : "false"}`)
      .then((response) => response.json())
      .then((data) => {
        if (data.quests && data.journeys) {
          let quests: { [slug: string]: QuestInterface } = {};
          data.quests.forEach((quest: QuestInterface) => {
            quests[quest.slug] = quest;
          });
          let journeys: { [slug: string]: JourneyInterface } = {};
          data.journeys.forEach((journey: JourneyInterface) => {
            journeys[journey.slug] = journey;
          });
          QuestsModel.generateJourneys(quests, journeys);
          return true;
        }
      })
      .catch((err) => {
        warn("Error loading quests", err);
        return false;
      })
      .finally(() => {
        QuestsModel.isLoading = false;
        if (includeData) {
          QuestsModel.setState({ loadingAllData: false });
        }
      });
  }
  static async loadQuest(slug: string, force: boolean) {
    if (!force && QuestsModel.state.quests && QuestsModel.state.quests[slug]) {
      return;
    }
    const url = `https://${Config.SERVER_HOSTNAME}/quests/${BotModel.state.bot.id}/quest/${slug}`;

    return fetch(url)
      .then((response) => response.json())
      .then((quest) => {
        if (quest.slug && quest.data) {
          let quests = { ...QuestsModel.state.quests } || {};
          quests[quest.slug] = quest;
          QuestsModel.generateJourneys(quests, QuestsModel.state.journeys);
        } else {
          warn("Quest not found", quest);
        }
        return quest;
        // if (data.quests && data.journeys) {
        //   let quests: { [slug: string]: QuestInterface } = {}
        //   data.quests.forEach((quest: QuestInterface) => { quests[quest.slug] = quest })
        //   let journeys: { [slug: string]: JourneyInterface } = {}
        //   data.journeys.forEach((journey: JourneyInterface) => { journeys[journey.slug] = journey })
        //   QuestsModel.generateJourneys(quests, journeys)
        // }
      })
      .catch((err) => {
        warn("Error loading quest", err, slug);
      });
  }

  // Optionally regenerate as needed
  static regenerateJourneys(quests?: { [slug: string]: QuestInterface }, journeys?: { [slug: string]: JourneyInterface }) {
    return QuestsModel.generateJourneys(quests || QuestsModel.state.quests, journeys || QuestsModel.state.journeys);
  }

  static generateJourneys(quests: { [slug: string]: QuestInterface }, journeys: { [slug: string]: JourneyInterface }) {
    // clone the journeys
    let journeysClone = JSON.parse(JSON.stringify(journeys));
    let questClone = JSON.parse(JSON.stringify(quests));
    // loop through the journeys

    let router: QuestInterface | undefined = undefined;
    let defaultUnhandled: QuestInterface | undefined = undefined;

    let unassignedGroup: WorkflowsJourney = { journey: undefined, quests: [], isUnassignedGroup: true };
    let workflowsObj: { [journeySlug: string]: WorkflowsJourney } = {};
    // loop through journeys and create a workflow for each

    for (const slug in journeysClone) {
      const journey = journeysClone[slug];
      workflowsObj[journey.slug] = { journey: journey, quests: [] };
    }

    for (const slug in questClone) {
      const quest = questClone[slug];
      if (quest.type === QuestType.ROUTER) {
        router = quest;
      } else {
        if (quest.type === QuestType.DEFAULT_UNHANDLED) {
          defaultUnhandled = quest;
        }
        // if the quest has a journey, add it to the workflows
        if (quest.journeySlug && workflowsObj[quest.journeySlug]) {
          workflowsObj[quest.journeySlug].quests.push(quest);
        } else {
          unassignedGroup.quests.push(quest);
        }
      }
    }
    let workflows: WorkflowsJourney[] = Object.values(workflowsObj);

    // sort the workflos by order
    workflows.sort((a, b) => {
      if (a.journey && b.journey) {
        return a.journey.order - b.journey.order;
      } else if (a.journey.order) {
        return 1;
      } else if (b.journey.order) {
        return -1;
      } else {
        return 0;
      }
    });
    workflows.forEach((workflow, index) => {
      if (workflow.journey) {
        journeysClone[workflow.journey.slug].order = index;
      }
    });

    // Add the unassigned to th beginning
    if (unassignedGroup.quests.length > 0) {
      workflows.unshift(unassignedGroup);
    }

    // sort the quests in each workflow
    workflows.forEach((workflow) => {
      workflow.quests.sort((a, b) => {
        if (a.order && b.order) {
          return a.order - b.order;
        } else if (a.order) {
          return 1;
        } else if (b.order) {
          return -1;
        } else {
          return 0;
        }
      });
      // workflow.quests.forEach((quest, index) => {
      //   if (quest.order !== index) {
      //     questClone[quest.slug].order = index
      //   }
      // })
    });

    const state = {
      workflows: workflows,
      journeys: journeysClone,
      quests: questClone,
      router: router,
      defaultUnhandled: defaultUnhandled,
    };
    QuestsModel.setState(state);
    return state;
  }

  static reorderWorkflow(startIndex: number, endIndex: number) {
    const reordered = ReorderHelper.reorder(QuestsModel.state.workflows, startIndex, endIndex);

    reordered.forEach((workflow, index) => {
      if (workflow.journey) {
        reordered[index].journey.order = index;
      }
    });

    let journeysChanged = [];

    for (let i = 0; i < QuestsModel.state.workflows.length; i++) {
      if (
        QuestsModel.state.workflows[i].journey &&
        reordered[i].journey &&
        QuestsModel.state.workflows[i].journey.order !== reordered[i].journey.order
      ) {
        journeysChanged.push({ slug: reordered[i].journey.slug, order: reordered[i].journey.order });
      }
    }

    if (journeysChanged.length > 0) {
      QuestsModel.saveJourneys(journeysChanged);
    }

    QuestsModel.setState({ workflows: reordered });

    // TODO: Also save the local copy of the journeys in the obj
  }

  static reorderQuestInJourneys(source: DraggableLocation, destination: DraggableLocation) {
    let workflows = QuestsModel.state.workflows;

    const reordered = ReorderHelper.reorderQuestInJourneys(JSON.parse(JSON.stringify(workflows)), source, destination);

    let questsToUpdate = [];

    let quests = { ...QuestsModel.state.quests };

    reordered.updated.forEach((workflow) => {
      workflow.quests.forEach((quest, index) => {
        quest.order = index;
        if (!quests[quest.slug]) {
          warn("Quest not found", quest.slug, quests);
        } else {
          quests[quest.slug].order = index;
        }

        // if (quest.order !== index) {
        questsToUpdate.push({ order: index, slug: quest.slug, journeySlug: workflow.journey?.slug, name: quest.name });
        // }
      });
    });
    // questsToUpdate.forEach(quest => {

    // })
    QuestsModel.setState({ quests: quests, workflows: reordered.workflows });

    QuestsModel.saveQuests(questsToUpdate);

    // reordered.forEach((workflow, index) => {
    //   if (workflow.quests) {
    //     workflow.quests.forEach((quest, index) => {
    //       if (quest.order !== index) {

    //
    //         questsToUpdate.push({id:quest.slug, order:index})
    //       }
    //     })
    //   }
    // })
    //

    // NewQuestsModel.setState({workflows:reordered})
  }

  static saveJourneys(journeys: Partial<JourneyInterface>[]) {
    Parse.Cloud.run("updateJourneys", { botId: BotModel.state.bot.id, journeys: journeys });
  }

  static async updateQuest(quest: Partial<QuestInterface>, save: boolean = false) {
    let quests = { ...QuestsModel.state.quests };
    let current = quests[quest.slug];
    if (!current) {
      warn("Quest not found", quest.slug, quests);
      return;
    }
    for (const key in quest) {
      if (Object.prototype.hasOwnProperty.call(quest, key)) {
        const element = quest[key];
        if (element !== undefined) {
          current[key] = element;
        }
      }
    }
    quests[quest.slug] = current;
    if (save) {
      await QuestsModel.saveQuests([quest]);
    }

    return QuestsModel.regenerateJourneys(quests);
  }

  static async updateJourney(journey: Partial<JourneyInterface>, save: boolean = false) {
    let journeys = { ...QuestsModel.state.journeys };
    let current = journeys[journey.slug];
    if (!current) {
      warn("journey not found", journey.slug, journeys);
      return;
    }
    for (const key in journey) {
      if (Object.prototype.hasOwnProperty.call(journey, key)) {
        const element = journey[key];
        if (element !== undefined || key === "brainSlug") {
          current[key] = element;
        }
      }
    }
    journeys[journey.slug] = current;
    if (save) {
      await QuestsModel.saveJourneys([journey]);
    }
    QuestsModel.regenerateJourneys(undefined, journeys);
  }

  static saveQuest(quests: Partial<QuestInterface>) {
    return QuestsModel.saveQuests([quests]);
  }
  static saveQuests(quests: Partial<QuestInterface>[]) {
    return Parse.Cloud.run("updateQuests", { botId: BotModel.state.bot.id, quests: quests });
  }

  static async createQuest(quest: Partial<QuestInterface>): Promise<QuestInterface> {
    const newQuest = await Parse.Cloud.run("createQuest", { botId: BotModel.state.bot.id, quest: quest });

    let quests = { ...QuestsModel.state.quests };
    quests[newQuest.slug] = newQuest;
    QuestsModel.regenerateJourneys(quests);
    return newQuest;
  }

  static async createJourney(journey: Partial<JourneyInterface>): Promise<JourneyInterface> {
    const newJourney = await Parse.Cloud.run("createJourney", { botId: BotModel.state.bot.id, journey: journey });
    let journeys = { ...QuestsModel.state.journeys };
    for (const key in journeys) {
      if (Object.prototype.hasOwnProperty.call(journeys, key)) {
        journeys[key].order = journeys[key].order + 1;
      }
    }
    newJourney.order = 0;
    journeys[newJourney.slug] = newJourney;
    QuestsModel.regenerateJourneys(undefined, journeys);
    let allJourneysArray = Object.values(QuestsModel.state.journeys).map((s) => {
      return { slug: s.slug, order: s.order };
    });
    QuestsModel.saveJourneys(allJourneysArray);
    return newJourney;
  }
  static async deleteQuest(quest: QuestInterface, isRevert: boolean = false) {
    if (quest.inheritance === InheritanceStatus.IS_PARENT) {
      warn("Cannot delete a parent quest", quest);
      return;
    }

    let quests = { ...QuestsModel.state.quests };
    delete quests[quest.slug];
    QuestsModel.regenerateJourneys(quests);
    await Parse.Cloud.run("deleteQuest", { botId: BotModel.state.bot.id, questSlug: quest.slug });
    if (isRevert) {
      NotificationsModel.displayNotification("Quest reverted", null, null, null, null, true);
    } else {
      NotificationsModel.displayNotification("Quest deleted", null, null, null, null, true);
    }
    if (quest.inheritance === InheritanceStatus.IS_CHILD) {
      QuestsModel.loadQuestsList(true);
    }
  }

  static async deleteJourney(journey: JourneyInterface) {
    if (!journey) {
      return;
    }
    let journeys = { ...QuestsModel.state.journeys };
    delete journeys[journey.slug];
    QuestsModel.regenerateJourneys(undefined, journeys);
    await Parse.Cloud.run("deleteJourney", { botId: BotModel.state.bot.id, journeySlug: journey.slug });
    NotificationsModel.displayNotification("Journey deleted", null, null, null, null, true);
  }

  static async makeQuestChild(questToMakeChild: QuestInterface) {
    if (questToMakeChild.inheritance !== InheritanceStatus.IS_PARENT) {
      warn("Quest not a parent", questToMakeChild);
      return;
    }
    const quest = await QuestsModel.loadQuest(questToMakeChild.slug, true);

    let newQuest = { ...quest };
    delete (newQuest as any).id;
    newQuest.inheritance = InheritanceStatus.IS_CHILD;
    newQuest.botId = BotModel.state.bot.id;

    const created = await QuestsModel.createQuest(newQuest);
    await QuestsModel.loadQuestsList(true);
    return created;
  }

  static async cloneToStandAlone(questToClone: QuestInterface) {
    if (questToClone.inheritance === InheritanceStatus.NONE) {
      warn("Quest is already a standalone", questToClone);
      return;
    }
    return QuestsModel.duplicateQuest(questToClone, questToClone.name + " (clone)");

    // const quest = await QuestsModel.loadQuest(questToClone.slug, true)

    // let newQuest = { ...quest }

    // delete (newQuest as any).id
    // newQuest.name = newQuest.name + " (clone)"
    // newQuest.inheritance = InheritanceStatus.NONE
    // newQuest.botId = BotModel.state.bot.id
    // newQuest.slug = nanoid(10);

    // const created = await QuestsModel.createQuest(newQuest)

    // await QuestsModel.loadQuestsList(true)
    // return created;
  }

  static async duplicateQuest(questToClone: QuestInterface, name: string) {
    const quest = await QuestsModel.loadQuest(questToClone.slug, true);

    let newQuest = { ...quest };

    delete (newQuest as any).id;
    newQuest.name = name;
    newQuest.inheritance = InheritanceStatus.NONE;
    newQuest.botId = BotModel.state.bot.id;
    newQuest.slug = nanoid(10);

    const created = await QuestsModel.createQuest(newQuest);

    await QuestsModel.loadQuestsList(true);
    return created;
  }

  static revertToParent(quest: QuestInterface) {
    if (quest.inheritance !== InheritanceStatus.IS_CHILD) {
      warn("Quest not a child", quest);
      return;
    }
    return QuestsModel.deleteQuest(quest, true);
  }

  static nameForQuest(questSlug: Slug) {
    if (!QuestsModel.state.quests) {
      return undefined;
    }

    const quest = QuestsModel.state.quests[questSlug];
    if (!quest) {
      return undefined;
    }
    return quest.name;
  }

  static getJourney(journeyOrJourneySlug: Slug | JourneyInterface) {
    const journeys = QuestsModel.state.journeys;
    if ((journeyOrJourneySlug as JourneyInterface).slug) {
      return journeyOrJourneySlug as JourneyInterface;
    }
    return journeys && journeys[journeyOrJourneySlug as Slug];
  }
  static async addNewQuestToJourney(journey: JourneyInterface, newQuestName: string) {
    let newQuest = QuestModelHelper.newQuest(newQuestName, QuestType.NORMAL, journey.slug, InheritanceStatus.NONE, 0);
    QuestsModel.createQuest(newQuest);
    return newQuest;
  }

  // static saveJourney                      = QuestsModelJourneyMethods.saveJourney
  // static deleteJourney                    = QuestsModelJourneyMethods.deleteJourney
  // static getJourney                       = QuestsModelJourneyMethods.getJourney;
  // static nameForJourney                   = QuestsModelJourneyMethods.nameForJourney;

  // static getQuest                         = NewQuestsModelQuestMethods.getQuest;
  // static nameForQuest                     = NewQuestsModelQuestMethods.nameForQuest;
  // static deleteQuest                      = NewQuestsModelQuestMethods.deleteQuest
  // static saveQuest                        = NewQuestsModelQuestMethods.saveQuest
  // static updateQuest                      = NewQuestsModelQuestMethods.updateQuest
  // static duplicateQuest                   = QuestsModelQuestMethods.duplicateQuest

  // static duplicateMatrix                  = QuestsModelMatrixMethods.duplicateMatrix
  // static addNewMatrixQuestToJourney       = QuestsModelMatrixMethods.addNewMatrixQuestToJourney

  // static searchData(searchTerm: string) {
  //   // fetch with post
  //   return fetch(`https://${Config.SERVER_HOSTNAME}/quests/search`, {
  //     method: "POST",
  //     headers: {
  //       'Content-Type': 'application/json'
  //     },
  //     body: JSON.stringify({ questIds: Object.values(QuestsModel.state.quests).map(q=>(q as any).id), searchTerm: searchTerm })
  //   }).then(response => response.json())

  // }
}
