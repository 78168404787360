import React, { useEffect } from 'react';
import UserModel from '../../../Models/User/UserModel';
import BotList from './BotList/BotList';
import Permissions from "../../../Models/Parse/Permissions"



import './SelectBotView.scss';
import BotModel from '../../../Models/Bots/Bot.Model';

import InviteList from './BotList/InviteList';
import { Spinner } from '../../../Components/Spinner/Spinner';
// import { useNavigate } from 'react-router-dom';
export default function SelectBotView() {
  const { allPermissions, user, invites } = UserModel.useState(s => s);
  
  // const navigate = useNavigate()
  const logOutClicked = () => {
    UserModel.logout();
  }


  useEffect(() => {
    UserModel.loadInvites();
  }, [])


  const permissionsSelected = async (permissions: Permissions) => {
    await BotModel.loadBot(permissions.bot.id);
    UserModel.setPermissions(permissions);
  }

  const inviteSelected = async (permissions: Permissions) => {

  }

  return <div className="SelectBotView">
    <div className="content">
      <div className="header">
        <div className="logo"><img src="/img/logo/SwivlStudioWritten.png" alt={"swivl studio"} /></div>
        Welcome back {user.get("firstName")}!
      </div>
      {allPermissions ? <>
        {allPermissions.length ? <div className="subHeader">Select a bot:</div>  : null}
        <BotList allPermissions={allPermissions} permissionsSelected={permissionsSelected} />
      </> : <Spinner />}

      {invites?.length ? <> <br /><div className="subHeader">Pending Invites:</div>
        <InviteList allPermissions={invites} permissionsSelected={inviteSelected} />

      </> : null}
    </div>
    <div className="logout" onClick={logOutClicked}>LOG OUT</div>

  </div>

};
