import Parse from 'parse';
import {ButtonsPayloadInterface, ConvertMessageToGPTLine, EmbedConfigForBot, Message, MessageType, PromptInterface, SenderOrRecipientType, Slug, TextPayloadInterface} from '@swivl/swivl-lib'
import {create} from 'zustand'
import BotModel from '../Bots/Bot.Model';
import { warn } from '../../Helpers/Logging/Logging';
import BotUser from '../Parse/BotUser';

interface StoreInterface {
    currentBotUserId?:string
    isLoadingAutoCompleteText:boolean,

    agentAutoCompleteText:string 
    
    agentAutoCompleteTranscriptPrompt?:string

    prompts?:PromptInterface[]

}


const BlankState:StoreInterface  = {
    isLoadingAutoCompleteText:false,
    currentBotUserId:undefined,
    agentAutoCompleteText:"",
    agentAutoCompleteTranscriptPrompt:undefined,
    prompts:undefined
}

const LINES_LIMIT = 5;

// function convertMessageToGPTLine(message:Message):string {
//     if (message.type !== MessageType.Text && message.type !== MessageType.Buttons) {
//         return "";
//     }
//     let type; 
//     if (message.from && message.from.type) {
//         if (message.from.type === SenderOrRecipientType.BotUser) {
//             type = "User";
//         } else if (message.from.type === SenderOrRecipientType.Bot) {
//             type = "Bot";
//         } else if (message.from.type === SenderOrRecipientType.Agent) {
//             type = "Agent";
//         }
//     }

//     if (!type) {
//         return "";
//     }

//     if (message.type === MessageType.Text) {
//         const payload:TextPayloadInterface = message.payload as any; 
//             return type + ": " + payload.text  + "\n";
        
//     } else if (message.type === MessageType.Buttons) {
//         if (message.from && message.from.type && message.actionTaken) {
//             return type + ": " + message.actionTaken.target  + "\n";
//         }
//     }
//     return "";
// }

export class AIModel {
  static useState = create<StoreInterface>(set => ({  ...BlankState }))
  static setState = AIModel.useState.setState; 
  static get state() { return AIModel.useState.getState(); }
  static reset() {  AIModel.setState({  ...BlankState }); }



    static setCurrentBotUser(botUser?:BotUser) {
        const botUserId = botUser?.id;

        if (botUserId && botUserId !== AIModel.state.currentBotUserId) {
            
            AIModel.setState({currentBotUserId:botUserId, agentAutoCompleteText:"", agentAutoCompleteTranscriptPrompt:undefined})
        }
    
    }

  static onConversationTranscriptUpdate(items:Message[], botUserId:string)   {
    
    let output:any = items; 
    if (output.length >  LINES_LIMIT) {
        
        output = output.slice(0, LINES_LIMIT);
    }

    output.reverse();
    
    output = output.map(item => ConvertMessageToGPTLine(item)).filter(item => item.length > 0)
 

    


    if (output.length > 0) {
        
        const lastItem = output[output.length-1];
        
        if (output[output.length-1].startsWith("User:")) {
            
            const agentAutoCompleteTranscriptPrompt = output.join("");
            if (AIModel.state.agentAutoCompleteTranscriptPrompt !== agentAutoCompleteTranscriptPrompt) {
                AIModel.setState({agentAutoCompleteTranscriptPrompt})
                
                AIModel.loadAgentAutoCompleteText(agentAutoCompleteTranscriptPrompt);
                
            } else {
                
            }
        } else {
            
            if (AIModel.state.agentAutoCompleteText.length > 0) {
                
                

                AIModel.setState({agentAutoCompleteText:"", agentAutoCompleteTranscriptPrompt:undefined})
            } else {
                
            }
        }

    }  
    }

    static autoCompleteCache = new Map<string, string>();
    static loadAgentAutoCompleteText(prompt:string) {
        if (AIModel.autoCompleteCache.has(prompt)) {
            

            AIModel.setState({agentAutoCompleteText:AIModel.autoCompleteCache.get(prompt)})
            return;
        }
        AIModel.setState({isLoadingAutoCompleteText:true})
        

        Parse.Cloud.run("predictAutoCompleteText", {prompt, botId:BotModel.state.bot.id}).then((result:any) => {
        
            AIModel.autoCompleteCache.set(prompt, result);
            AIModel.setState({
                agentAutoCompleteText:result,
                isLoadingAutoCompleteText:false
            })

        }).catch((error:any) => {
            warn("Error loading agent auto complete text", error)
        })

    
        
    }

    static generateReviewReply(reviewText:string, rating:number) {
        return Parse.Cloud.run("generateReviewReply", {reviewText, rating:rating, botId:BotModel.state.bot.id})
    }



    static loadPrompts(force:boolean = false) {
        if (AIModel.state.prompts && !force) {
            return Promise.resolve(AIModel.state.prompts);
        }
        return Parse.Cloud.run("getAllPrompts", {botId:BotModel.state.bot.id}).then((prompts:PromptInterface[]) => {
        
            AIModel.setState({prompts})
            return prompts;
        }).catch((error:any) => {
            warn("Error loading prompts", error)
        })
    }
    
    static savePrompt(prompt:Partial<PromptInterface>) {
        return Parse.Cloud.run("savePrompt", {botId:BotModel.state.bot.id, prompt:prompt})
        .then((_:PromptInterface) => {
            return AIModel.loadPrompts(true);
        })
    }

    static deletePrompt(prompt:PromptInterface) {
        return Parse.Cloud.run("deletePrompt", {botId:BotModel.state.bot.id, slug:prompt.slug})
        .then((_:boolean) => {
            return AIModel.loadPrompts(true);
        })
    }
    static nameForPrompt(slug:Slug) {
        const prompt = AIModel.state.prompts?.find(item => item.slug === slug);
        return prompt?.name || "";
        
    }
}
