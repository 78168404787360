import React, { Component, useEffect } from 'react';
import Parse  from 'parse';

import NameRow from './components/NameRow/NameRow'
// import BotUserCRMView from './BotUserCRMView';


import {DateTime} from 'luxon'

import './BotUserTranscriptDetails.scss'




import { FaCheck, FaHubspot } from 'react-icons/fa';
import {RxTriangleDown, RxTriangleRight } from 'react-icons/rx' 
import { UserVars } from '@swivl/swivl-lib';
import { AnalyticsEvent } from '../../../Helpers/Analytics/AnalyticsEvent';
import AppsModel, { AppType } from '../../../Models/Apps/Apps.Model';
import { BotUserModel } from '../../../Models/BotUser/BotUser.Model';
import Bot from '../../../Models/Parse/Bot';
import BotUser from '../../../Models/Parse/BotUser';
import SubscriptionModel from '../../../Models/Subscription/Subscription.Model';
import VariablesModel from '../../../Models/Variables/Variables.Model';
import { Config } from '../../../Config/Config';
import { SaveInput } from '../../../Components/Input/SaveInput/SaveInput';

const log = require("debug")("warn")

const TOP_LEVEL_VARS:string[] = [
"Entity personName",
"Entity email",
"Entity phonenumber",
UserVars.User_Zip,
"Entity Size",
"Entity Feature",
"Entity Item",
"Entity Location",
UserVars.User_EmailType, 



] // These are the vars that are NOT hidden behind a menu
const TOP_LEVEL_VAR_NAMES = {
  "Entity personName":"Name",
  "Entity email":"Email",
  "Entity phonenumber":"Phone Number",
  [UserVars.User_Zip]:"Zip Code",
  "Entity Size":"Size",
  "Entity Feature":"Feature",
  "Entity Item":"Item",
  "Entity Location":"Location",
  [UserVars.User_EmailType]:"Email Type",
}

interface Props {
  bot: Bot;
  item:BotUser
}

export default function BotUserTranscriptDetails(props:Props) {
  const hubspot = AppsModel.useGetApp(AppType.Hubspot)
  useEffect(()=> {
    AppsModel.load(false)
  } ,[])
  return <InternalBotUserDetails {...props} hubspot={hubspot}/>
}

interface dProps extends Props { 
  hubspot?:Parse.Object 
}

export class InternalBotUserDetails extends Component<dProps, any> {

  constructor(props:dProps) {
    super(props);
    this.state = {selectedTab:"crm", showAllVars:false}
    this.crmClicked       = this.crmClicked.bind(this);
    this.variablesClicked = this.variablesClicked.bind(this);
    this.saveVariableForUser =  this.saveVariableForUser.bind(this)
    this.renderUserVariables = this.renderUserVariables.bind(this);
    this.syncWithCRM = this.syncWithCRM.bind(this)
  }

    syncWithCRM() {
      var self = this;

      this.setState({syncing:true})

      Parse.Cloud.run('syncBotUserToHubspotContact', {botUserId:this.props.item.id, appId:this.props.hubspot.id})
      .then(function(response) {
        self.setState({syncing:false, synced:true});
        setTimeout(function() { self.setState({syncing:false, synced:false}); },2000);
      }).catch((error) => {
        self.setState({syncing:false})

        console.error("There was an error", error);
      });
    }


  crmClicked() {
    AnalyticsEvent("Live", "Details Tab Selected", "CRM");
    this.setState({selectedTab:"crm"});
  }
  variablesClicked() {
    AnalyticsEvent("Live", "Details Tab Selected", "Variables");
    this.setState({selectedTab:"variables"});
  }

  saveVariableForUser(variableId, value, callback) {
    log("saveVariableForUser",variableId, value, callback)
    var item = this.props.item;
    var  userSavedVariables = item.get("userVariables");
    if (!userSavedVariables) { userSavedVariables = {}; }
    userSavedVariables[variableId] = value;
    item.set("userVariables",userSavedVariables);
    BotUserModel.updateUser(item, true);
    callback()
  }

  renderUserVariables() {
    let topLevelFields = [];
    let variableFields = [];

    let botUserVariables = {...VariablesModel.variablesAndEntityVariables(true, true, null,false,true)} || {}

    

    if (botUserVariables) {

    const  userSavedVariables = this.props.item.get("userVariables");

    TOP_LEVEL_VARS.forEach((key) => {
      var value = (userSavedVariables && userSavedVariables[key]) ? userSavedVariables[key]  : "";
                topLevelFields.push(
                <SaveInput
          inputClassName=' white '
          readOnly={true}
                              placeholder=''
                              title={TOP_LEVEL_VAR_NAMES[key]}
                              key={key}
                              name={key}
                              value={value}
                              parsePhoneNumber={key === "Entity phonenumber" ? true : false}
                              save={this.saveVariableForUser}
                              />)

                });

    for (var key in botUserVariables) { // this is an array dummy

      if (botUserVariables.hasOwnProperty(key)) {
        var value = (userSavedVariables && userSavedVariables[botUserVariables[key].id]) ? userSavedVariables[botUserVariables[key].id]  : "";
        if (botUserVariables[key].name.includes("Hidden*")) { continue; }

        if (TOP_LEVEL_VARS.includes(botUserVariables[key].id)) {

          // topLevelFields.push(<SaveInput
          // inputClassName=' white '
          //                     placeholder=''
          //                     title={botUserVariables[key].name}
          //                     key={botUserVariables[key].id}
          //                     name={botUserVariables[key].id}
          //                     value={value}
          //                     parsePhoneNumber={botUserVariables[key].id === "Entity phonenumber" ? true : false}
          //                     save={this.saveVariableForUser}
          //                     />)
        } else if (this.state.showAllVars) {

        variableFields.push(<SaveInput
          readOnly={true}

          inputClassName=' white '
                              placeholder=''
                              title={botUserVariables[key].name}
                              key={botUserVariables[key].id}
                              name={botUserVariables[key].id}
                              value={value}
                              parsePhoneNumber={botUserVariables[key].id === "Entity phonenumber" ? true : false}
                              save={this.saveVariableForUser}
                              />)

        }
      }
    }
  }

    return <div key={"uv_" + this.props.item.id} className="botUserVariables">

      <div className="topLevelVariables">
        {topLevelFields}
      </div>
      <div className="variableFields">
        <div className="variableFieldsHeader" onClick={() => {    this.setState({showAllVars:!this.state.showAllVars})   }}>
          {this.state.showAllVars ? <RxTriangleDown /> : <RxTriangleRight/>}
          <div className="variableFieldsTitle">Other Variables</div>
      </div>
      {variableFields}
      </div>
      </div>


  }

  
  renderDownloadTranscript()   {
     if (this.props.bot && this.props.bot.get("apps") && this.props.bot.get("apps").includes("transcript")) {
    
      return <a href={"https" + Config.SERVER_HOSTNAME + "/get-single-transcript/" + this.props.item.id} target="_blank" className="hubspotButton notConnected" rel="noreferrer" >Download Transcript</a>
    } 
    
  }


  renderURL() {
    const user = this.props.item; 
    if (user.get("address")) {
      const address = user.get("address") 
      if (address.hostname && address.pathname) {
        return <div className="address">
          <label>URL</label>
          <div>{address.hostname}</div>
          <label>CURRENT PAGE</label>
          <div>{address.pathname}</div>
        </div>
      }
    }
    return <div className="address">
    <label>URL</label>
    <div>Unknown</div>
  </div>
  }

  render () {
    if (!this.props.item) { return <div className="botUserDetails"></div>; }
      const item = this.props.item;
      var  createdAt, lastInteraction;
      var createdAtMoment = DateTime.fromJSDate(item.get("createdAt"));
        createdAt = createdAtMoment.toLocaleString(DateTime.DATETIME_MED);

      if (this.props.item.get("lastInteraction")) {
        var lastInteractionMoment = DateTime.fromJSDate(item.get("lastInteraction"));
        lastInteraction = lastInteractionMoment.toLocaleString(DateTime.DATETIME_MED);
      } else {
        lastInteraction = "Unknown";
      }

      var variablesList;
      if (!this.props.item) {
        variablesList = <div key="noUser" className="botUserVariables"></div>
      } else  if (this.props.bot && this.props.bot.get("apps") && this.props.bot.get("apps").includes("hubspot")) {

      } else {

        variablesList = this.renderUserVariables()


      }



    return <div className="BotUserTranscriptDetails">
      <NameRow item={this.props.item}  />
      <div className="botUserDetailsRow">
        <label>FIRST INTERACTION</label>
        <div>{createdAt}</div>
      </div>
      <div className="botUserDetailsRow">
        <label>LAST INTERACTION</label>
       <div> {lastInteraction}</div>
      </div>
      {this.renderURL()}
      {this.renderDownloadTranscript()}

      {variablesList}


      </div>





  }
}
