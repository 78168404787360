
import React from 'react';
import { useParams } from 'react-router-dom';
import ImageUploader from '../../../Components/ImageUploader/ImageUploader';

import { AgentModel } from '../../../Models/Agent/Agent.Model';
import { NavBar } from '../../../Models/NavBar/NavBar.Model';
// import * as ReactDOM from 'react-dom';
// import ImageUploader from '../../../Components/ImageUploader/ImageUploader';
// import { Button } from '../../../components/Button/Button';
// import SSInput from '../../../components/SSInput/SSInput';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// import { AgentModel } from '../../../Models/Agent/Agent.Model';

import "./AgentSettingsView.scss" 
import { Button } from '../../../Components/Button/Button';
import { Spinner } from '../../../Components/Spinner/Spinner';
import { Input } from '../../../Components/Input/Input';



export default function SlackAgentSettingsView() {
    const [state, setState] = React.useState<any>({agent:undefined, avatarURL: "", isLoaded: false, name:"",isCreate:false,  justUpdated:false,  errorMsg:undefined});

    // mounted = false; 
    const {botId, slackUserId} = useParams()
    

    

    React.useEffect(() => {


        if (!botId || !slackUserId) { return; }


        NavBar.set(   
            <NavBar.Component.Back />, 
            <NavBar.Component.Title title="Slack Agent Settings" />, 
            null, 
            "Slack Agent Settings");
        


       AgentModel.getSlackAgent(slackUserId, botId).then((agent) => {
           if (agent) {
            setState({...state, agent:agent, name:agent.name, avatarURL:agent.avatarURL, isCreate:false, isLoaded:true})

           } else {
            setState({...state, agent:undefined, isCreate:true, isLoaded:true})

           }
       }).catch(e => {
           alert("There was an error loading your slack agent.")
           
       })

    }
    ,[]);

    
    const onChange = (e) => {
        setState({...state, name:e.target.value, justUpdated:false,  errorMsg:undefined})
    }
    const setBase64Data = (base64) => {
        setState({...state, base64:base64, justUpdated:false, errorMsg:undefined})
    }
    const saveClicked = () => {
        const {base64, name, avatarURL, errorMsg} = state 

        if (!name || name.length === 0) {
            setState({...state, errorMsg:"You must enter a display name"});
            return;
        }
        setState({...state, isSaving:true, errorMsg:undefined})
        // setState({isSaving:false, justUpdated:true})
        // setTimeout(() => {
        //     if (mounted) { setState({justUpdated: false})}
        // }, 2000)

        createOrUpdateAgent()
    }

    // const doneClicked = () => {
    //     props.toggleShowAgentSettings()
    // }

    const createOrUpdateAgent = () => {
        
        
        const {base64, name, isCreate } = state 

        if (!name) { return; }
        
        if (isCreate) {

            AgentModel.createAgent(name, base64, "slack", slackUserId, botId).then((agent) => {
                setState({...state, hasSaved:true})
            })
        } else {
            AgentModel.updateAgent(name, base64, "slack",  slackUserId, botId).then((agent) => {
                setState({...state, hasSaved:true})

            })
        }

        
    
      }


      if  (!state.isLoaded){  return <div className="loadingView"><Spinner  /></div>;}
    
    
    

        
        const {isCreate, base64, name, avatarURL, errorMsg ,isSaving,justUpdated, hasSaved} = state 

        if (hasSaved) {
            const copy = (isCreate) ? "Please let your administrator know that you're escalation agent is now ready to be assigned to a group." : "Your agent has been updated."
            return <div className="AgentSettingsView">

            <div className="content">


            <div className="agentSettingsForm">
                <h1>👍</h1>
            <h3>Looks good! </h3>
            {copy}
            <br/>
            You may now close this window.

            </div>
            </div>
                </div>
            


        }

        const errorView = (errorMsg) ? <div className="errorMsg">{errorMsg}</div> : null;
        const justUpdatedOrSave = (justUpdated) ? <div className="justUpdatedView">Saved!</div> : <Button isLoading={isSaving} isRound  style={Button.Style.Orange} onClick={saveClicked}>Save</Button> //  <Button loading={isSaving} isRound  color={"orange"} onClick={saveClicked}>Save</Button>
        ;

        const cancelButton = (isCreate) ? null : null // <Button onClick={doneClicked}>Done</Button>

        let createHeader, step1, step2; 

        if (isCreate) {
            createHeader = <div className="steps">
                <div>
                <h3>Before we begin</h3>
                We need set up your agent profile. This is what users will see when you takeover a conversation from your bot.
                </div>
            </div>
        }

        return <div className="AgentSettingsView">

            <div className="content">
            {createHeader}

            <div className="agentSettingsForm">

                <div className="avatar">
                    <ImageUploader 
                        imageURL={avatarURL}
                        base64={base64}
                        setBase64Data={setBase64Data} 
                        />
                </div>
                <label>Display Name</label>
                <small>This will appear for users you chat with.</small>
                <Input placeholder="Enter display name..." value={name} name="name" onChange={onChange} />

                {errorView}
                {justUpdatedOrSave}
                {cancelButton}
                </div>
                </div>
            


        </div>
}

