import React from 'react';
import * as ReactDOM from 'react-dom';

import { createRoot } from 'react-dom/client';

import App from './App/App';
import Parse from 'parse'


import "./Styles/Defaults.scss";
import { Config } from './Config/Config';
import {  QueryClientProvider, QueryClient } from '@tanstack/react-query'
// import { ReactQueryDevtools } from '@tanstack/react-query-devtools'



localStorage.debug = 'log,error,warn,' + localStorage.customDebug;



  Parse.initialize(Config.APP_ID, 'ZakaqpiufBBWfeperq6Kbfzz');
  Parse.serverURL = "https://"+Config.SERVER_HOSTNAME + "/parse";  

  
  const queryClient = new QueryClient()


// After
const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(<QueryClientProvider contextSharing={true} client={queryClient}>
          {/* <ReactQueryDevtools initialIsOpen={false} /> */}
  {/* // <React.StrictMode> */}
    <App />
  {/* // </React.StrictMode> */}
  </QueryClientProvider>);



// ReactDOM.render(
//   <QueryClientProvider contextSharing={true} client={queryClient}>
//           {/* <ReactQueryDevtools initialIsOpen={false} /> */}
//   {/* // <React.StrictMode> */}
//     <App />
//   {/* // </React.StrictMode> */}
//   </QueryClientProvider>,
//   document.getElementById('root')
// );
