
import {create} from 'zustand'

import Parse from "parse";
import NLPModel from "../NLP/NLP.Model";
import Bot from "../Parse/Bot";
import SubscriptionModel from "../Subscription/Subscription.Model";
import UserModel from "../User/UserModel";
import VariablesModel from "../Variables/Variables.Model";
import SwivlNotifications from '../Notifications/SwivlNotifications';
import { ModelController } from '../Model.Controller';
import FMSModel from '../FMS/FMS.Model';
import { InheritanceStatus } from '@swivl/swivl-lib';


interface StoreInterface {
  bot?: Bot;
  parent?: Bot;
  isLoadingBot: boolean;
}
const BlankState:StoreInterface = {
  bot: undefined,
  isLoadingBot:false 
}
export default class BotModel {

  static useState = create<StoreInterface>(set => ({  ...BlankState }))
  static setState = BotModel.useState.setState; 
  static get state() { return BotModel.useState.getState(); }
  static reset() {  BotModel.setState({  ...BlankState }); }


  static botInheritence:InheritanceStatus
  static get botIsParent() { return BotModel.botInheritence === InheritanceStatus.IS_PARENT}
  static get botIsChild() { return BotModel.botInheritence === InheritanceStatus.IS_CHILD }
  static get isVersion2() { return BotModel.botInheritence === InheritanceStatus.IS_CHILD || BotModel.botInheritence === InheritanceStatus.IS_PARENT }

  static isInherited(botId:string) { 
    if (BotModel.state.bot?.id === botId) {
      return false;
    }
    return true;
  }

  static async switchToBot(botId:string) {

    BotModel.setState({isLoadingBot:true})
    ModelController.resetModels(true);
    return BotModel.loadBot(botId, true);
  }

  static async loadBot(
    botId: string,
    forceReload: boolean = false
  ): Promise<Bot | undefined> {
    var botQuery = new Parse.Query(Bot);
    botQuery.include("userVariables");
    botQuery.include("subscription");
    botQuery.include("NLPData");
    botQuery.include("parent")

    try {
      const bot: Bot = await botQuery.get(botId);
      

      BotModel.botInheritence = bot.get("inheritance") || InheritanceStatus.NONE;
      BotModel.setState({bot:bot, isLoadingBot:forceReload ? true : false})
      
      localStorage.setItem("botId", bot.id);
      await SubscriptionModel.loadedFromBot(bot);
      await NLPModel.loadFromDB(bot)
      await VariablesModel.loadedFromBot(bot);
      SwivlNotifications.connect();
      if (forceReload) {
        window.location.reload();
      }
      return bot;
    } catch (e) {
      return undefined;
    }
  }

  static async updateBot(bot:Bot) {
    const currentBot = BotModel.state.bot;
    if (!currentBot || currentBot.id === bot.id) {
      BotModel.setState({bot:bot})
    }
  }

  
}
