import {create} from 'zustand'

interface StoreInterface {
  showModal:boolean, 
  modalWidth?:number,
  modalContent?:React.ReactNode
  shouldCloseOnOverlayClick:boolean
}

const BlankState:StoreInterface = {
  showModal:false, 
  modalWidth:undefined,
  modalContent:undefined,
  shouldCloseOnOverlayClick:true
};

export default class ModalModel {
  static useState = create<StoreInterface>(set => ({  ...BlankState }))
  static setState = ModalModel.useState.setState; 
  static get state() { return ModalModel.useState.getState(); }
  static reset() {  ModalModel.setState({  ...BlankState }); }

    static showModalWithContent(content?:React.ReactNode, modalWidth?:number, shouldCloseOnOverlayClick:boolean = true) {
        ModalModel.setState({ 
                showModal : true,
                modalWidth : modalWidth,
                modalContent : content,
                shouldCloseOnOverlayClick : shouldCloseOnOverlayClick
             })
    }
    static hideModal() {
      ModalModel.setState({ 
        showModal : false,
         })
    }
}