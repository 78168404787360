import React from "react";
import { BsArrowLeft } from "react-icons/bs";
import { Link, useNavigate,useLocation } from "react-router-dom";
import { NavBar } from "../../../../Models/NavBar/NavBar.Model";
export function NavBarBack (props:{title?:string, to?:string}) {
    const { title, to } = props;
    const navigate = useNavigate();
    const location = useLocation();
    let backTitle;
    if (title) {
        backTitle = title;
    } else if (location.state && ( location.state as any).fromTitle && typeof (location.state as any).fromTitle === "string") {
        backTitle = "BACK TO " + (( location.state as any).fromTitle as string).toUpperCase();    
    } else {
        backTitle = "BACK";
    }
    
    if (to) { return <Link className="textButton" to={to}><BsArrowLeft/> {backTitle}</Link>}
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    return <a className="textButton" onClick={()=>{ navigate(-1); }}><BsArrowLeft/> {backTitle}</a>
  }