import React from 'react';
import './MainMenu.scss';
import {AnalyticsEvent} from '../../../Helpers/Analytics/AnalyticsEvent'
import { AppModel } from '../../../Models/App/App.Model';
import UserModel from '../../../Models/User/UserModel';
import BotModel from '../../../Models/Bots/Bot.Model';
import { useSwivlNavigate } from '../../../Models/NavBar/useSwivlNavigate';
import SubscriptionModel from '../../../Models/Subscription/Subscription.Model';

export function  MainMenu()  {
  const showMainMenu = AppModel.useState(s => s.showMainMenu)
  const permissions = UserModel.useState(s => s.permissions)
  const bot = BotModel.useState(s => s.bot)

  const navigate = useSwivlNavigate()
    const dashboardClicked = () => {
      AnalyticsEvent("Main Menu", "Section", "Dashboard");
      AppModel.toggleMainMenu()
      navigate("/"); 
    }
    const conversationsClicked = () => {
      AnalyticsEvent("Main Menu", "Section", "Conversations");
      AppModel.toggleMainMenu()
      navigate("/conversations")
    }
    const  trainingClicked = () => {
      AnalyticsEvent("Main Menu", "Section", "Interactions");
      AppModel.toggleMainMenu()
      navigate("/interactions/results")
    }
    const myStorageClicked = () => {
      AnalyticsEvent("Main Menu", "Section", "My Storage");
      AppModel.toggleMainMenu()
      navigate("/my-storage")
    }

    const reviewsClicked = () => {
      AnalyticsEvent("Main Menu", "Section", "Reviews");
      AppModel.toggleMainMenu()
      navigate("/reviews")
    }
    const settingsClicked = () => {
      AnalyticsEvent("Main Menu", "Section", "Settings");
      AppModel.toggleMainMenu()
      navigate("/settings/account")
    }


 

      let conversations, training, reviews, myStorage;
      let iconsCount = 2;

      if (permissions.conversations)  {
        iconsCount++;
        conversations = <div className="mainNavButton"  onClick={conversationsClicked}>
            <span className="icon"><img src='/img/main-nav/Conversations.png' alt="Conversations" width={60} height={45}/></span>
            <span className="title">Conversations</span>
          </div>;
      }
      if (permissions.training) {
        iconsCount++;
        training = <div className="mainNavButton"  onClick={trainingClicked}>
              <span className="icon"><img src='/img/main-nav/Training.png' alt="Training" width={64} height={65}/></span>
              <span className="title">Interactions</span>
            </div>;
          }
        
          if (bot.get("worksheetSettings")) {
            iconsCount++;
            myStorage =            <div className="mainNavButton"  onClick={myStorageClicked}>
            <span className="icon"><img src='/img/main-nav/MyStorage.png' alt="MyStorage" width={65} height={45}/></span>
            <span className="title">My Storage</span>
          </div>
                }
        if (permissions.reviews_manager && SubscriptionModel.showReviewManager()) {
          iconsCount++;
          reviews = <div className="mainNavButton"  onClick={reviewsClicked}>
              <span className="icon"><img src='/img/main-nav/Reviews.png' alt="Reviews" width={64} height={45}/></span>
              <span className="title">Reviews</span>
            </div>
        }
        

      // if (permissions.user_journeys) {
      //   iconsCount++;
      //   user_journeys = <div className="mainNavButton"  onClick={userJoureysClicked}>
      //               <span className="icon"><img src='/img/main-nav/UserJourneys.png' alt="Journeys" width={65} height={58}/></span>
      //               <span className="title">Workflows</span>
      //             </div>
      // }

      const modalStyle = {width:((iconsCount * 210) + 10) + "px"};

      return <>
                <div className={showMainMenu ? 'MainMenuShade isShowing' : 'MainMenuShade'} onClick={AppModel.toggleMainMenu} />

          <div className={showMainMenu ? 'MainMenu isShowing' : 'MainMenu'} style={modalStyle}>
              <div className="mainNavButton"  onClick={dashboardClicked}>
                <span className="icon"><img src='/img/main-nav/Dashboard.png' alt="Dashboard" width={60} height={45}/></span>
                <span className="title">Dashboard</span>
              </div>
              {conversations}
              {reviews}
              {training}
              {myStorage}
              {/* {user_journeys} */}
              <div className="mainNavButton" onClick={settingsClicked}>
                <span className="icon"><img src='/img/main-nav/Settings.png' alt="Settings" width={55} height={55}/></span>
                <span className="title">Settings</span>
              </div>
          </div>
          <div className="mainNavToggleButton">
            <div className={showMainMenu ? "navButton openNav" : "navButton closeNav"} onClick={AppModel.toggleMainMenu}>
              <div className="redStick"></div><div className="yellowStick"></div>
            </div>
            </div>
        </>

    
}
