import { AllTables } from "@swivl/swivl-lib";
import BotModel from "../Bots/Bot.Model";
import { DropdownItem } from "../../Components/Dropdown/Dropdown";


export class TablesModel {
    static reset() {  }
    static getTablesForBotAsOptions():DropdownItem[] {
        const bot = BotModel.state.bot;
        
        
        return Object.values(AllTables).filter(table => {
            if (!table.botId || table.botId === bot.id) {
                return true;
            }
            return false;
        }).map(table => {
            return {
                label: table.name,
                value: table.id
            }
        })
    }

    
    static tableHadRequiredFields(tableId:string):boolean {
        const table = AllTables[tableId];
        if (table) {
            const columns = Object.values(table.mapping);
            for (let i = 0; i < columns.length; i++) {
                const column = columns[i];
                if (column.isRequired) {
                    return true;
                }
            }

        }
        return false; 
    }

}