import React, { useEffect } from 'react';
import { NavBar } from '../../../Models/NavBar/NavBar.Model';
import './LoadingView.scss';
interface Props { navBarTitle?:string,  pageTitle?:string }

export const LoadingView:React.FC<Props> = (props:Props) => {
  const { navBarTitle, pageTitle } = props;
      useEffect(() => {
        if (navBarTitle || pageTitle) {
          NavBar.set(   
            null, 
            navBarTitle ? <NavBar.Component.Title title={navBarTitle}/> : null, 
            null,
            pageTitle ? pageTitle : "swivlStudio"
            );
        }
      }, [navBarTitle, pageTitle ])



    return <div className="LoadingView">
    <div className="loadingContent">
      <div className="hoverWave"><img src="/img/logo/logo-hoover-bust.png" alt="Hoover!" /></div>
      <div className="loadingLogo"><img src="/img/logo/SwivlStudioWritten.png" alt="Swivl Studio"/></div>
    <div className="loadingBar">LOADING</div>
    </div>
  </div>
  
}

export const PageLoadingView:React.FC<Props> = (props:Props) => {
  const { navBarTitle, pageTitle } = props;
      useEffect(() => {
        if (navBarTitle) {
          NavBar.setCenter(navBarTitle ? <NavBar.Component.Title title={navBarTitle}/> : null) 
        }
        if (pageTitle) {
          NavBar.setTitle(pageTitle)
        }
      }, [navBarTitle, pageTitle ])
      
    return <div className="LoadingView">
    <div className="loadingContent">
    <div className="loadingBar">LOADING</div>
    </div>
  </div>
  
}