import {
  JourneyInterface,
  JourneyType,
  NodeType,
  NormalQuestInterface,
  QuestInterface,
} from "@swivl/swivl-lib";
import { create } from "zustand";
import { QuestsModel, WorkflowsJourney } from "../Quests/Quests.Model";

export interface QuickReplyItem {
  id: string; // Quest ID
  name: string;
  message: string;
  isGreeting: boolean;
}

interface StoreInterface {
  isLoadingQuickReplies: boolean;
  quickReplies?: QuickReplyItem[];
  greeting?: QuickReplyItem;
}
const BlankState: StoreInterface = {
  isLoadingQuickReplies: false,
  quickReplies: undefined,
  greeting: undefined,
};

export class QuickReplyModel {
  static useState = create<StoreInterface>((set) => ({ ...BlankState }));
  static setState = QuickReplyModel.useState.setState;
  static get state() {
    return QuickReplyModel.useState.getState();
  }
  static reset() {
    QuickReplyModel.setState({ ...BlankState });
  }

  static async loadQuickReplies(journeys?: WorkflowsJourney[]) {
    if (!journeys || !journeys.length) {
      return;
    }
    if (QuickReplyModel.state.isLoadingQuickReplies) {
      return;
    }
    if (QuickReplyModel.state.quickReplies) {
      return;
    }
    QuickReplyModel.setState({ isLoadingQuickReplies: true });

    const quickReplyJourney = journeys.find(
      (j) => j.journey?.type === JourneyType.QUICK_REPLIES
    );

    let quests = quickReplyJourney
      ? Object.values(QuestsModel.state.quests).filter(
          (q: QuestInterface) => q.journeySlug === quickReplyJourney.journey.slug
        )
      : [];

    if (!quickReplyJourney) {
      return;
    }
    var quickReplyItems = [];
    var greeting: QuickReplyItem = undefined;
    quests.sort((a: QuestInterface, b: QuestInterface) => {
      return a.order - b.order;
    });
    quests.forEach((quest) => {
      let firstMessageNode;
      const data = (quest as NormalQuestInterface).data;

      if (data) {
        firstMessageNode = Object.values(data).find(
          (node) => node.type === NodeType.Message
        );
      }
      if (!firstMessageNode) {
        return;
      }

      const quickReplyItem: QuickReplyItem = {
        id: quest.slug,
        name: quest.name,
        message: firstMessageNode.text,
        isGreeting: quest.name.includes("Greeting"),
      };
      if (quickReplyItem.isGreeting) {
        greeting = quickReplyItem;
      }
      if (quickReplyItem.message) {
        quickReplyItems.push(quickReplyItem);
      }
    });

    QuickReplyModel.setState({
      isLoadingQuickReplies: false,
      quickReplies: quickReplyItems,
      greeting: greeting,
    });
  }
}
