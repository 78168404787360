import BotModel from "../../Bots/Bot.Model";
import Parse from "parse";

export class NLPService {

static async getNLPAppInfo() { 
  const bot = BotModel.state.bot; 
  if (!bot || !bot.get("luisAppId") || !bot.get("luisAppVersion")) { return Promise.reject(new Error('Bot not found')); }
  return   {
    nlpAppId:bot.get("luisAppId"), 
    nlpAppVersion: bot.get("luisAppVersion"), 
    botId:bot.id 
  }
}

static getEntities() {
    return NLPService.getNLPAppInfo()
    .then(function(params) {
      return Parse.Cloud.run('getNLPEntities',params)
    })
  }
  static getIntents() {
    return NLPService.getNLPAppInfo()
    .then(function(params) {
      return Parse.Cloud.run('getNLPIntents',params)
    })
  }


  static async loadNLPExamplesPaginated(params?:any, skip?:any, examplesParam?:any) {
    var examples = examplesParam || []
    if (!params) {
      params = await  NLPService.getNLPAppInfo() 
    }
    if (skip) { params.skip = skip }
    params.itemsPerPage = 500; 
    return Parse.Cloud.run('getNLPExamplesPaginated',params)
    .then((returnedExamples)=>{
      if (returnedExamples) {
        examples = examples.concat(returnedExamples)
      }
      // log("Got examples", returnedExamples,examples.length);
      if (returnedExamples && returnedExamples.length >= 500) {
        // log("Paginating");
        var numberToSkip = (skip) ? skip + 500 : 500; 
        return NLPService.loadNLPExamplesPaginated(params, numberToSkip, examples)
      }
      return examples;
    })

 }
}