import React from 'react';
import Permissions from '../../../../Models/Parse/Permissions';

import './BotList.scss';
import { Spinner } from '../../../../Components/Spinner/Spinner';
interface ItemProps {
  selectedPermissionsId?:string 
  selectingPermissionsId?:string 
  permissions:Permissions
  permissionsSelected(permissions:Permissions):void 
}
class BotListItem extends React.Component<ItemProps> {
  constructor(props:ItemProps) {
    super(props)
    this.selected = this.selected.bind(this)
  }
  selected() {
    this.props.permissionsSelected(this.props.permissions)
  }
  render() {
    var className = "BotListItem";
    var spinner, onClick;


    if (this.props.selectingPermissionsId) {

      if (this.props.selectingPermissionsId === this.props.permissions.id) {
        className += " selected";
        spinner = <div className="spinner"><Spinner /></div>;
      } else {
        className += " disabled";

      }
    } else {
      onClick = this.selected;
    }
    if (this.props.selectedPermissionsId && this.props.selectedPermissionsId === this.props.permissions.id) {
      className += " selected";
      onClick = null;
    }
    var avatarImg = (this.props.permissions.bot.get("avatar") && this.props.permissions.bot.get("avatar").url()) ?  <img src={this.props.permissions.bot.get("avatar").url()} alt="avatar" /> : null;
    return <div className={className} onClick={onClick}>
      <div className="avatar">{avatarImg}</div>
      <div className="name">{this.props.permissions.bot.get("name")}</div>
      {spinner}
    </div>
  }
}


interface Props {
  allPermissions:Permissions[]
  permissionsSelected(bot:Parse.Object):void 
}
export default class BotList extends React.Component<Props> {
  constructor(props) {
    super(props)
    this.state = {selectedPermissionsId:null, selectingPermissionsId:null}
    this.permissionsSelected = this.permissionsSelected.bind(this)
  }
  permissionsSelected(permission:Permissions) {
    this.setState({selectingPermissionsId:permission})
    this.props.permissionsSelected(permission)
  }
    render() {
      const items = this.props.allPermissions.map((item, index) => {
        return <BotListItem key={item.id} permissions={item}  {...this.state} permissionsSelected={this.permissionsSelected} />;
      });
      return <div className="BotList" >{items}</div>;
    }
};
