import {  TrainingState, LUISEntity, LUISExample, LUISIntent,stringTokenizer, NLPModelCache, CoreType  } from "@swivl/swivl-lib";
import Parse from "parse";
import {create} from 'zustand'
import { warn } from "../../Helpers/Logging/Logging";
import BotModel from "../Bots/Bot.Model";
import Bot from "../Parse/Bot";
import { NLPService } from "./API/NLP.Service";
import { Config } from "../../Config/Config";
const log = require('debug')('NLPModel');


interface StoreInterface {
    caches?:NLPModelCache[]
    cachesMap?:{[idAndDashAndVersion:string]:NLPModelCache}


    intents?:LUISIntent[] 
    entities?:LUISEntity[] 
    examples?:LUISExample[]
    NLPData?:Parse.Object
    NLPInfo?:{ nlpAppId:string, nlpAppVersion:string, botId:string   }

   trainingState:TrainingState
   nlpFailureReason?:string 
}
const BlankState:StoreInterface = {
  caches: undefined,
  cachesMap: undefined,
  intents: undefined,
  entities: undefined,
  examples: undefined,
  NLPData: undefined,
  NLPInfo:undefined ,
  trainingState: TrainingState.LOADING,
  nlpFailureReason:undefined


};
export default class NLPModel {

  static useState = create<StoreInterface>(set => ({  ...BlankState }))
  static setState = NLPModel.useState.setState; 
  static get state() { return NLPModel.useState.getState(); }
  static reset() {  NLPModel.setState({  ...BlankState }); }

  /*
  // Use this to test if we have all classes set up: 

  // * /

  // DONE static setBotNLPState(stateParam, save, failureReason) {}
  // DONE static publishApp() {}
  static getNLPAppInfo() {}
  static setNLPAppInfo(nlpAppInfo) {}
  // DONE static getTrainingStatus() { }

  // DONE static trainApplicationAndPublish() {}
  // static getIntentsAndExamples(force) {} // CAN REMOVE
  static getJustIntents() {}
  static async loadNLPExamplesPaginated(params, skip, examplesParam) {}
  static setIntentsAndExamples(intents, examples) {}
  static addNewIntent(intent) {}
  static createIntent(name) {}
  static deleteIntent(intentId) {}
  static removeIntent(intentId) {}
  static createExample(payload, source) {}
  static createExamplesMulti(payload, source, skipReload) {}
  static deleteExample(exampleId) {}
  static getEntities(force) {}
  static createEntity(name) {}
  static deleteEntity(entity) {}
  static setEntities(entities) {}
  static entitiesForIntent(intent) {}
  static createPayloadFromExample(example, intentName) {}
  static addEntityToExample(selectedTokens, entityToAdd, intent, example) {}
  static removeEntityFromExample(selectedTokens, intent, example) {}
  static enablePrebuiltEntitiesForBot(bot) {}
  static nameForPrebuiltEntity(name) {}
  static getLuisAccountUniquenessForBot(bot) {}
 // */
  static async loadFromDB(bot:Bot) { /// This method loads the parse object from the DB
  
   
    if (!bot.get("NLPData")) {
      
        let nlpData = new Parse.Object("NLPData");
        nlpData.set("bot", bot);
        bot.set("NLPData", nlpData);
        await bot.save();
    }
    
    NLPModel.setState( {
        intents  : bot.get("NLPData").get("intents"),
        entities : bot.get("NLPData").get("entities"),
        NLPData  : bot.get("NLPData"),
        NLPInfo  :  {
          nlpAppId:bot.get("luisAppId"), 
          nlpAppVersion:  bot.get("luisAppVersion"), 
          botId:bot.id 
        }})


        
    if (!bot.get("NLPData").get("intents") || !bot.get("NLPData").get("entities") ||
    !bot.get("NLPData").get("intents").length || !bot.get("NLPData").get("entities").length
    
    ) {
      NLPModel.loadAll(true);
      
    }
    }
static loadAll(force?:boolean) {
  NLPModel.loadIntents(force)
  NLPModel.loadEntities(force)
  NLPModel.loadExamples(force)
  NLPModel.getTrainingStatus()
  NLPModel.loadCaches(force)
}

static nameForCachedModel(appId:string, version:string) {
  if (!NLPModel.state.cachesMap) { return null }
  let cache = NLPModel.state.cachesMap[appId + "-" + version]
  if (!cache) { return "Unknown" }
  return cache.name

}

static loadCaches(force?:boolean) {
  if (NLPModel.state.caches && !force) { return; }
  let bot = BotModel.state.bot;
  let core = bot.get("core") || CoreType.STORAGE
  return fetch(`https://${Config.SERVER_HOSTNAME}/nlp/caches/${core}`)
  .then(response => response.json())
  .then((caches:NLPModelCache[]) => {
    let cachesMap = {}
    for (let i = 0; i < caches.length; i++) {
      cachesMap[caches[i].appId + "-" + caches[i].version] = caches[i]
    }
    NLPModel.setState({caches:caches, cachesMap:cachesMap})

    
  }).catch(err => {
    warn("Error loading caches", err);
    return false;
  })
}

static async resetNLPCacheForBot(bot:Bot) {
  if (bot.get("NLPData")) {
    let nlpDataOld = bot.get("NLPData");
    nlpDataOld.destroy();
      bot.set("NLPData", undefined);
      await bot.save();
  }
}


static getTrainingStatus() {
  log("Getting App Info - _getTrainingStatus");
  const params:any = {...NLPModel.state.NLPInfo}

  return Parse.Cloud.run('getNLPTrainingStatus', params)
  .then((status) => {
    if (status.error) {  NLPModel.setBotNLPState(TrainingState.UNTRAINED, false); return; }
    // if (publishOnSuccess) {
      var stillUpdating = false;
      var fail = false;
      var failureReason = null;
      for (var i = 0; i < status.length; i++) {
        if (status[i].details.status === "InProgress" || status[i].details.status == "Queued") { stillUpdating = true}
        if (status[i].details.status === "Fail") {
           fail = true;
           if (status[i].details.failureReason === "FewLabels") {
             failureReason = "Missing Examples";
           }
          }
      }
      if (fail) {
        if (!failureReason) { failureReason = "Try Again"; }
        NLPModel.setBotNLPState(TrainingState.FAILED, true, failureReason);
      } else if (stillUpdating){
          NLPModel.setBotNLPState(TrainingState.TRAINED, true);
      } else {
        NLPModel.setBotNLPState(TrainingState.TRAINED, true);
      }
  })
  .catch(function(error) {
    warn('Error NLP App ', error);
    if (error.code && error.code === 141) {
      NLPModel.setBotNLPState(TrainingState.UNTRAINED, true);
    }
  });
}




static trainApplicationAndPublish() {
  NLPModel.setBotNLPState(TrainingState.TRAINING, false);


  log("Getting App Info - trainApplicationAndPublish");

  const params:any = {...NLPModel.state.NLPInfo}

     return Parse.Cloud.run('trainNLPApp', params)
    .then(function() {
      NLPModel.publishApp()
  })
  .catch(function(error) {
    warn('Error NLP App ', error);
  });
}

static publishApp() {
  NLPModel.setBotNLPState(TrainingState.PUBLISHING, false)
  const params:any = {...NLPModel.state.NLPInfo}
    return Parse.Cloud.run('publishNLPApp', params)
  .then((success) => {
    NLPModel.setBotNLPState(TrainingState.PUBLISHED, true)
  }).catch((error) => {
    warn("Error Publishing App");
    NLPModel.setBotNLPState(TrainingState.FAILED, true, "Error Publishing")
  })
}

static loadIntents(force) {
    if (NLPModel.state.intents && !force) { return; }
    NLPService.getIntents().then(intents=> {
      NLPModel.setState( {
        intents  : intents
      });
      let NLPData = NLPModel.state.NLPData;
      NLPData.set("intents", intents);
      NLPData.save();
    }).catch(err=> { warn("error loading entities", err); });
}

static loadEntities(force) {
    if (NLPModel.state.entities && !force) { return; }
    NLPService.getEntities().then(entities=> {
      NLPModel.setState( {
        entities  : entities
      });    
      let NLPData = NLPModel.state.NLPData;
      NLPData.set("entities", entities);
      NLPData.save();
    }).catch(err=> { warn("error loading entities", err); });
}

static loadExamples(force:boolean) {
  log("loadExamples", force)
  if (NLPModel.state.examples && !force) { return; }
  log("Loading Examples")
  NLPService.loadNLPExamplesPaginated().then(examples=> {
    
    NLPModel.setState( {
      examples  : examples
    });  
  }).catch(err=> { warn("error loading entities", err); });
}




static async createIntent(name:string):Promise<LUISIntent> {
  if (!name) { return Promise.reject("Missing Params"); }
  log("createIntent");
  return Parse.Cloud.run('createNLPIntent',  {name:name, ...NLPModel.state.NLPInfo}  )
  .then((intent) => {
    NLPModel.addNewIntent(intent)
    return intent; 
  }).catch((error) => { warn('Error Creating Intent', error); })


}
static addNewIntent(intent:LUISIntent) {
  let intents = NLPModel.state.intents || [];
  intents.push(intent)
  intents.sort(function(a,b) {return (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0);} );
  NLPModel.setState({intents: intents});
  NLPModel.setBotNLPState(TrainingState.UNTRAINED, true)
}

static examplesForIntent(intent:LUISIntent):LUISExample[] {
  if (!NLPModel.state.examples) { return [] }
  return NLPModel.state.examples.filter(i => i.intentLabel === intent.name)
}

static entitiesForIntent(intent:LUISIntent) {
  const examples = NLPModel.examplesForIntent(intent)
  const entities = NLPModel.state.entities; 
  // if (!intent || !this.app.state.entities || !intent.examples || intent.examples.length < 1) { return null; }
  let entityLabels = [];
  for (let i = 0; i < examples.length; i++) {
    let example = examples[i];
    if (example.entityLabels && example.entityLabels.length > 0) {
      for (let e = 0; e < example.entityLabels.length; e++) {
        if (!entityLabels.includes(example.entityLabels[e].entityName)) { // Make sure it's unique!
          entityLabels.push(example.entityLabels[e].entityName)
        }
      }
    }
  }

  var _entities = [];
  for (let i = 0; i < entities.length; i++) {
    if (entityLabels.includes(entities[i].name)) {
      _entities.push(entities[i])
    }
  }
  return _entities;
}

  static deleteIntent(intentId:string) {
    if (!intentId) { return  Promise.reject("Missing Params"); }
    const params:any = {...NLPModel.state.NLPInfo, intentId:intentId}
    return Parse.Cloud.run('deleteNLPIntent',params)
      .then((data) => {
        NLPModel.removeIntent(intentId)
      }).catch((error) => {   warn('Error Deleting Intent', error); })
  }
  static removeIntent(intentId) {
    let intents = NLPModel.state.intents || [];
    for (var i = 0; i < intents.length; i++) { if(intents[i].id === intentId) { 
      

      intents.splice(i, 1);  break; }  }
    NLPModel.setState({intents:intents});
    NLPModel.setBotNLPState(TrainingState.UNPUBLISHED, true)

    let NLPData = NLPModel.state.NLPData;
    NLPData.set("intents", intents);
    NLPData.save();
  }

  


  static nameForPrebuiltEntity(entityName:string) {
    if (entityName === "personName") { return "Name"; }
    if (entityName === "phonenumber") { return "Phone Number"; }
    if (entityName === "email") { return "Email Address"; }
    return "Unknown";
  }



  static createPayloadFromExample(example, intentName) {
    const text = example.text;
    var tokenData = [];
    for (var i = 0; i < example.tokenizedText.length; i++) {
      tokenData.push({tokenizedText: example.tokenizedText[i], text:"", startCharacterIndex:null, endCharacterIndex:null, entity:false})
    }
    var currentTokenIndex = 0;
    var charIndex = 0; // This is used to signify the start of which token we're in within the text string.
    for (var i = 0; i < text.length; i++) {
       var currentToken = tokenData[currentTokenIndex];
       if (currentToken && currentToken.startCharacterIndex == null) { currentToken.startCharacterIndex = i; }
       var nextToken = (currentTokenIndex <= tokenData.length) ? tokenData[currentTokenIndex+1] : null;
       let letter = text.charAt(i);
       let tokenLetter = (currentToken) ? currentToken.tokenizedText.charAt(i - charIndex) : null;

       if (letter != tokenLetter) {
         if (nextToken && nextToken.tokenizedText.charAt(0) == letter) {
           currentToken.endCharacterIndex = currentToken.startCharacterIndex + currentToken.text.length;
           charIndex += currentToken.text.length;
           currentTokenIndex++;
           i--;
         } else {
           currentToken.text += letter;
         }
       } else {
         currentToken.text += letter;
       }
       if (i == text.length - 1 && !nextToken) {
         currentToken.endCharacterIndex = currentToken.startCharacterIndex + currentToken.text.length;
       }

       // charIndex++;
     }
     var newText = ""
     for (var i = 0; i < tokenData.length; i++) {
       newText += tokenData[i].text;
     }


     var payload = {
       text: newText,
       intentName:intentName,
       entityLabels: []
     }


     for (var i = 0; i < example.entityLabels.length; i++) {
       const startToken = tokenData[example.entityLabels[i].startTokenIndex]
       const endToken = tokenData[example.entityLabels[i].endTokenIndex]
       var endIndex = endToken.endCharacterIndex;
       const lastChar = newText.charAt(endIndex - 1);
       if (lastChar === ' ' ||
           lastChar === '.' ||
           lastChar === '-' ||
           lastChar === '?' ||
           lastChar === '!' ||
           lastChar === '-'
       ) {
         endIndex -= 1; // If the last character is a space, back it up!
       }

       payload.entityLabels.push({entityName:example.entityLabels[i].entityName, startCharIndex: startToken.startCharacterIndex, endCharIndex:endIndex-1})

     }

     return payload;
  }
  static addEntityToExample(selectedTokens, entityToAdd, intent, example) {
    selectedTokens.sort(function(a, b) {return a - b;})


    // const entityName = (entityToAdd.typeId && entityToAdd.typeId == 2) ? "prebuilt." + entityToAdd.name : entityToAdd.name;
    const label = {entityName: entityToAdd.name, startTokenIndex: selectedTokens[0], endTokenIndex: selectedTokens[selectedTokens.length - 1]};
    example.entityLabels.push(label);

    const payload = NLPModel.createPayloadFromExample(example, intent.name)

    return NLPModel.createExample(payload)
  }


  static removeEntityFromExample(selectedTokens, intent, example) {
    selectedTokens.sort(function(a, b) {return a - b;})

    for (var i = 0; i < example.entityLabels.length; i++) {
      if (example.entityLabels[i].startTokenIndex === selectedTokens[0]) {
        example.entityLabels.splice(i, 1)
        break;
      }
    }
    const payload = this.createPayloadFromExample(example, intent.name)

    return NLPModel.createExample(payload)
 }

 static createExample(payload:{  text: string ,intentName:string, entityLabels: any[]}, source?:string) {

  
    let params:any = {...NLPModel.state.NLPInfo}
    params.payload = payload;
    params.source =  (source) ? source : "studio"
    
    return Parse.Cloud.run('createNLPExample',params).then((exampleReturnData) => {
        
        let examples = [...NLPModel.state.examples] || []
        var isLoading = false; 
        // // endCharIndex: 27
        // // entityName: "drink type"
        // // startCharIndex: 22
        // endTokenIndex: 0
        // entityName: "food type"
        // role: null
        // roleId: null
        // startTokenIndex: 0

        if (exampleReturnData.ExampleId &&  exampleReturnData.UtteranceText ) {
          isLoading = payload.entityLabels && payload.entityLabels.length > 0;
          const newExample = {
            id: exampleReturnData.ExampleId,
            text: payload.text,
            intentLabel: payload.intentName, 
            tokenizedText: stringTokenizer(exampleReturnData.UtteranceText),
            entityLabels:  [], // We aren't putting these in so we can track state change
            intentPredictions:[],
            entityPredictions: [],
            isLoading: isLoading,
          }
          let foundExample = false; 
          for (let index = 0; index < examples.length; index++) {
            if (examples[index].id === exampleReturnData.ExampleId) {
              examples[index] = newExample; 
              foundExample = true; 
              
            }
          }
          if (!foundExample) {
            examples.unshift({
              id: exampleReturnData.ExampleId,
              text: payload.text,
              intentLabel: payload.intentName, 
              tokenizedText: stringTokenizer(exampleReturnData.UtteranceText),
              entityLabels:  [],
              intentPredictions:[],
              entityPredictions: [],
              isLoading: isLoading,

            } as any)
          }
        }
        NLPModel.setState({examples:examples})
        if (isLoading) { // We had to add a new entity so lets reload the examples
          NLPModel.loadExamples(true)

        }

  })
  }




  static createEntity(name) {

    
    let params:any = {...NLPModel.state.NLPInfo} 

      params.name = name;
    return Parse.Cloud.run('createNLPEntity',params)
    .then(function(entity) {
      let entities = NLPModel.state.entities || [];
      entities.push(entity)
      NLPModel.setState({entities:entities});

      NLPModel.setBotNLPState(TrainingState.UNTRAINED, true)
      return entity; 
    }).catch(function(error) {
      warn('Error Loading Entities', error);
    })
  }

  static deleteEntity(entity) {
    if (!entity || !entity.id) {
      warn("No Entity Id");
      return Promise.reject("No Entity Id");
    }

    var entities = NLPModel.state.entities;
    for (var i = 0; i < entities.length; i++) {

      if(entities[i].id === entity.id) {
        entities.splice(i,1);
        break;
      }
    }
    NLPModel.setState({entities:entities});

    let NLPData = NLPModel.state.NLPData;
    NLPData.set("entities", entities);
    NLPData.save();


    let params:any = {...NLPModel.state.NLPInfo} 

      params.entityId = entity.id;
      return window.Parse.Cloud.run('deleteNLPEntity',params)
      .catch(function(error) {
      alert("There was an error deleting this entity. Please refresh.");
      warn('Error Loading Entities', error);

    })
  }


  static setBotNLPState(stateParam:TrainingState, save?:boolean, failureReason?:string) {
    var state;
    let bot  = BotModel.state.bot; 
    
    if (!stateParam) { state = TrainingState.DIRTY } else { state = stateParam}
    bot.set("NLPTrainingState", state)
    if (stateParam === TrainingState.TRAINED || stateParam === TrainingState.PUBLISHED) { 
     bot.set("hasTrainedNLP", true)
    }

    if (failureReason) {
      NLPModel.setState({trainingState:state, nlpFailureReason:failureReason})
    } else {
      NLPModel.setState({trainingState:state, nlpFailureReason:undefined})

    }
    if (save) { BotModel.updateBot(bot) }
  }


  static getLuisAccountUniquenessForBot(bot) {
    var BotClass = Parse.Object.extend("Bot");
    var botQuery = new Parse.Query(BotClass);
    botQuery.limit(10000);
    botQuery.equalTo("luisAppId", bot.get("luisAppId"));
    return botQuery.find().then((bots) => {
      if (bots && bots.length == 1)  { return Promise.resolve("unique"); }
      return Promise.resolve("shared");
      })
  }



  static createExamplesMulti(payload) {

    
    let params:any = {...NLPModel.state.NLPInfo} 
    params.payload = payload;
    params.source = "studio" 
    

    return Parse.Cloud.run('createNLPExamples',params)
    .then((exampleReturnData) => {
      
      NLPModel.setBotNLPState(TrainingState.UNTRAINED, true)
        return NLPModel.loadExamples(true)
    })
  }

}



