import Parse from 'parse';
import {BotInterface, LUISEntity, SenderOrRecipient, SenderOrRecipientType ,InheritanceStatus, UserVars} from '@swivl/swivl-lib';




export default class Bot extends Parse.Object {
    constructor() { super('Bot'); }


    get name(): string { return this.get("name"); }
    set name(newName: string) { this.set("name", newName) }

    get inherhitance(): InheritanceStatus { return this.get("inheritance"); }
    get isChild(): boolean { return this.get("inheritance") === InheritanceStatus.IS_CHILD; }
    get isParent(): boolean { return this.get("inheritance") === InheritanceStatus.IS_PARENT; }


    get hasEscalations():boolean { return this.get("hasEscalations"); }


    senderOrRecipient():SenderOrRecipient {
      return {
              id:this.id,
              type:SenderOrRecipientType.Bot,
              name:this.get("name"),
              avatar:(this.get("avatar") && this.get("avatar").url()) ?  this.get("avatar").url() : "https://swivl-cdn.s3-us-west-1.amazonaws.com/avatar-generic.png",
          }
    }

    static query():Parse.Query {
      let query = new Parse.Query('Bot');
      query.include("userVariables");
      return query;
    }
    static getBotByBotId(botId:string):Promise<Bot> {
      let query =  Bot.query();
      return query.get(botId) as Promise<Bot>;
    }
    static getBotPointer(botOrBotId:Bot|string): Bot {
      if (botOrBotId instanceof Bot) { return botOrBotId; }
      let bot = new Bot();
      bot.id = botOrBotId;
      return bot;
    }
    static getId(botOrBotId:Bot|string): string {
      if (botOrBotId instanceof Bot) { return botOrBotId.id; }
      if (typeof botOrBotId === "string") { return botOrBotId; }
      return null;
    }


    modelJSON(entities?:LUISEntity[]):BotInterface {
 
      const avatar = (this.get("avatar") && this.get("avatar").url()) ?  this.get("avatar").url() : "https://swivl-cdn.s3-us-west-1.amazonaws.com/avatar-generic.png";

      const userVars = (this.get("userVariables") && this.get("userVariables").get("variables")) ? this.get("userVariables").get("variables") : {}
      const botUserDisplayNameVariableId = UserVars.User_Name 
      if (entities) {

        for (var i = 0; i < entities.length; i++) {
          var name = entities[i].name;
          if (true) { if (name === "email") { name = "Email"} else if (name === "personName") { name = "Person Name"} else if (name === "phonenumber") { name = "Phone Number"}}
          userVars["Entity " + entities[i].name] = {
            id: "Entity " + entities[i].name,
            name: name,
            type: "entity",
            isEntity:true 
          }

        }
      }
      
      return {
        id:this.id, 
        name:this.get("name"),
        avatar:avatar,
        variables:userVars,
        userDisplayNameVariableId:botUserDisplayNameVariableId
      }
    }
}

Parse.Object.registerSubclass('Bot', Bot);
