import { AgentInterface } from "@swivl/swivl-lib";
import {create} from 'zustand'

import SwivlNotifications from "../Notifications/SwivlNotifications";
import UserModel from "../User/UserModel";
import Parse from 'parse'
import BotModel from "../Bots/Bot.Model";
import { warn } from "../../Helpers/Logging/Logging";
import EscalationsModel from "../Escalations/Escalations.Model";

const log = require('debug')("AgentModel");
type AgentAvailability = { [agentId: string]: {active:boolean, lastSeen:Date} }

interface StoreInterface {
    agent?:AgentInterface 
    isLoaded:boolean
    isAvailable:boolean
    allAgents:{[agentId:string]:AgentInterface}
    agentsOnline:AgentAvailability
}
const BlankState:StoreInterface  = {
  agent:undefined,
  isLoaded:false,
  allAgents:{},
  isAvailable: false, // localStorage.getItem("swivl_available") === "true"
  agentsOnline:{}
}



export class AgentModel {



  static useState = create<StoreInterface>(set => ({  ...BlankState }))
  static setState = AgentModel.useState.setState; 
  static get state() { return AgentModel.useState.getState(); }
  static reset() {  AgentModel.setState({  ...BlankState }); }


  static interval = setInterval(() => {  AgentModel.sendAvailabilityIfNeeded(); }, 2000)

  private static _agentsOnline:AgentAvailability = {}
  private static _shouldUpdateAgentsOnline = false;


  static sendAvailabilityIfNeeded() {
    if (AgentModel.state.isLoaded && BotModel.state.bot && AgentModel.state.agent ) {
      SwivlNotifications.sendAgentAvailability( BotModel.state.bot.id, AgentModel.state.agent.id, AgentModel.state.isAvailable)
    }
  }
  static setAvailability(available:boolean) {
    log("$$$ setAvailability", available);
    // localStorage.setItem('swivl_available', available ? 'true' : 'false')
    AgentModel.setState({isAvailable : available }); 
    if (AgentModel.state.agent && BotModel.state.bot) {
      SwivlNotifications.sendAgentAvailability( BotModel.state.bot.id, AgentModel.state.agent.id, available)
    }
  }

  static async loadAllAgents() {
    
    if (!BotModel.state.bot) {
      warn("No Bot!");
      return;
     }
    const agents = await Parse.Cloud.run("getAllAgents", {botId:BotModel.state.bot.id})
    log("Has Agents?", agents);
    if (agents) {
      let allAgents = {}
      agents.forEach( (agent:AgentInterface) => { allAgents[agent.id] = agent; })
      AgentModel.setState({
        allAgents : allAgents,
    });
    }
  }

  static async getAgent():Promise<AgentInterface> {
    if (AgentModel.state.agent) {
        log("Has Agent!", AgentModel.state.agent);
        return AgentModel.state.agent; 
    }
    if (!BotModel.state.bot) {
      
      warn("No Bot!");
      
      return; }

    try {
    const agent = await Parse.Cloud.run("getAgent", {botId:BotModel.state.bot.id, platform:"swivl", platformId:UserModel.state.user.id })
    log("Has Agent?", agent);

    if (typeof agent === 'string' && agent === "no_agent") {
        log("$$$ SETTING NO AGENT!");
        AgentModel.setState({
          agent : undefined,
          isLoaded : true
        }); 
        return undefined; 
    } else if (agent.id) {
      AgentModel.setState({
        agent : agent,
        isLoaded : true
      }); 
        return agent;
    }
} catch(e) {
    warn(e);
}
}
  

static async getSlackAgent(slackUserId, botId) {
  const agent = await Parse.Cloud.run("getAgent", {botId:botId, platform:"slack", platformId:slackUserId})
  

  if (typeof agent === 'string' && agent === "no_agent") {
    
      return undefined; 
  }
  return agent; 
}



static async createAgent(name:any, avatarBase64:any, platformParam?:any, platformIdParam?:any, botIdParam?:any) {
  const platform = platformParam || "swivl";
  const botId = botIdParam || BotModel.state.bot.id;
  const platformId = platformIdParam || UserModel.state.user.id

  const agent = await Parse.Cloud.run("createAgent", {botId:botId, platform:platform, platformId: platformId , name:name, avatarBase64:avatarBase64 })
  if (agent.id) {
    AgentModel.setState({
      agent : agent,
      isLoaded : true
    }); 
      EscalationsModel.loadEscalations()

      return agent;
  }

}
static async updateAgent(name:any, avatarBase64:any,  platformParam?:any, platformIdParam?:any, botIdParam?:any) {
  const platform = platformParam || "swivl";
  const botId = botIdParam || BotModel.state.bot.id;
  const platformId = platformIdParam || UserModel.state.user.id
  try {
    const agent = await Parse.Cloud.run("updateAgent", {botId:botId, platform:platform, platformId: platformId , name:name, avatarBase64:avatarBase64 })
    if (agent.id) {
      AgentModel.setState({
        agent : agent,
        isLoaded : true
      }); 
        return agent;
    }
  } catch(e) {
    warn("Updating agent error: " , e);
    return Promise.reject(e);
  }

}

  static setShouldUpdateAgentsOnline(shouldUpdate:boolean) {
    AgentModel._shouldUpdateAgentsOnline = shouldUpdate;
    if (shouldUpdate) {
      AgentModel.setState({agentsOnline:AgentModel._agentsOnline})
    }
  }
  static async agentsOnlineUpdated(agentsOnlineUpdate:AgentAvailability) {
    AgentModel._agentsOnline = agentsOnlineUpdate;
    
    if (AgentModel._shouldUpdateAgentsOnline) {
      AgentModel.setState({agentsOnline:agentsOnlineUpdate})
    }
  }
}
