const log = require("debug")("AnalyticsEvent")
function AnalyticsEvent(category:string, action:string, label?:string, value?:string) {
   log("Event:", category,",", action,",", label, ",", value);
   var payload:any = { 'event_category': category };
   if (label) { payload.event_label  = label; }
   if (value) { payload.value  = value; }
   (window as any).gtag('event', action, payload);
 };
 (window as any).AnalyticsEvent = AnalyticsEvent; 

 export {AnalyticsEvent}