import * as React from 'react';
import "./SSSwitch.scss";
export default class SSSwitch extends React.Component {
  constructor(props) {
    super(props)
    this.onClick = this.onClick.bind(this)
  }
  onClick() {
    if (this.props.onChange) {
      const event = {target:{value:!this.props.on, checked:!this.props.on, type:'checkbox', name:this.props.name }}
      if (this.props.analytics && this.props.analytics.category) {
        if (!this.props.on &&  this.props.analytics.onAction) {    window.AnalyticsEvent(this.props.analytics.category,  this.props.analytics.onAction);
        } else if (this.props.on &&  this.props.analytics.offAction) { window.AnalyticsEvent(this.props.analytics.category,  this.props.analytics.offAction); }
      }

      this.props.onChange(event)
    }
  }
  render() {
    const className = (this.props.on) ? "ssSwitch on": "ssSwitch";
    return <div className={className} onClick={this.onClick}>
            <div className="indicator"></div>
            <div className="onLabel">ON</div>
            <div className="offLabel">OFF</div>
          </div>;
   }
};
