const Orange = {
  "botId": "",
  "language": "en",
  "startOnQuest": null,
  "position": "bottom-right",
  "startConversationClosed": false,
  "startOpen": false,
  "loadPastMessages": false,
  "avatarURL": "https://swivl-uploads-production.s3.amazonaws.com/54f85f16d7d3583e4dc880367ff9fdae_avatar.png",
  "showAvatarInHeader": false,
  "showAvatarWithTimestamp": true,
  "headerText": "Questions?",
  "dotText":"Questions?",
  "linkTarget": "_blank",
  "primaryFont": "'Poppins', sans-serif;",
  "secondaryFont": "'Poppins', sans-serif;",
  "loadPastMessagesColor": "#DAD3D0",
  "loadPastMessagesHoverColor": "#C3BCB9",
  "loadPastMessagesActiveColor": "#A8A4A2",
  "loadPastMessagesSpinnerFill": "#f67902",
  "headerBackground": "#ffffff",
  "headerColor": "#000000",
  "bodyBackground": "#FFFFFF",
  "userBubbleBackground": "#ECECEC",
  "userBubbleColor": "#000000",
  "botBubbleBackground": "#ECECEC",
  "botBubbleColor": "#000000",
  "timestampColor": "#949494",
  "formBackground": "#FFFFFF",
  "formColor": "#000000",
  "linkColor": "#f67902",
  "linkHoverColor": "#e92200",
  "sendButtonBackground": "#f67902",
  "sendButtonColor": "#FFFFFF",
  "sendButtonHoverBackground": "#e92200",
  "sendButtonHoverColor": "#FFFFFF",
  "sendButtonActiveBackground": "#F67902",
  "sendButtonActiveColor": "#FFFFFF",
  "primaryButtonBackground": "#f67902",
  "primaryButtonColor": "#FFFFFF",
  "primaryButtonHoverBackground": "#e92200",
  "primaryButtonHoverColor": "#FFFFFF",
  "primaryButtonActiveBackground": "#F67902",
  "primaryButtonActiveColor": "#FFFFFF",
  "secondaryButtonBackground": "#f67902",
  "secondaryButtonColor": "#FFFFFF",
  "secondaryButtonHoverBackground": "#e92200",
  "secondaryButtonHoverColor": "#FFFFFF",
  "secondaryButtonActiveBackground": "#F67902",
  "secondaryButtonActiveColor": "#FFFFFF",
  "tertiaryButtonBackground": "#f67902",
  "tertiaryButtonColor": "#FFFFFF",
  "tertiaryButtonHoverBackground": "#e92200",
  "tertiaryButtonHoverColor": "#FFFFFF",
  "tertiaryButtonActiveBackground": "#F67902",
  "tertiaryButtonActiveColor": "#FFFFFF",
  "showAvatar": true,
  "collapsedStyle": "dot"
};
export default Orange;
