import Parse from "parse";
import { create } from "zustand";
import { Config } from "../../Config/Config";
import UserModel from "../User/UserModel";

const log = require("debug")("subscriptions:model");

const SubscriptionPermissions = {
  legacy: {
    id: "legacy",
    name: "Legacy Plan",
    training: true,
    brain_tiers: true,
    max_users: 1,
    advanced_embed: true,
  },
  free: {
    id: "free",
    name: "Trial Plan",
    training: false,
    brain_tiers: false,
    max_users: 1,
    advanced_embed: false,
  },
  founder_plan: {
    id: "founder_plan",
    name: "Founder Plan",
    training: true,
    brain_tiers: false,
    max_users: 1,
    advanced_embed: true,
  },
  startup_plan: {
    id: "startup_plan",
    name: "Startup Plan",
    training: true,
    brain_tiers: true,
    max_users: 5,
    advanced_embed: true,
  },
  enterprise_plan: {
    id: "enterprise_plan",
    name: "Enterprise Plan",
    training: true,
    brain_tiers: true,
    max_users: 10,
    advanced_embed: true,
  },
};
interface StoreInterface {
  subscription?: Parse.Object;
}
const BlankState: StoreInterface = {
  subscription: undefined,
};
export default class SubscriptionModel {
  static useState = create<StoreInterface>((set) => ({ ...BlankState }));
  static setState = SubscriptionModel.useState.setState;
  static get state() {
    return SubscriptionModel.useState.getState();
  }
  static reset() {
    SubscriptionModel.setState({ ...BlankState });
  }

  static stripePublishableKey =
    Config.APP_ID === "Swivl-Server-Production"
      ? "pk_live_OVVsGqEV10HGcUSPgnkZjzFX00RhLEK8C1"
      : "pk_test_4iozKG5Dlcf7QxS0BCgky0GK00FTzlDoOB";

  static loadedFromBot(bot) {
    SubscriptionModel.setState({
      subscription: bot.get("subscription"),
    });
  }

  static getPlan() {
    const plan = SubscriptionPermissions[SubscriptionModel.state.subscription.get("plan")];
    log("getPlan", plan);
    if (plan) {
      return plan;
    } else {
      return SubscriptionPermissions.free;
    }
  }
  static nameForPlan() {
    if (SubscriptionModel.state.subscription && SubscriptionModel.state.subscription.get("planName")) {
      return SubscriptionModel.state.subscription.get("planName");
    }

    if (SubscriptionModel.state.subscription && SubscriptionModel.state.subscription.get("customName")) {
      return SubscriptionModel.state.subscription.get("customName");
    }
    const plan = SubscriptionPermissions[SubscriptionModel.state.subscription.get("plan")];
    log("nameForPlan", plan);

    if (plan) {
      return plan.name;
    }
    return SubscriptionPermissions.free.name;
  }

  static showBrainTiers() {
    if (
      SubscriptionModel.state.subscription &&
      typeof SubscriptionModel.state.subscription.get("multipleBrainTiers") !== "undefined"
    ) {
      return SubscriptionModel.state.subscription.get("multipleBrainTiers");
    }

    return SubscriptionModel.permissionForKey("brain_tiers", false);
  }

  static canTrain() {
    if (UserModel.state.user.get("isSuperAdmin")) {
      return true;
    }

    if (SubscriptionModel.state.subscription && SubscriptionModel.state.subscription.get("canTrain")) {
      return true;
    }
    return SubscriptionModel.permissionForKey("training", false);
  }
  static maxUsers() {
    if (SubscriptionModel.state.subscription && SubscriptionModel.state.subscription.get("maxUsers")) {
      return SubscriptionModel.state.subscription.get("maxUsers");
    }
    return SubscriptionModel.permissionForKey("max_users", 1);
  }
  static showAdvancedEmbed() {
    if (UserModel.state.user.get("isSuperAdmin")) {
      return true;
    }

    log("showAdvancedEmbed");
    if (SubscriptionModel.state.subscription && typeof SubscriptionModel.state.subscription.get("advancedEmbed") !== "undefined") {
      return SubscriptionModel.state.subscription.get("advancedEmbed");
    }
    return SubscriptionModel.permissionForKey("advanced_embed", false);
  }

  static canSeePermissions() {
    if (UserModel.state.user.get("isSuperAdmin")) {
      return true;
    }
    if (SubscriptionModel.state.subscription && SubscriptionModel.state.subscription.get("usersAndPermissions")) {
      return true;
    }
    if (
      SubscriptionModel.state.subscription &&
      SubscriptionModel.state.subscription.get("plan") &&
      SubscriptionModel.state.subscription.get("plan") === "free"
    ) {
      return false;
    }
    return true;
  }

  static advancedAppsEnabled() {
    if (UserModel.state.user.get("isSuperAdmin")) {
      return true;
    }
    log("showAdvancedEmbed");
    if (SubscriptionModel.state.subscription && typeof SubscriptionModel.state.subscription.get("premiumApps") !== "undefined") {
      return SubscriptionModel.state.subscription.get("premiumApps");
    }
    if (
      SubscriptionModel.state.subscription &&
      SubscriptionModel.state.subscription.get("plan") &&
      SubscriptionModel.state.subscription.get("plan") !== "free"
    ) {
      return true;
    }
    return false;

    //  if (!UserModel.state.user.get("isSuperAdmin") || (SubscriptionModel.state.subscription && SubscriptionModel.state.subscription.get("plan") && SubscriptionModel.state.subscription.get("plan") == "free")  ) {
    //   return false;
    // }
    // if (SubscriptionModel.app.state.user && UserModel.state.user.get("isSuperAdmin") ) {
    //   return  true;
    // }
    // return false;
  }
  static showSMSTakeover() {
    // if (SubscriptionModel.app.state.user && UserModel.state.user.get("isSuperAdmin")) {  return true; }
    if (SubscriptionModel.state.subscription && SubscriptionModel.state.subscription.get("smsTakeover")) {
      return true;
    }
    return false;
  }

  static showReviewManager() {
    if (SubscriptionModel.state.subscription && SubscriptionModel.state.subscription.get("ReviewsManager")) {
      return true;
    }
    return false;
  }

  static showActiveTenantsCampaign() {
    if (SubscriptionModel.state.subscription && SubscriptionModel.state.subscription.get("activeTenantsCampaign")) {
      return true;
    }
    return false;
  }

  static permissionForKey(key, falseState) {
    if (!SubscriptionModel.state.subscription) {
      return;
    }
    const status = SubscriptionModel.state.subscription.get("status");
    log("permissionForKey", key, falseState, status);

    if (
      !SubscriptionModel.state.subscription ||
      status === "incomplete" ||
      status === "incomplete_expired" ||
      status === "canceled" ||
      status === "unpaid"
    ) {
      return falseState;
    }

    if (
      !SubscriptionModel.state.subscription ||
      !SubscriptionModel.state.subscription.get("plan") ||
      !SubscriptionPermissions[SubscriptionModel.state.subscription.get("plan")]
    ) {
      log(
        "Returning  False State",
        SubscriptionModel.state.subscription,
        SubscriptionModel.state.subscription.get("plan"),
        SubscriptionPermissions[SubscriptionModel.state.subscription.get("plan")]
      );
      return falseState;
    }
    log("Returning: ", SubscriptionPermissions[SubscriptionModel.state.subscription.get("plan")][key]);
    return SubscriptionPermissions[SubscriptionModel.state.subscription.get("plan")][key];
  }

  static updateSubscription(subscription: Parse.Object) {
    subscription.save();
    SubscriptionModel.setState({
      subscription: subscription,
    });
  }

  static loadSubscriptionOfferForBot(bot, pendingOnly) {
    var SubscriptionOfferClass = Parse.Object.extend("SubscriptionOffer");
    var query = new Parse.Query(SubscriptionOfferClass);
    query.equalTo("bot", bot);
    query.limit(1000);
    if (pendingOnly) {
      query.equalTo("status", "pending");
      query.exists("stripePlanId");
    }
    return query.find();
  }

  static createSubscriptionOfferForBot(bot) {
    var SubscriptionOfferClass = Parse.Object.extend("SubscriptionOffer");
    var offer = new SubscriptionOfferClass();

    offer.set("bot", bot);
    offer.set("status", "pending");
    offer.set("planName", "Startup Plan");
    offer.set("maxUsers", 5);
    offer.set("supportTier", "free");
    offer.set("onboardingHours", 0);
    offer.set("monthlyTrainingHours", 0);
    offer.set("canTrain", true);
    offer.set("multipleBrainTiers", true);
    offer.set("premiumApps", true);
    offer.set("advancedEmbed", true);

    return offer.save();
  }
}
