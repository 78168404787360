import Parse from 'parse';
import VariablesModel from '../Variables/Variables.Model';
import { DateTime } from 'luxon';
import { UserVars } from '@swivl/swivl-lib';

export default class BotUser extends Parse.Object {
    constructor() { super('BotUser'); }
    isActive: boolean = false;
    // forceHideTakeover: boolean = false;
    // forceShowTakeover: boolean = false; 
  
  
  static getById(botId:string):Promise<BotUser> {
    let query =  new Parse.Query(BotUser);
    return query.get(botId) as Promise<BotUser>;
  }

    get isTakenOver(): boolean { return this.get("isTakenOver"); }
    set isTakenOver(isTakenOver: boolean) { this.set("isTakenOver", isTakenOver) }


    get variables(): {[key: string]: string } { return this.get("userVariables"); }
    set variables(vars: {[key: string]: string} ) { this.set("userVariables", vars) }

    get address(): any { return this.get("address"); }
    set address(address: any) { this.set("address", address) }

    get lastUserInput(): any { return this.get("lastUserInput"); }
    set lastUserInput(lastUserInput: any) { this.set("lastUserInput", lastUserInput) }

    get thirdPartyId(): any { return this.get("thirdPartyId"); }
    set thirdPartyId(thirdPartyId: any) { this.set("thirdPartyId", thirdPartyId) }

    get botId(): string { return this.get("bot").id; }

    

    get displayName():string {
        
        
            if (UserVars.User_Name)  {
              if (this.get("userVariables") && this.get("userVariables")[UserVars.User_Name] && this.get("userVariables")[UserVars.User_Name].length > 0 ) {
                return this.get("userVariables")[UserVars.User_Name];
              }
            }
      
            if (this.get("userNumber")) {
              return "User " + this.get("userNumber");
            } else {
              return  "User " + this.id;
            }
      
    }

    renewTranscriptAccess():Promise<BotUser> {
      const newDate = DateTime.now().plus({hours:73}).toJSDate();
      this.set("transcriptAccessExpires", newDate) 
      return this.save();
    }
}

Parse.Object.registerSubclass('BotUser', BotUser);
