
import React from "react";
import { Input } from "../../../../../Components/Input/Input";

interface Props {
    isSearch?: boolean,
    onFocus?:any
    onBlur?:any
    name?:string
    onChange?:any
    onSet?:any
    value?:any
    // onKeyDown?:any
    placeholder?:any
    // analytics?:any

}
export class NavBarSearch extends React.Component<Props> {
    render() { return <Input
        isSearch={true}
        onFocus={ this.props.onFocus }
        onBlur={ this.props.onBlur }
        name={this.props.name}
        onChange={this.props.onChange}
        onSet={this.props.onSet}
        value={this.props.value}
        placeholder={this.props.placeholder}
        isRound={true}
        />;
    }
  }