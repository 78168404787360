import React, { Component } from 'react';
import { FaCircle, FaEllipsisV } from 'react-icons/fa';

import './NameRow.scss'
import SSCopyToClipboard from '../../../../../Components/SSCopyToClipboard/SSCopyToClipboard';

export default class NameRow extends Component<any, any> {

  constructor(props, context) {
    super(props, context);
    this.state = {showDetails:false}
    this.toggleDetails = this.toggleDetails.bind(this);
  }
  toggleDetails() {
    this.setState({showDetails:!this.state.showDetails});
  }

  render(){
    if  (!this.props.item) { return null}
    const displayName = this.props.item.displayName;

    const moreDetails = (!this.state.showDetails) ? null :   <div>
         <div className="moreDetails">
              <SSCopyToClipboard
                copyValue={this.props.item.id}
                copiedComponent={<div className="moreDetailsItem copied">USER ID: {this.props.item.id}<span>COPIED</span></div>}
                ><div className="moreDetailsItem">USER ID: {this.props.item.id}<span>COPY</span></div>
              </SSCopyToClipboard>
              <SSCopyToClipboard
                copyValue={this.props.item.get("userNumber")}
                copiedComponent={<div className="moreDetailsItem copied">USER #: {this.props.item.get("userNumber")}<span>COPIED</span></div>}
                ><div className="moreDetailsItem">USER #: {this.props.item.get("userNumber")}<span>COPY</span></div>
              </SSCopyToClipboard>
            </div>
            </div>;

    const online = (this.props.item.isActive) ? <div className="onlineIndicator online"><span><FaCircle /></span> ONLINE</div> : null;
    return <div className="NameRow">
      <div className="name">{displayName}{online}</div>
      <div className={(this.state.showDetails) ? "moreButton showing" :"moreButton"}>
        <FaEllipsisV onClick={this.toggleDetails}/>
        {moreDetails}
      </div>
    </div>
  }
}
