import { useNavigate } from "react-router-dom";
import { NavBar } from "./NavBar.Model";

export type SwivlNavigateFunction = (path: string|number, fromTitle?: any) => void;

export function useSwivlNavigate():SwivlNavigateFunction {
    const navigate = useNavigate();
    const swivlNavigate = (path: string|number, fromTitle?:string) => {
        if(typeof path === "number") {
            navigate(path); 
        } else 
        if (fromTitle) {
            navigate(path, { state: { fromTitle } });
        } else if (NavBar.TitleForPath[window.location.pathname]) {
            navigate(path, { state: { fromTitle:NavBar.TitleForPath[window.location.pathname] } });
        } else {
            navigate(path);
        }
    }
    return swivlNavigate;
}