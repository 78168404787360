import React, {Suspense, lazy, useEffect} from "react";
import {
  Navigate,
  Route,
  Routes
} from "react-router-dom";
import { NavigationHelper } from "../../Helpers/Navigation/Navigation.Helper";
import { useSwivlNavigate } from "../../Models/NavBar/useSwivlNavigate";
import SlackAgentSettingsView from "../../Views/Conversations/Agent/SlackAgentSettingsView";
import LoginView from "../../Views/Login/LoginView";
import { PageLoadingView } from "../Layout/Loading/LoadingView";
import TranscriptView from "../../Views/Transcript/TranscriptView"; 

const InvitesView  = lazy(() => import("../../Views/Invites/InvitesView"));



export default function RouterLoggedOut() {  
  const navigate = useSwivlNavigate()

  useEffect(() => {
    NavigationHelper.navFunction = navigate; 
  }, [navigate]);

    return         <Routes>
      <Route index element={<LoginView />} />
      <Route path="slack/agent/bot/:botId/slack-user/:slackUserId"  element={<Suspense fallback={<PageLoadingView />}><SlackAgentSettingsView/></Suspense>} />

      <Route path="/login" element={<LoginView />} />
      <Route path="invite/:inviteId"  element={<Suspense fallback={<PageLoadingView  navBarTitle="Invite"          pageTitle="Invite"   />}><InvitesView /></Suspense>} />

      <Route path="transcript/:botUserId"  element={<Suspense fallback={<PageLoadingView  navBarTitle="TranscriptView"          pageTitle="Transcript"   />}><TranscriptView /></Suspense>} />

      
      <Route path="*" element={<Navigate to="/login" />} />


 
    </Routes> 



  }