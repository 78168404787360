import Parse from "parse";
import { create } from "zustand";
import { FMSType } from "@swivl/swivl-lib";

import BotModel from "../Bots/Bot.Model";

interface StoreInterface {
  FMSs?: Parse.Object[];
  FMS?: Parse.Object;
  isLoading: boolean;
  isAddingFMS?: boolean;
  isSavingFMS?: boolean;
}
const BlankState: StoreInterface = {
  FMSs: undefined,
  FMS: undefined,
  isLoading: true,
  isAddingFMS: false,
  isSavingFMS: false,
};
export default class FMSModel {
  static useState = create<StoreInterface>((set) => ({ ...BlankState }));
  static setState = FMSModel.useState.setState;
  static get state() {
    return FMSModel.useState.getState();
  }
  static reset() {
    FMSModel.setState({ ...BlankState });
  }

  static async load(force) {
    if (FMSModel.state.FMSs && !force) {
      return;
    }
    FMSModel.setState({ isLoading: true });
    let query = new Parse.Query("FMS");
    query.equalTo("bot", BotModel.state.bot);
    let FMSs = await query.find();
    FMSModel.setState({ isLoading: false, FMSs: FMSs });
  }

  static async loadById(fmsId: string) {
    FMSModel.setState({ isLoading: true });
    let query = new Parse.Query("FMS");
    let FMS = await query.get(fmsId);
    FMSModel.setState({ isLoading: false, FMS: FMS });
  }

  static async loadByBotId(botId: string, isDefault: boolean) {
    FMSModel.setState({ isLoading: true });

    const bot = new Parse.Object("Bot");
    bot.id = botId;

    let query = new Parse.Query("FMS");

    query.equalTo("bot", bot);
    query.equalTo("isDefault", isDefault);

    let FMS = await query.first();
    FMSModel.setState({ isLoading: false, FMS: FMS });
  }

  static async addFMS(type: FMSType) {
    let FMSs = FMSModel.state.FMSs || [];
    FMSModel.setState({ isAddingFMS: true });
    let name = String(type);
    let existing = FMSs?.filter((fms) => fms.get("type") === type);
    if (existing.length > 0) {
      name = `${type} ${existing.length + 1}`;
    }

    const fms = new Parse.Object("FMS");
    fms.set("name", name);
    fms.set("type", type);
    fms.set("bot", BotModel.state.bot);

    if (!FMSModel.state.isLoading && FMSs.length === 0) {
      fms.set("isDefault", true);
    }

    const vars = {
      smallMaxSize: 50,
      mediumMaxSize: 150,
      largeMaxSize: 300,
      actionButton: "Reserve Now",
      sizeSmallDescription: "Similar in size to a walk-in closet. Holds the contents of a studio apartment.",
      sizeMediumDescription: "Similar in size to a small bedroom. Holds the contents of a typical garage.",
      sizeLargeDescription: "Similar in size to a 1 to 1.5 car garage. Holds the contents of a 3-4 bedroom house",
      sizeExtraLargeDescription: "You have a lot of stuff! These units have plenty of room for your whole house!",
      sizeParkingDescription:
        "Each spot varies, but sizing ranges from a small utility trailer, vehicle, or camper, to a large Class A / Motorhome.",
      showPrice: true,
      showFloor: true,
    };

    fms.set("data", vars);

    await fms.save();
    FMSs.push(fms);
    FMSModel.setState({ isAddingFMS: false, FMSs: FMSs });
  }

  static async setAsDefault(fms: Parse.Object) {
    FMSModel.setState({ isSavingFMS: true });

    let FMSs = FMSModel.state.FMSs || [];
    await FMSs.forEach(async (f) => {
      if (f.id === fms.id) {
        f.set("isDefault", true);
      } else if (f.get("isDefault")) {
        f.set("isDefault", false);
        await f.save();
      }
    });
    FMSModel.setState({ FMSs: FMSs, isSavingFMS: false });
    fms.save();
  }

  static async deleteFMS(fms: Parse.Object) {
    let FMSs = FMSModel.state.FMSs || [];
    let index = FMSs.findIndex((f) => f.id === fms.id);
    if (index >= 0) {
      FMSs.splice(index, 1);
      FMSModel.setState({ FMSs: FMSs });
    }
    fms.destroy();
  }
  static async updateFMS(fms: Parse.Object, shouldSave: boolean) {
    let FMSs = FMSModel.state.FMSs || [];
    let index = FMSs.findIndex((f) => f.id === fms.id);
    if (index >= 0) {
      FMSs[index] = fms;
      FMSModel.setState({ FMSs: FMSs });
    }
    if (shouldSave) {
      fms.save();
    }
  }
}
