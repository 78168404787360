
import React from "react";
import { Spinner } from "../../../../Components/Spinner/Spinner";

export function NavBarButton (props:{title?:any, children?:any, isLoading?:boolean, onClick?:() => void, type?:"primary"|"secondary"|"tertiary"|"danger"|"success"|"warning"|"info"}) {
    const { title, onClick ,children, isLoading, type} = props;
    let typeClass = type || "primary";

    return <button className={isLoading ? "textButton isLoading " + typeClass : "textButton " + typeClass } onClick={() => { 
        if (onClick && !isLoading) {
          onClick()

        }} }><span className="textButtonContent">{title}{children}</span><span className="textButtonLoadingSpinner">{isLoading && <Spinner/>}</span></button>
  }