import {create} from 'zustand'
import BotModel from '../Bots/Bot.Model';
import Parse from "parse";
import { warn } from '../../Helpers/Logging/Logging';


interface StoreInterface {
    brainsResults?:Parse.Object[]

    loadedInbox:boolean
    loadedCompleted:boolean
    loadedIgnored:boolean
  }
  const BlankState:StoreInterface = {
    brainsResults: undefined,
    loadedInbox:false,
    loadedCompleted:false,
    loadedIgnored:false
  }
  export default class BrainsResultsModel {
  
    static useState = create<StoreInterface>(set => ({  ...BlankState }))
    static setState = BrainsResultsModel.useState.setState; 
    static get state() { return BrainsResultsModel.useState.getState(); }
    static reset() {  BrainsResultsModel.setState({  ...BlankState }); }

    


    

    static load(forceRefresh, box) {
        const bot = BotModel.state.bot; 
        if (!bot) {  return; }
      if (BrainsResultsModel.state.brainsResults &&
        BrainsResultsModel.state.brainsResults.length > 0 &&
          !forceRefresh &&
          BrainsResultsModel.state.brainsResults[0].get("bot") === bot.id) {
            return;
      }
      var BrainsResultsClass = Parse.Object.extend("BrainsResult");
      var query = new Parse.Query(BrainsResultsClass);
      query.equalTo("bot",bot);
      if (box === "Inbox") { query.equalTo("status", "inbox")   }
      if (box === "Completed") { query.equalTo("status", "completed")   }
      if (box === "Ignored") { query.equalTo("status", "ignore")   }

      query.limit(1000);
      query.include('user');
      query.descending("createdAt");
      //Splits:
      //without users 73606;
      //with user 102776
      // At 100 batch intervals: 1851;
      // at 1000 batch intervals: 17503


      query.find().then((brainsResults) => {

        var results = BrainsResultsModel.state.brainsResults || [];
        var existingIds = [];
        for (var i = 0; i < results.length; i++) {
          existingIds.push(results[i].id);
        }

        let state = {...BlankState}

        if (!box) {
          state.loadedInbox = true;
          state.loadedCompleted = true;
          state.loadedIgnored = true;



        } else if (box === "Inbox") {
          state.loadedInbox = true;
        } else if (box === "Completed") {
          state.loadedCompleted = true;
        } else if (box === "Ignored") {
          state.loadedIgnored = true;
        }


        for (var e = 0; e < brainsResults.length; e++) {
          if (!existingIds.includes(brainsResults[e].id)) {
            results.push(brainsResults[e])
          }
        }
        state.brainsResults = results; 

        
        
        BrainsResultsModel.setState(state)
      }).catch(function(error) {
        
      })
    }

    static getResult(brainsResultId) {
        const bot = BotModel.state.bot; 
        if (!bot) {  return; }
        var BrainsResultsClass = Parse.Object.extend("BrainsResult");
        var query = new Parse.Query(BrainsResultsClass);
        query.equalTo("bot",  bot);
        return query.get(brainsResultId)
    }



    static ignore(brainsResult) {
      //  THis could probably be combined with ignoreResults  in the future.
      var brainsResults = BrainsResultsModel.state.brainsResults;
      for (var i = 0; i < brainsResults.length; i++) {
        if (brainsResults[i].id === brainsResult.id) {
          brainsResults[i].set("status", "ignore");
          BrainsResultsModel.setState({brainsResults:brainsResults})
          break;
        }
      }
      brainsResult.set("status", "ignore");
      brainsResult.save();
    }

    static ignoreResults(results) {
      var promises = [];
      const resultsIds = results.map((item) => { return item.id; })
      var brainsResults = BrainsResultsModel.state.brainsResults;
      for (var i = 0; i < brainsResults.length; i++) {
        if (resultsIds.includes(brainsResults[i].id)) {
          brainsResults[i].set("status", "ignore");
          promises.push(brainsResults[i].save())
        }
      }
      BrainsResultsModel.setState({brainsResults:brainsResults});
      Promise.all(promises).catch((e) => { 
        warn("Error ignoring results", e)
      });
    }

    static markForGG(results) {
      var promises = [];
      const resultsIds = results.map((item) => { return item.id; })
      var brainsResults = BrainsResultsModel.state.brainsResults;
      for (var i = 0; i < brainsResults.length; i++) {
        if (resultsIds.includes(brainsResults[i].id)) {
          brainsResults[i].set("status", "great_grey");
          promises.push(brainsResults[i].save())
        }
      }
      BrainsResultsModel.setState({brainsResults:brainsResults});
      return Promise.all(promises).catch((e) => { 
        warn("Error markForGG", e)
      });
    }


    static setQuality(brainsResult, quality) {
      brainsResult.set("quality",  quality)
      if (quality > 0.5) { brainsResult.set("status", "completed"); }
      var brainsResults = BrainsResultsModel.state.brainsResults;
      for (var i = 0; i < brainsResults.length; i++) {
        if (brainsResults[i].id === brainsResult.id) {
          brainsResults[i] = brainsResult;
          BrainsResultsModel.setState({brainsResults:brainsResults})
          break;
        }
      }
      brainsResult.save();
    }

    static setActionTaken(brainsResult, actionTaken) {
      brainsResult.set("actionTaken",  actionTaken)
      brainsResult.set("status", "completed")

      var brainsResults = BrainsResultsModel.state.brainsResults;
      for (var i = 0; i < brainsResults.length; i++) {
        if (brainsResults[i].id === brainsResult.id) {
          brainsResults[i] = brainsResult;
          BrainsResultsModel.setState({brainsResults:brainsResults})
          break;
        }
      }
      return brainsResult.save();
    }

    static setActionTakenMulti(items, actionTaken) {

      var promises = [];
      var brainsResults = BrainsResultsModel.state.brainsResults;

      for (var i = 0; i < items.length; i++) {
        items[i].set("actionTaken",  actionTaken)
        items[i].set("status", "completed")
        promises.push(items[i].save())
        for (var r = 0; r < brainsResults.length; r++) {
          if (brainsResults[r].id === items[i].id) {
            brainsResults[i] = items[i];
          }
        }
      }
      BrainsResultsModel.setState({brainsResults:brainsResults})

      return Promise.all(promises);
    }

    static resetJustChanged() {
      var brainsResults = BrainsResultsModel.state.brainsResults;
      for (var r = 0; r < brainsResults.length; r++) {
          (brainsResults[r] as any).justChanged = false; 
      }
    
      BrainsResultsModel.setState({brainsResults:brainsResults})
    }


    static fetchUpdatedItem(objectId) {
      var BrainsResultsClass = Parse.Object.extend("BrainsResult");
      var query = new Parse.Query(BrainsResultsClass);
      query.get(objectId).then((brainResult) => {
        if (BrainsResultsModel.state.brainsResults) {
          var brainsResults = BrainsResultsModel.state.brainsResults;
          brainsResults.unshift(brainResult);
          BrainsResultsModel.setState({brainsResults:brainsResults});
        }
      }).catch((e) => { 

      })
    }
}
